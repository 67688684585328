import { Box, Skeleton, Stack } from "@mui/material";
import AboutLoader from "./AboutLoader";
import SecondaryContainer from "../Containers/SecondaryContainer";

type Props = {};

const ReportsLoader = (props: Props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: { mobile: "30px", xs: "60px", md: "90px", lg: "150px" },
      }}
    >
      <Skeleton
        sx={{
          height: "250px",
          width: "100%",
          margin: "0px",
          transform: "none",
        }}
      />
      <SecondaryContainer>
        <Stack
          spacing={4}
          sx={{
            marginBottom: "30px",
          }}
        >
          <Skeleton
            sx={{ height: "100px", width: "100%", transform: "none" }}
          />
          <Skeleton
            sx={{ height: "700px", width: "100%", transform: "none" }}
          />
        </Stack>
      </SecondaryContainer>
    </Box>
  );
};

export default ReportsLoader;
