import { Box, Typography } from "@mui/material";
import { BsQuestionLg } from "react-icons/bs";
type Props = {
  iconBg?: string;
  question?: string;
  answer?: string;
};

const QuestionItem = ({ iconBg, question, answer }: Props) => {
  return (
    <Box
      component="li"
      sx={{
        display: "flex",
        gap: {
          mobile: "16px",
          md: "24px",
          lg: "32px",
        },
      }}
    >
      <Box
        sx={{
          width: { mobile: "20px", md: "32px" },
          height: { mobile: "20px", md: "32px" },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "8px",
          backgroundColor: iconBg ? iconBg : "#707070",
          mt: "8px",
          "& svg": {
            color: "#fff",
            fontSize: {
              mobile: "18px",
              md: "24px",
            },
          },
        }}
      >
        <BsQuestionLg />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            color: "#707070",
            fontSize: {
              mobile: "18px",
              md: "20px",
              lg: "22px",
            },
          }}
        >
          {question}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            color: "#5EB74C",
            fontSize: {
              mobile: "18px",
              md: "20px",
              lg: "22px",
            },
          }}
        >
          {answer}
        </Typography>
      </Box>
    </Box>
  );
};

export default QuestionItem;
