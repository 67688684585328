import { Box, Stack, styled } from "@mui/material";
import { useState } from "react";
import { getNumberOfWords, makeASmallerText } from "../../helpers/smallerText";
import AnimateScale from "../Animations/AnimateScale";
import AnimateTap from "../Animations/AnimateTap";
import AnimateX from "../Animations/AnimateX";
import StyledHeader from "../StyledComponents/StyledHeader";

type Props = {
  header: string;
  text?: string | undefined | null;
  link: string;
  headerColor?: string;
  bolder?: boolean;
  hideLine?: boolean;
  showFullText?: boolean;
};

const StyledText = styled("p")({
  textOverflow: "ellipsis",
  wordBreak: "break-word",
  color: "#505050",
  lineHeight: "2.2",
  letterSpacing: ".5px",
  wordSpacing: "3px",
  fontWeight: "600",
  position: "relative",
  "&::before": {
    content: '""',
    backgroundColor: "#428E33",
    width: "4px",
    height: "100%",
    position: "absolute",
    left: "-70px",
  },

  // 1440px
  "@media (max-width: 1440px)": {
    fontSize: "1.25rem",
    lineHeight: "1.5",
    "&::before": {
      width: "3px",
      left: "-70px",
    },
  },
  "@media (max-width: 1280px)": {
    "&::before": {
      width: "3px",
      left: "-35px",
    },
  },
  //960px
  "@media (max-width: 960px)": {
    fontSize: "14px",
    lineHeight: "1.2",
    "&::before": {
      width: "2px",
      left: "-18px",
    },
  },
  //600px
  "@media (max-width: 600px)": {
    fontSize: "12px",
    lineHeight: "1.5",
    "&::before": {
      left: "-16px",
    },
  },
});
const StyledLink = styled("h6")({
  width: "fit-content",
  cursor: "pointer",
  color: "#428E33",
  fontSize: "1.625rem",
  textDecoration: "underline",
  transition: "all 0.1s ease-in-out",
  "&:hover": {
    scale: "1.05",
  },
  // 1440px
  "@media (max-width: 1440px)": {
    fontSize: "1.375rem",
  },
  "@media (max-width: 960px)": {
    fontSize: "16px",
  },
});

const TextInfo = (props: Props) => {
  const [showFullText, setShowFullText] = useState(props.showFullText || false);

  // getNumberOfWords(props.text);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: { mobile: "12px", md: "20px", xl: "32px" },
        zIndex: 4,
      }}
    >
      {" "}
      <AnimateX direction="right">
        <StyledHeader
          sx={{
            color: props.headerColor ? props.headerColor : "#707070",
            fontWeight: "700",
            marginLeft: { mobile: "16px", sm: "35px", md: "70px" },
          }}
        >
          {props.header}
        </StyledHeader>
      </AnimateX>
      {props.text && (
        <AnimateX direction="left">
          <StyledText
            sx={{
              marginLeft: { mobile: "16px", sm: "35px", md: "70px" },
              textAlign: "justify",
              width: "100%",
            }}
          >
            {showFullText ? props.text : makeASmallerText(props.text, 80)}
          </StyledText>
        </AnimateX>
      )}
      {props.text && getNumberOfWords(props.text) > 80 && (
        <StyledLink
          onClick={() => {
            //show the full text
            setShowFullText(true);
          }}
          sx={{
            marginLeft: { mobile: "16px", sm: "35px", md: "70px" },
          }}
        >
          <AnimateTap>
            {!showFullText && <AnimateScale>{props.link}</AnimateScale>}
          </AnimateTap>
        </StyledLink>
      )}
    </Box>
  );
};

export default TextInfo;
