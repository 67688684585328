import { Box } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import newsBg from "../../assets/images/news-bg.png";
import Achievments from "../../components/Achievments/Achievments";
import BaitikHeader from "../../components/BaitikHeader/BaitikHeader";
import ContainerWithPadding from "../../components/Containers/ContainerWithPadding";
import SecondaryContainer from "../../components/Containers/SecondaryContainer";
import ContactUs from "../../components/LayoutComponents/ContactUs/ContactUs";
import MainLoader from "../../components/Loaders/MainLoader";
import MediaCenter from "../../components/MediaCenter/MediaCenter";
import MainSlider from "../../components/Sliders/MainSlider/MainSlider";
import ServicesSlider from "../../components/Sliders/ServicesSlider/ServicesSlider";
import TextInfo from "../../components/TextInfo/TextInfo";
import { useLayoutContext } from "../../context/layoutContext";
import useGetMultiApi from "../../hooks/useGetMultiApi";
import axios from "axios";
import Cookies from "universal-cookie";
type Props = {};



const Home = (props: Props) => {
  const { t, i18n } = useTranslation();
  const apiUrls = useMemo(
    () => ["/main_page", "/service_page", "/liquidity_fund_rate"],
    []
  );
  const { data, loading } = useGetMultiApi(apiUrls);
  const { data: contactData, loading: contactLoading } = useLayoutContext();

  const aboutUsText =
    data && data.length > 0 ? data[0]?.page_details?.TEXT : null;
  const serviceData = data && data.length > 1 ? data[1] : null;



  // csrfToken
  // const cookies = new Cookies();
  // const [csrfToken, setCsrfToken] = useState('');

  // axios.get(`https://dvdyatiinew.development.dimofinf.net/sanctum/csrf-cookie`)
  // console.log('csrfToken', csrfToken);
  // axios.get("https://admin.skfh.com.sa/sanctum/csrf-cookie")
 
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          overflowX: { mobile: "hidden" },
        }}
      >
        {/* slider */}
        <Box
          component="section"
          id="slider"
          sx={{
            height: "100vh",
            width: "100%",
          }}
        >
          <MainSlider />
        </Box>
        {/* about us */}
        {aboutUsText ? (
          <ContainerWithPadding
            sx={{
              "&::before": {
                transform:
                  i18n.dir() === "rtl"
                    ? "translate(-50%,-50%)"
                    : "translate(-50%, -50%) rotate(180deg)",
              },
            }}
          >
            <SecondaryContainer>
              <TextInfo
                key={aboutUsText.type_id}
                link={t("homePage.aboutUs.more")}
                header={aboutUsText.title}
                text={aboutUsText.description}
              />
              {data && data[2] && (
                <BaitikHeader
                  generalFundData={data[2]?.rate}
                  title={data[2]?.title}
                />
              )}
            </SecondaryContainer>
          </ContainerWithPadding>
        ) : (
          loading && <MainLoader />
        )}

        {/* services */}
        {serviceData && (
          <Box
            sx={{
              backgroundColor: "#EFF4EE",
            }}
          >
            <Box
              sx={{
                margin: {
                  mobile: "30px 0 100px 0",
                  xs: "60px 0",
                  md: "80px 0",
                  xl: "100px 0",
                },
                display: "flex",
                flexDirection: "column",
                gap: { mobile: "1rem", xs: "3rem", md: "6.25rem", xl: "7rem" },
              }}
            >
              <SecondaryContainer>
                <TextInfo
                  // header={t("homePage.services.title")}
                  // text={t("homePage.services.desc")}
                  header={serviceData?.title}
                  text={serviceData?.description}
                  link={t("homePage.services.more")}
                />
              </SecondaryContainer>
              <ServicesSlider slides={serviceData?.list} />
            </Box>
          </Box>
        )}

        {/* news & achievments */}
        <Box
          sx={{
            backgroundImage: `url(${newsBg})`,
            backgroundRepeat: "repeat",
            backgroundSize: "contain",
            backgroundPosition: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <MediaCenter />
          {/* achievments */}
          <Achievments />
        </Box>
      </Box>
      {contactData && contactData[0] && (
        <ContactUs contactInfo={contactData[0]} />
      )}{" "}
    </>
  );
};

export default Home;
