import { Box } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Banner from "../../components/Banner/Banner";
import ContactUs from "../../components/LayoutComponents/ContactUs/ContactUs";
import MainLoader from "../../components/Loaders/MainLoader";
import { useLayoutContext } from "../../context/layoutContext";
import useGetSingleApi from "../../hooks/useGetSingleApi";

type Props = {};

const ContactUsPage = (props: Props) => {
  const { t, i18n } = useTranslation();
  const { data: contactData, loading: contactLoading } = useLayoutContext();
  if (contactLoading) {
    return <MainLoader />;
  }
  return (
    <>
      {contactData && (
        <Box>
          {/* <Banner
            image={data?.image}
            title={t("contactUs")}
            text={data?.description}
          /> */}

          {contactData && contactData[0] && (
            <ContactUs type="page" contactInfo={contactData[0]} />
          )}
        </Box>
      )}
    </>
  );
};

export default ContactUsPage;
