import { Box, styled, useMediaQuery } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Banner from "../../components/Banner/Banner";
import Calendar from "../../components/Calender/Calender";
import ContainerWithPadding from "../../components/Containers/ContainerWithPadding";
import SecondaryContainer from "../../components/Containers/SecondaryContainer";
import CustomPagination from "../../components/CusomPagination/CustomPagination";
import MainLoader from "../../components/Loaders/MainLoader";
import PhotoLine from "../../components/PhotoLine/PhotoLine";
import PhotoModal from "../../components/PhotoModal/PhotoModal";
import useGetMultiApi from "../../hooks/useGetMultiApi";
import useScrollToTop from "../../hooks/useScrollToTop";

const StyledTab = styled("h3")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  flex: 1,
  fontSize: "40px",
  fontWeight: 700,
  borderRadius: "15px",
  color: "#70707063",
  transition: "all 0.3s ease",
  "&.active": {
    backgroundColor: "#FFFFFF",
    color: "#428E33",
    boxShadow: "-5px 0px 20px #00000017",
  },
  "@media (max-width: 1280px)": {
    fontSize: "30px",
  },
  "@media (max-width: 960px)": {
    fontSize: "24px",
  },
});
const StyledTabContainer = styled("div")({
  display: "flex",
  borderRadius: "15px",
  backgroundColor: "#f2f2f2db",
  padding: "5px",
  height: "120px",
  width: "850px",
  marginBottom: "120px",
  "@media (max-width: 1650px)": {
    width: "750px",
    marginBottom: "100px",
  },
  "@media (max-width: 1440px)": {
    width: "600px",
    marginBottom: "90px",
  },
  "@media (max-width: 1280px)": {
    width: "450px",
    marginBottom: "70px",
    height: "90px",
  },
  "@media (max-width: 960px)": {
    width: "350px",
    marginBottom: "50px",
  },
  "@media (max-width: 600px)": {
    width: "300px",
    height: "70px",
  },
});

type Props = {};

const News = (props: Props) => {
  const { t, i18n } = useTranslation();
  const apiUrls = useMemo(() => ["/news", "/events"], []);
  const { data, loading } = useGetMultiApi(apiUrls);
  const [photoGroups, setPhotoGroups] = useState<any>([]);
  const [type, setType] = useState(2);
  const matchesMd = useMediaQuery("(max-width:1280px)");
  useScrollToTop();
  useEffect(() => {
    if (data) {
      if (matchesMd) {
        const groupedPhotos = [];
        for (let i = 0; i < data[0]?.news_details?.length; i += 2) {
          groupedPhotos.push(data[0]?.news_details?.slice(i, i + 2));
        }
        setPhotoGroups(groupedPhotos);
      } else {
        const groupedPhotos = [];
        for (let i = 0; i < data[0]?.news_details?.length; i += 3) {
          groupedPhotos.push(data[0]?.news_details?.slice(i, i + 3));
        }
        setPhotoGroups(groupedPhotos);
      }
    }
  }, [data]);
  if (loading) {
    return <MainLoader />;
  }
  if (data) {
    return (
      <>
        <Box>
          <Banner image={data[0]?.image} title={data[0]?.title} />

          <ContainerWithPadding
            sx={{
              "&::before": {
                transform:
                  i18n.dir() === "rtl"
                    ? "translate(-50%,-50%)"
                    : "translate(-50%, -50%) rotate(180deg)",
                backgroundSize: "contain",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <SecondaryContainer>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <StyledTabContainer>
                      <StyledTab
                        onClick={() => setType(2)}
                        className={type === 2 ? "active" : ""}
                      >
                        {t("news")}{" "}
                      </StyledTab>
                      <StyledTab
                        className={type === 1 ? "active" : ""}
                        onClick={() => setType(1)}
                      >
                        {t("events")}
                      </StyledTab>
                    </StyledTabContainer>
                  </Box>
                  {type === 2 ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px",
                      }}
                    >
                      {data[0]?.news_details &&
                        photoGroups.map((photo: any, index: number) => {
                          if (index % 2 === 0) {
                            return (
                              <PhotoLine
                                type="news"
                                key={index}
                                odd
                                photos={photo}
                              />
                            );
                          } else {
                            return (
                              <PhotoLine
                                type="news"
                                key={index}
                                photos={photo}
                              />
                            );
                          }
                        })}
                    </Box>
                  ) : (
                    <Calendar events={data[1]} />
                  )}
                </Box>
              </SecondaryContainer>
              {type === 2 && (
                <CustomPagination
                  pagesNumber={
                    data[0]?.news_details?.length / 6 > 1
                      ? Math.ceil(data[0]?.news_details?.length / 6)
                      : 0
                  }
                />
              )}
            </Box>
          </ContainerWithPadding>
        </Box>
        <PhotoModal />
      </>
    );
  }
  return <></>;
};

export default News;
