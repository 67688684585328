import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import albumImage from "../../assets/images/video-bg.jpg";
import { useLayoutContext } from "../../context/layoutContext";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100vw",
  padding: "8vh 0 5vh 0",
  height: "100vh",
  //   height: "80vh",
  backgroundColor: "transparent",
  border: "none",
};

export default function PhotoModal() {
  const { photoModal, setPhotoModal, photoModalData, setPhotoModalData } =
    useLayoutContext();

  return (
    <>
      <Modal
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgb(0 0 0 / 60%)",
          },
        }}
        open={photoModal}
        onClose={() => {
          setPhotoModal(false);
        }}
        aria-labelledby="modal-photo-title"
        aria-describedby="modal-photo"
      >
        <Box
          sx={style}
          onClick={() => {
            setPhotoModal(false);
            setPhotoModalData({
              title: "",
              file: "",
              id: "",
              description: "",
            });
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: { mobile: "20px", sm: "24px", md: "28px", lg: "36px" },
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              component="img"
              sx={{
                boxShadow: "0 0 20px #00000017",
                borderRadius: "15px",
                width: photoModalData.description
                  ? { mobile: "60vw", sm: "55vw", md: "50vw" }
                  : { mobile: "90vw", sm: "85vw", md: "75vw" },
                height: photoModalData.description ? "60vh" : "75vh",
                objectFit: "cover",
              }}
              src={photoModalData?.file}
              alt="album-image"
            />
            <Typography
              id="modal-photo-title"
              variant="h6"
              component="h2"
              sx={{
                fontWeight: "bold",
                fontSize: {
                  mobile: "32px",
                  sm: "36px",
                  md: "40px",
                  lg: "48px",
                },
                color: "#F2F2F2",
                textAlign: "center",
              }}
            >
              {photoModalData?.title}{" "}
            </Typography>
            <Typography
              id="modal-photo-title"
              variant="h6"
              component="h2"
              sx={{
                fontWeight: "bold",
                fontSize: {
                  mobile: "24px",
                  sm: "28px",
                  md: "36px",
                },
                color: "white",
                textAlign: "center",
              }}
            >
              {photoModalData?.description}{" "}
            </Typography>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
