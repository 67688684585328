import { Box } from "@mui/material";
import queryString from "query-string";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import aboutUsBg from "../../assets/images/about-us-bg.png";
import Banner from "../../components/Banner/Banner";
import ContainerWithPadding from "../../components/Containers/ContainerWithPadding";
import SecondaryContainer from "../../components/Containers/SecondaryContainer";
import ContactUs from "../../components/LayoutComponents/ContactUs/ContactUs";
import MainLoader from "../../components/Loaders/MainLoader";
import TextInfo from "../../components/TextInfo/TextInfo";
import VisionAndMission from "../../components/VisionAndMission/VisionAndMission";
import { useLayoutContext } from "../../context/layoutContext";
import useGetSingleApi from "../../hooks/useGetSingleApi";

type Props = {};

const InstitutionalView = (props: Props) => {
  const { data: contactData, loading: contactLoading } = useLayoutContext();

  const { t, i18n } = useTranslation();
  const apiUrl = useMemo(() => "/institutional_vision", []);
  const { data, loading } = useGetSingleApi(apiUrl);
  const location = useLocation();
  const parsedLocation = queryString.parse(location.hash);
  //scroll to section
  useEffect(() => {
    if (parsedLocation.section) {
      const element = document.getElementById(parsedLocation.section as string);
      element?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  }, [parsedLocation]);

  if (loading) {
    return <MainLoader />;
  }
  return (
    <>
      {data && (
        <Box>
          <Banner
            image={data?.image}
            title={data?.title}
            text={data?.description}
          />
          <Box
            sx={{
              position: "relative",
              "&::before": {
                backgroundImage: `url(${aboutUsBg})`,
                zIndex: -1,
                backgroundRepeat: "repeat",
                backgroundSize: "contain",
                backgroundPosition: "center",
                content: '""',
                position: "absolute",
                top: "50%",
                left: "50%",
                width: "100%",
                height: "100%",
                transform:
                  i18n.dir() === "rtl"
                    ? "translate(-50%,-50%)"
                    : "translate(-50%, -50%) rotate(180deg)",
              },
            }}
          >
            {data?.page_details?.TEXT && (
              <ContainerWithPadding
                sx={{
                  "&::before": {
                    transform:
                      i18n.dir() === "rtl"
                        ? "translate(-50%,-50%)"
                        : "translate(-50%, -50%) rotate(180deg)",
                  },
                }}
                id="about-us"
              >
                <SecondaryContainer>
                  <TextInfo
                    headerColor="#428E33"
                    link={t("homePage.aboutUs.more")}
                    header={data?.page_details?.TEXT?.title}
                    text={data?.page_details?.TEXT?.description}
                  />
                </SecondaryContainer>
              </ContainerWithPadding>
            )}
            {data?.page_details?.MISSION_VISION && (
              <VisionAndMission
                id="vision"
                data={data?.page_details?.MISSION_VISION}
              />
            )}{" "}
            {/* {data?.page_details?.VALUES && (
              <OurValues data={data?.page_details?.VALUES} />
            )} */}
          </Box>
        </Box>
      )}
      {contactData && contactData[0] && (
        <ContactUs contactInfo={contactData[0]} />
      )}
    </>
  );
};

export default InstitutionalView;
