import { Skeleton, Stack } from "@mui/material";
import React from "react";
import TextLoader from "./TextLoader";
import SecondaryContainer from "../Containers/SecondaryContainer";

type Props = {};

const AboutLoader = (props: Props) => {
  return (
    <SecondaryContainer>
      <Skeleton
        sx={{
          height: "50px",
          width: "200px",
        }}
      />
      <TextLoader />
    </SecondaryContainer>
  );
};

export default AboutLoader;
