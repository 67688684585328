import { createTheme } from "@mui/material/styles";

export const ltrTheme = createTheme({
  direction: "ltr",
  typography: {
    fontFamily: `'Cairo', sans-serif;`,

    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    body2: {
      fontSize: 14,
    },
  },

  palette: {
    primary: {
      light: "#6EDB58",
      main: "#428E33",
    },
    secondary: {
      main: "#fff",
    },
    warning: {
      main: "#707070",
    },
  },
  breakpoints: {
    values: {
      mobile: 0,
      xs: 600,
      sm: 960,
      md: 1280,
      lg: 1440,
      xl: 1650,
    },
  },
});
export const rtlTheme = createTheme({
  direction: "rtl",
  typography: {
    fontFamily: `'Cairo', sans-serif;`,

    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    body2: {
      fontSize: 14,
    },
  },
  breakpoints: {
    values: {
      mobile: 0,
      xs: 600,
      sm: 960,
      md: 1280,
      lg: 1440,
      xl: 1650,
    },
  },
  palette: {
    primary: {
      light: "#6EDB58",
      main: "#428E33",
    },
    secondary: {
      main: "#fff",
    },
    warning: {
      main: "#707070",
    },
  },
});
