import { Stack } from "@mui/material";
import { BsGoogle, BsInstagram, BsTwitter, BsYoutube } from "react-icons/bs";
import { FaFacebookF, FaSnapchatGhost, FaYoutube } from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io5";

import { useLayoutContext } from "../../../context/layoutContext";
import AnimateTap from "../../Animations/AnimateTap";

type Props = {
  color: string;
  size: "big" | "small";
};

const SocialMedia = ({ color, size }: Props) => {
  const navigateToLink = (link: string) => {
    window.open(link, "_blank");
  };

  const { data } = useLayoutContext();

  return (
    <>
      <Stack
        direction="row"
        spacing={{
          mobile: 1,
          xs: 1.5,
          sm: 2,
        }}
        sx={{
          "& svg": {
            cursor: "pointer",

            color: { color },
            "&:hover": {
              color: "#95EC84",
            },
            fontSize: {
              mobile: size === "big" ? "1rem" : "1.5rem",
              sm: size === "big" ? "1.5rem" : "1.375rem",
              lg: size === "big" ? "2rem" : "1.5rem",
            },
          },
        }}
      >
        {data &&
          data[1] &&
          data[1].map((item: any) => {
            if (item?.type === 1) {
              return (
                <AnimateTap key={item.type}>
                  <FaFacebookF onClick={() => navigateToLink(item?.url)} />
                </AnimateTap>
              );
            }
            if (item?.type === 2) {
              return (
                <AnimateTap key={item.type}>
                  <BsInstagram onClick={() => navigateToLink(item?.url)} />
                </AnimateTap>
              );
            }
            if (item?.type === 3) {
              return (
                <AnimateTap key={item.type}>
                  <FaSnapchatGhost onClick={() => navigateToLink(item?.url)} />
                </AnimateTap>
              );
            }
            if (item?.type === 4) {
              return (
                <AnimateTap key={item.type}>
                  <BsTwitter onClick={() => navigateToLink(item?.url)} />
                </AnimateTap>
              );
            }
            if (item?.type === 5) {
              return (
                <AnimateTap key={item.type}>
                  <IoLogoYoutube onClick={() => navigateToLink(item?.url)} />
                </AnimateTap>
              );
            }
            if (item?.type === 6) {
              return (
                <AnimateTap>
                  <BsGoogle
                    key={item.type}
                    onClick={() => navigateToLink(item?.url)}
                  />
                </AnimateTap>
              );
            }
          })}
      </Stack>
    </>
  );
};

export default SocialMedia;
