import { Box } from "@mui/material";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Banner from "../../components/Banner/Banner";
import ContainerWithPadding from "../../components/Containers/ContainerWithPadding";
import SecondaryContainer from "../../components/Containers/SecondaryContainer";
import MainLoader from "../../components/Loaders/MainLoader";
import ClosedInvestmentFundTab from "../../components/RealEstateInvestmentFundComponents/ClosedInvestmentFundTab";
import GeneralInvestmentFundTab from "../../components/RealEstateInvestmentFundComponents/GeneralInvestmentFundTab";
import IndirectInvestmentFundTab from "../../components/RealEstateInvestmentFundComponents/IndirectInvestmentFundTab";
import RealEstateTabsHeader from "../../components/RealEstateInvestmentFundComponents/RealEstateTabsHeader";
import TextInfo from "../../components/TextInfo/TextInfo";
import useGetSingleApi from "../../hooks/useGetSingleApi";

type Props = {};

const RealEstateInvestmentFund = (props: Props) => {
  const { t, i18n } = useTranslation();
  const apiUrl = useMemo(() => "/real_estate_fund", []);
  const { data, loading } = useGetSingleApi(apiUrl);
  const [tab, setTab] = useState(0);
  if (loading) {
    return <MainLoader />;
  }
  return (
    <>
      {data && (
        <Box>
          <Banner image={data?.image} title={data?.title} />

          {data && (
            <ContainerWithPadding
              sx={{
                "&::before": {
                  backgroundSize: "contain",
                  transform:
                    i18n.dir() === "rtl"
                      ? "translate(-50%,-50%)"
                      : "translate(-50%, -50%) rotate(180deg)",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: {
                    mobile: "50px",
                    sm: "60px",
                    md: "75px",
                    lg: "90px",
                    xl: "110px",
                  },
                  zIndex: 4,
                  width: "100%",
                }}
              >
                <SecondaryContainer>
                  <TextInfo
                    headerColor="#428E33"
                    link={t("homePage.aboutUs.more")}
                    header={data?.title}
                    text={data?.description}
                  />
                </SecondaryContainer>
                <RealEstateTabsHeader setTab={setTab} />

                {tab === 0 && data?.general_fund && (
                  <GeneralInvestmentFundTab
                    generalFundData={data?.general_fund}
                  />
                )}
                {tab === 1 && data?.private_fund && (
                  <ClosedInvestmentFundTab fundData={data?.private_fund} />
                )}
                {tab === 2 && data?.indirect_fund && (
                  <IndirectInvestmentFundTab fundData={data?.indirect_fund} />
                )}
              </Box>
            </ContainerWithPadding>
          )}
        </Box>
      )}
    </>
  );
};

export default RealEstateInvestmentFund;
