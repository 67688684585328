// @ts-nocheck
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Typography,
} from "@mui/material";
import axios from "axios";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { TiDelete } from "react-icons/ti";
import { toast } from "react-toastify";
import * as Yup from "yup";
import greenTriangle from "../../assets/images/small-green-triangle.svg";
import SecondaryContainer from "../Containers/SecondaryContainer";
import SmallerContainer from "../Containers/SmallerContainer";
import CustomTextField from "../CustomInputs/CustomTextField";
import CustomSelect from "../CustomSelect/CustomSelect";
import { SmallTextTitle } from "../TextInfo/SmallTextInfo";
import ReCAPTCHA from "react-google-recaptcha";

type Props = {
  selectItems?: any;
};

const JobsForm = (props: Props) => {
  const { t } = useTranslation();
  const recaptchaRef = useRef<ReCAPTCHA | null>();
  const [capVal, setCapVal] = useState(false)
  const key3 = "6LcXQhkqAAAAANKqN8l03jlZCwZZdZgAeAmeCHOq"
  //form logic
  const onChange = async () => {
    try {
      const token = await recaptchaRef?.current?.executeAsync();
      setCapVal(token)
    } catch (error) {
      console.error('Error executing reCAPTCHA:', error);
    }
  }

  useEffect(() => {
    onChange()
  }, [])

  const handleSubmit = async (values: any, actions: any) => {
    try {
      await sendForm(values);
      handleSuccess();
      actions.resetForm();
    } catch (error) {
      handleError(error);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const sendForm = async (values: any) => {
    const formData = new FormData();
    //loop through values and append to formData
    for (const key in values) {
      formData.append(key, values[key]);
    }
    formData.append("recaptcha_response", capVal)
    await axios.post(`${process.env.REACT_APP_API_URL}/api/request-job`, formData);
  };

  const handleSuccess = () => {
    toast.success(`${t("jobs.form.applicationSent")}`, {
      toastId: "contactUsForm",
    });
  };

  const handleError = (errors: any) => {
    const errorValues = Object.values(errors.response.data.errors);
    toast.error(String(errorValues as any[][][0][0]), {
      toastId: "contactUsForm",
    });
  };
  const initialValues = {
    name: "",
    email: "",
    mobile: "",
    certificate: "",
    nationality: "",
    years_of_experience: "",
    cv: "",
    job_id: "",
    department_id: "",
    type: "",
    message: "",
    checked: false,
    // recaptcha_response: ""
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(`${t("jobs.form.name")}`),
    email: Yup.string()
      .email(`${t("jobs.form.invalidMail")}`)
      .required(`${t("jobs.form.email")}`),
    mobile: Yup.number().required(`${t("jobs.form.mobile")}`),
    certificate: Yup.string().required(`${t("jobs.form.certificate")}`),
    nationality: Yup.string().required(`${t("jobs.form.nationality")}`),
    years_of_experience: Yup.string().required(`${t("jobs.form.exp")}`),
    //should upload file
    cv: Yup.mixed().required(`${t("jobs.form.cv")}`),
    job_id: Yup.string().required(`${t("jobs.form.job")}`),
    department_id: Yup.string().required(`${t("jobs.form.department")}`),
    type: Yup.string().required(`${t("jobs.form.type")}`),
    checked: Yup.boolean().oneOf([true], `${t("jobs.form.checked")}`),
  });
  const [jobsOptions, setJobsOptions] = React.useState<any[]>([]);
  const [departmentsOptions, setDepartmentsOptions] = React.useState<any[]>();

  useEffect(() => {
    if (props.selectItems) {
      setJobsOptions(props.selectItems.jobs);
      setDepartmentsOptions(props.selectItems.departments);
    }
  }, [props.selectItems]);

  return (
    <Box
      data-aos="fade-zoom-in"
      sx={{
        marginTop: { mobile: "3rem", sm: "5rem", md: "7rem", lg: "10rem" },
        zIndex: 1,
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          handleSubmit(values, actions);
        }}
      >
        {({ isSubmitting, setFieldValue, errors, touched, values }) => (
          <Form>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#f2f2f238",
                  padding: {
                    mobile: "50px 0",
                    md: "60px 0",
                    lg: "66px 0 100px 0",
                  },
                }}
              >
                <SecondaryContainer>
                  <SmallTextTitle
                    title={t("jobs.title")}
                    titleColor="#428E33"
                    gap={{ mobile: "12px", md: "50px" }}
                    startIcon={
                      <Box
                        component="img"
                        src={greenTriangle}
                        alt={"start-icon"}
                        sx={{
                          marginTop: {
                            mobile: "5px",
                            md: "10px",
                            // lg: "15px",
                          },
                        }}
                      />
                    }
                  />
                </SecondaryContainer>
                <SmallerContainer>
                  <Box
                    sx={{
                      mt: { mobile: "16px", md: "36px" },
                      display: "grid",
                      gridTemplateColumns: "repeat(6, 1fr)",
                      columnGap: { mobile: ".8rem", md: "1rem", lg: "1.5rem" },
                      rowGap: { mobile: "1.5rem", md: "2rem", lg: "2.5rem" },
                    }}
                  >
                    <Box
                      sx={{
                        gridColumn: {
                          mobile: "span 3",
                          xs: "span 2",
                        },
                      }}
                    >
                      <CustomTextField
                        label={t("jobs.name")}
                        name="name"
                        color="warning"
                      />
                    </Box>
                    <Box
                      sx={{
                        gridColumn: {
                          mobile: "span 3",
                          xs: "span 2",
                        },
                      }}
                    >
                      <CustomTextField
                        label={t("jobs.education")}
                        name="certificate"
                        color="warning"
                      />
                    </Box>
                    <Box
                      sx={{
                        gridColumn: {
                          mobile: "span 3",
                          xs: "span 2",
                        },
                      }}
                    >
                      <CustomTextField
                        label={t("jobs.email")}
                        name="email"
                        color="warning"
                      />
                    </Box>
                    <Box
                      sx={{
                        gridColumn: {
                          mobile: "span 3",
                          xs: "span 2",
                        },
                      }}
                    >
                      <CustomTextField
                        label={t("jobs.phone")}
                        type="number"
                        name="mobile"
                        color="warning"
                      />
                    </Box>
                    <Box
                      sx={{
                        gridColumn: {
                          mobile: "span 3",
                          xs: "span 2",
                        },
                      }}
                    >
                      <CustomTextField
                        label={t("jobs.Years")}
                        type="number"
                        name="years_of_experience"
                        color="warning"
                      />
                    </Box>
                    <Box
                      sx={{
                        gridColumn: {
                          mobile: "span 3",
                          xs: "span 2",
                        },
                      }}
                    >
                      <CustomTextField
                        label={t("jobs.nationality")}
                        name="nationality"
                        color="warning"
                      />
                    </Box>
                    <Box
                      sx={{
                        gridColumn: {
                          mobile: "span 6",
                          xs: "span 2",
                        },
                      }}
                    >
                      <CustomSelect
                        label={t("jobs.availableJobs")}
                        name="job_id"
                        options={jobsOptions}
                      />
                    </Box>

                    <Box
                      sx={{
                        gridColumn: {
                          mobile: "span 6",
                          xs: "span 2",
                        },
                      }}
                    >
                      <CustomSelect
                        key="major"
                        label={t("jobs.major")}
                        name="department_id"
                        options={departmentsOptions}
                      />
                    </Box>
                    <Box
                      sx={{
                        gridColumn: {
                          mobile: "span 6",
                          xs: "span 2",
                        },
                      }}
                    >
                      <CustomSelect
                        key="type"
                        label={t("jobs.permanence")}
                        name="type"
                        options={[
                          {
                            id: 2,
                            title: t("jobs.jobType.fullTime"),
                          },
                          {
                            id: 1,
                            title: t("jobs.jobType.partTime"),
                          },
                          {
                            id: 3,
                            title: t("jobs.jobType.remote"),
                          },
                        ]}
                      />
                    </Box>
                    <Box sx={{ gridColumn: "span 6" }}>
                      <CustomTextField
                        key="coverLetter"
                        label={t("jobs.coverLetter")}
                        name="message"
                        color="warning"
                        multiline
                      />
                    </Box>
                  </Box>
                </SmallerContainer>
              </Box>
              <SecondaryContainer>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: { mobile: "40px", md: "60px", lg: "80px" },
                    mt: { mobile: "40px", md: "60px", lg: "80px" },
                  }}
                >
                  <SmallTextTitle
                    type="small"
                    gap={{ mobile: "12px", md: "50px" }}
                    titleColor="#428E33"
                    title={t("jobs.desc")}
                    startIcon={
                      <Box
                        component="img"
                        src={greenTriangle}
                        alt="greenTriangle"
                        sx={{
                          marginTop: {
                            mobile: "5px",
                          },
                        }}
                      />
                    }
                  />
                  <Box
                    sx={{
                      position: "relative",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="warning"
                      sx={{
                        width: {
                          mobile: "150px",
                          xs: "180px",
                          sm: "200px",
                          md: "250px",
                          lg: "300px",
                          xl: "350px",
                        },
                        height: {
                          mobile: "50px",
                          md: "60px",
                          lg: "70px",
                        },
                        borderRadius: "10px",
                        fontSize: { mobile: "18px", md: "20px", lg: "22px" },
                        fontWeight: "bold",
                      }}
                    >
                      <input
                        name="cv"
                        onChange={(e) => {
                          const file = e?.target?.files?.[0];
                          if (file) {
                            setFieldValue("cv", file);
                          }
                        }}
                        type="file"
                        style={{
                          position: "absolute",
                          opacity: "0",
                          width: "100%",
                          height: "100%",
                          top: "0",
                          left: "0",
                        }}
                      />
                      {t("jobs.attachments")}
                    </Button>
                    {touched.cv && errors.cv && (
                      <Typography
                        sx={{
                          textAlign: "center",
                          color: "error.main",
                          fontSize: "12px",
                          fontWeight: "bold",
                          position: "absolute",
                          bottom: "-20px",
                        }}
                      >
                        {t("jobs.form.cv")}
                      </Typography>
                    )}
                    {values.cv && (
                      <Box
                        sx={{
                          display: "flex",
                          position: "absolute",
                          bottom: "-30px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            color: "#707070",
                          }}
                        >
                          CV
                        </Typography>
                        <TiDelete
                          size="20px"
                          color="red"
                          cursor={"pointer"}
                          onClick={() => {
                            setFieldValue("cv", null);
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                  <Field name="checked">
                    {({ field }: any) => (
                      <FormControlLabel
                        name="checked"
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            marginLeft: "20px",
                            color:
                              errors.checked && touched.checked
                                ? "red"
                                : "black",
                          },
                        }}
                        control={
                          <Checkbox
                            {...field}
                            checked={field.value}
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                            sx={{
                              alignSelf: "flex-start",
                              padding: "0",

                              "& .MuiSvgIcon-root": {
                                "& path": {
                                  fill: "#428E33",
                                },
                              },
                            }}
                          />
                        }
                        label={t("jobs.endorsement")}
                      />
                    )}
                  </Field>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    value={capVal}
                    sitekey={key3}
                    size="invisible"
                    onChange={val => setCapVal(val)}
                    name="recaptcha_response"
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                    endIcon={isSubmitting && <CircularProgress size={20} />}
                    sx={{
                      width: {
                        mobile: "150px",
                        xs: "180px",
                        sm: "200px",
                        md: "210px",
                      },
                      height: {
                        mobile: "50px",
                        md: "60px",
                        lg: "70px",
                      },
                      borderRadius: "10px",
                      fontSize: "22px",
                      fontWeight: "bold",
                      alignSelf: "flex-end",
                    }}
                  >
                    {t("jobs.send")}
                  </Button>
                </Box>
              </SecondaryContainer>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default React.memo(JobsForm);
