import { Box, Stack, styled } from "@mui/material";
import { motion } from "framer-motion";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import AwardCard from "../../components/AwardCard/AwardCard";
import Banner from "../../components/Banner/Banner";
import ContainerWithPadding from "../../components/Containers/ContainerWithPadding";
import SecondaryContainer from "../../components/Containers/SecondaryContainer";
import ContactUs from "../../components/LayoutComponents/ContactUs/ContactUs";
import MainLoader from "../../components/Loaders/MainLoader";
import StyledHeader from "../../components/StyledComponents/StyledHeader";
import { useLayoutContext } from "../../context/layoutContext";
import useGetSingleApi from "../../hooks/useGetSingleApi";

type Props = {};
const StyledText = styled("p")({
  color: "#505050",
  fontSize: "1.5rem",
  fontWeight: "600",
  position: "relative",
});

const Awards = (props: Props) => {
  const { data: contactData, loading: contactLoading } = useLayoutContext();

  const { t, i18n } = useTranslation();
  const apiUrl = useMemo(() => "/awards", []);
  const variants = {
    visible: (i: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.2,
        duration: 0.9,
      },
    }),
    hidden: { opacity: 0, y: 200 },
  };

  const { data, loading } = useGetSingleApi(apiUrl);
  if (loading) {
    return <MainLoader />;
  }
  return (
    <>
      {data && (
        <Box>
          <Banner
            image={data?.image}
            title={data?.title}
            text={data?.description}
          />
          <ContainerWithPadding
            sx={{
              "::before": {
                backgroundSize: { mobile: "contain", sm: "cover" },
                transform:
                  i18n.dir() === "rtl"
                    ? "translate(-50%,-50%)"
                    : "translate(-50%, -50%) rotate(180deg)",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <SecondaryContainer>
                {data?.page_details?.TEXT && (
                  <Stack
                    spacing={2}
                    sx={{
                      flex: "1",
                      position: "relative",
                      marginBottom: "4rem",
                      "&::before": {
                        content: '""',
                        backgroundColor: "#428E33",
                        width: { mobile: "2px", xs: "4px" },
                        height: "80%",
                        transform: "translateY(10%)",
                        position: "absolute",
                        left: { mobile: "-16px", xs: "-35px" },
                      },
                    }}
                  >
                    <StyledHeader>{data.page_details.TEXT.title}</StyledHeader>
                    <StyledText>
                      {data.page_details.TEXT.description}
                    </StyledText>
                  </Stack>
                )}

                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: { mobile: "1fr", sm: "1fr 1fr" },
                    alignItems: { mobile: "center", xs: "unset" },
                    gap: { mobile: "1.5rem", xs: "2rem", md: "3rem" },
                  }}
                >
                  {data &&
                    data.page_details &&
                    data.page_details.CARDS_IMAGES &&
                    data.page_details.CARDS_IMAGES.map(
                      (item: any, index: any) => (
                        <motion.div
                          custom={index}
                          variants={variants}
                          viewport={{
                            once: true,
                          }}
                          initial="hidden"
                          whileInView="visible"
                          key={index}
                        >
                          <AwardCard
                            date={item.title}
                            title={item.description}
                            image={item.file}
                          />
                        </motion.div>
                      )
                    )}
                </Box>
              </SecondaryContainer>
            </Box>
          </ContainerWithPadding>
        </Box>
      )}
      {contactData && contactData[0] && (
        <ContactUs contactInfo={contactData[0]} />
      )}
    </>
  );
};

export default Awards;
