import { styled } from "@mui/material";

export const StyledTitle = styled("h3")({
  fontSize: "24px",
  color: "#fff",
  "@media (max-width:1650px)": {
    fontSize: "22px",
  },
  "@media (max-width:1280px)": {
    fontSize: "20px",
  },
  "@media (max-width:960px)": {
    fontSize: "18px",
  },
  "@media (max-width:600px)": {
    fontSize: "16px",
    color: "#59A44A",
  },
});

export const StyledDesc = styled("p")({
  fontSize: "20px",
  fontWeight: "600",
  color: "#fff",
  wordBreak: "break-word",
  "@media (max-width:1650px)": {
    fontSize: "18px",
  },
  "@media (max-width:1440px)": {
    fontSize: "16px",
  },
  "@media (max-width:1280px)": {
    fontSize: "14px",
  },
  //less than 960px
  "@media (max-width:960px)": {
    fontSize: "12px",
  },
  "@media (max-width:600px)": {
    color: "#646464",
    fontSize: "14px",
  },
});

export const FlexColumn = styled("div")({
  display: "flex",
  flexDirection: "column",
});
