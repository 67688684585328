import { styled } from "@mui/material";

const StyledHeader = styled("h2")({
  color: "#707070",
  fontSize: "50px",
  // 1440px
  "@media (max-width: 1440px)": {
    fontSize: "40px",
  },
  "@media (max-width: 1280px)": {
    fontSize: "36px",
  },
  "@media (max-width: 960px)": {
    fontSize: "32px",
  },
  "@media (max-width: 600px)": {
    fontSize: "24px",
  },
});

export default StyledHeader;
