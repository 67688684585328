import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { SmallTextTitle } from "../../TextInfo/SmallTextInfo";
import { FiDownload } from "react-icons/fi";

type Props = {
  report: {
    id: number;
    title: string;
    report_files: {
      id: number;
      title: string;
      file: string;
    }[];
  };
};

const SingleReport = ({ report }: Props) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: { mobile: "8px", sm: "16px", md: "20px", lg: "26px" },
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "12px",
            height: "12px",
            backgroundColor: "#707070",
            borderRadius: "50%",
          }}
        />
        <Typography
          sx={{
            fontSize: { mobile: "16px", sm: "20px", md: "22px", xl: "24px" },
            fontWeight: "bold",
            color: "#707070",
          }}
        >
          {report.title}{" "}
        </Typography>
      </Box>
      <Button
        variant="contained"
        sx={{
          borderRadius: "4px",
          width: { mobile: "32px", md: "41px" },
          height: { mobile: "30px", md: "38px" },
          minWidth: { mobile: "32px", md: "41px" },
          padding: "0",
          "& svg": {
            fontSize: { mobile: "20px", md: "30px" },
          },
        }}
        onClick={() => {
          if (report?.report_files[0]?.file) {
            window.open(report.report_files[0].file, "_blank");
          }
        }}
      >
        <FiDownload />{" "}
      </Button>
    </Box>
  );
};

export default SingleReport;
