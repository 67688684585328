import { Box, Divider, Stack, Typography, styled } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import queryString from "query-string";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import Banner from "../../components/Banner/Banner";
import ContainerWithPadding from "../../components/Containers/ContainerWithPadding";
import SecondaryContainer from "../../components/Containers/SecondaryContainer";
import CustomPagination from "../../components/CusomPagination/CustomPagination";
import ContactUs from "../../components/LayoutComponents/ContactUs/ContactUs";
import ReportsLoader from "../../components/Loaders/ReportsLoader";
import Report from "../../components/Report/Report";
import { useLayoutContext } from "../../context/layoutContext";
import useGetSingleApi from "../../hooks/useGetSingleApi";

type Props = {};
const StyledHeader = styled("h4")({
  color: "#428e33",
  fontSize: "30px",
  fontWeight: "bold",
  "@media (max-width: 960px)": {
    fontSize: "24px",
  },
  "@media (max-width: 600px)": {
    fontSize: "16px",
  },
});
type file = {
  type_id: number;
  title: string;
  file: string;
};
const headerStyles = {
  // flex: "1",
  alignSelf: "center",
  color: "#707070",
  cursor: "pointer",
  fontWeight: "bold",
  textAlign: "center",
  fontSize: {
    mobile: "18px",
    sm: "24px",
    md: "30px",
    lg: "36px",
    xl: "48px",
  },
};

const Reports = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const parsedLocation = queryString.parse(location.search);

  const { data: contactData, loading: contactLoading } = useLayoutContext();

  const { t, i18n } = useTranslation();
  const apiUrl = useMemo(
    () =>
      `/reports?value=${parsedLocation.value || 1}&page=${
        parsedLocation.page || 1
      }&year=${parsedLocation.year || ""}&month=${parsedLocation.month || ""}`,
    [parsedLocation]
  );
  const { data, loading } = useGetSingleApi(apiUrl);

  if (loading) {
    return <ReportsLoader />;
  }
  return (
    <>
      {data && (
        <Box>
          <Banner
            image={data?.image}
            title={data?.title}
            text={data?.description}
          />
          <ContainerWithPadding
            sx={{
              "&::before": {
                backgroundSize: { mobile: "contain", sm: "cover" },
                transform:
                  i18n.dir() === "rtl"
                    ? "translate(-50%,-50%)"
                    : "translate(-50%, -50%) rotate(180deg)",
              },
            }}
          >
            <Box sx={{ width: "100%" }}>
              <SecondaryContainer>
                <Stack
                  spacing={{
                    xs: 4,
                    sm: 5,
                    md: 6,
                    lg: 7,
                  }}
                  sx={{
                    width: "100%",
                    zIndex: 2,
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      padding: {
                        mobile: "16px 8px",
                        xs: "24px 16px",
                        xl: "28px 70px",
                      },
                      border: "2px solid #428E33",
                      borderRadius: "10px",
                    }}
                  >
                    <Stack
                      direction="row"
                      sx={{
                        width: "100%",
                      }}
                      justifyContent="space-around"
                      divider={
                        <Divider
                          sx={{
                            borderWidth: "1px",
                            borderColor: "primary.main",
                            opacity: ".35",
                          }}
                        />
                      }
                    >
                      <Typography
                        variant="h4"
                        onClick={() => {
                          navigate(`/reports?value=1`);
                        }}
                        sx={{
                          ...headerStyles,
                          opacity: parsedLocation.value === "1" ? "1" : ".35",
                        }}
                      >
                        {t("dailyReports")}
                      </Typography>

                      <Typography
                        variant="h4"
                        onClick={() => {
                          navigate(`/reports?value=2`);
                        }}
                        sx={{
                          ...headerStyles,
                          opacity: parsedLocation.value === "2" ? "1" : ".35",
                        }}
                      >
                        {t("monthlyReports")}
                      </Typography>
                    </Stack>
                  </Box>
                  <Stack direction={"row"} spacing={2}>
                    <DatePicker
                      label={parsedLocation.year || t("year")}
                      views={["year"]}
                      onChange={(e) => {
                        const date = new Date(e as string);
                        navigate(
                          `/reports?${queryString.stringify({
                            ...parsedLocation,
                            year: date.getFullYear(),
                          })}`
                        );
                      }}
                    />
                    <DatePicker
                      label={
                        ` ${t("month")} ${
                          Number(parsedLocation.month) + 1 || ""
                        }` || t("month")
                      }
                      views={["month"]}
                      onChange={(e) => {
                        const date = new Date(e as string);
                        navigate(
                          `/reports?${queryString.stringify({
                            ...parsedLocation,
                            month: date.getMonth() + 1,
                          })}`
                        );
                      }}
                    />
                  </Stack>
                  {data?.reports?.data.length > 0 ? (
                    <>
                      {" "}
                      <TabPanel value={1} index={Number(parsedLocation.value)}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                          }}
                        >
                          <Stack
                            sx={{
                              width: "100%",
                              alignItems: "center",
                              borderBottom: "1px solid #428e3342",
                              paddingBottom: "20px",
                              marginBottom: "30px",
                            }}
                            justifyContent="space-between"
                            direction="row"
                            divider={
                              <Box
                                sx={{
                                  width: { mobile: "8px", sm: "14px" },
                                  backgroundColor: "primary.main",
                                  borderRadius: "50%",
                                  height: { mobile: "8px", sm: "14px" },
                                }}
                              />
                            }
                          >
                            <StyledHeader>
                              {t("reports.reportName")}{" "}
                            </StyledHeader>
                            <StyledHeader>{t("reports.date")}</StyledHeader>
                            <StyledHeader>
                              {t("reports.downloadReport")}{" "}
                            </StyledHeader>
                          </Stack>
                          <Stack spacing={2}>
                            {data &&
                              data.reports &&
                              data?.reports?.data &&
                              data?.reports?.data?.map(
                                (file: file, index: any) => (
                                  <Report
                                    {...file}
                                    key={file.type_id + index}
                                  />
                                )
                              )}
                          </Stack>
                        </Box>
                      </TabPanel>
                      <TabPanel value={2} index={Number(parsedLocation.value)}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                          }}
                        >
                          <Stack
                            sx={{
                              width: "100%",
                              alignItems: "center",
                              borderBottom: "1px solid #428e3342",
                              paddingBottom: "20px",
                              marginBottom: "30px",
                            }}
                            justifyContent="space-between"
                            direction="row"
                            divider={
                              <Box
                                sx={{
                                  width: { mobile: "8px", sm: "14px" },
                                  backgroundColor: "primary.main",
                                  borderRadius: "50%",
                                  height: { mobile: "8px", sm: "14px" },
                                }}
                              />
                            }
                          >
                            <StyledHeader>
                              {t("reports.reportName")}{" "}
                            </StyledHeader>
                            <StyledHeader>{t("reports.date")}</StyledHeader>
                            <StyledHeader>
                              {t("reports.downloadReport")}{" "}
                            </StyledHeader>
                          </Stack>
                          <Stack spacing={2}>
                            {data &&
                              data.reports &&
                              data?.reports?.data &&
                              data?.reports?.data?.map(
                                (file: file, index: any) => (
                                  <Report
                                    {...file}
                                    key={file.type_id + index}
                                  />
                                )
                              )}
                          </Stack>
                        </Box>
                      </TabPanel>
                    </>
                  ) : (
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontSize: "24px",
                        fontWeight: "bold",
                        color: "primary.main",
                      }}
                    >
                      {t("noReports")}
                    </Typography>
                  )}
                </Stack>
              </SecondaryContainer>
            </Box>
          </ContainerWithPadding>
        </Box>
      )}
      {data?.reports?.last_page > 1 && (
        <CustomPagination pagesNumber={data?.reports?.last_page} />
      )}
      {contactData && contactData[0] && (
        <ContactUs contactInfo={contactData[0]} />
      )}
    </>
  );
};

export default Reports;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
