import { useEffect, useState } from "react";
import { get } from "../services/axios-service";
import { useTranslation } from "react-i18next";

const useGetSingleApi = (url: string) => {
  const [data, setData] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any | null>(null);
  const { i18n } = useTranslation();
  useEffect(() => {
    let newUrl = url;
    if (newUrl.includes("?")) {
      newUrl = `${newUrl}&lang=${i18n.language}`;
    } else {
      newUrl = `${newUrl}?lang=${i18n.language}`;
    }
    // Fetch slider data
    setLoading(true);
    get(newUrl)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [url, i18n.language]);

  return { data, loading, error };
};
export default useGetSingleApi;
