import { Container, Stack } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineMenu } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import leftGreenLogo from "../../../assets/images/header-green-left-logo.png";
import greenLogo from "../../../assets/images/header-green-logo.svg";
import logoLtr from "../../../assets/images/header-logo-ltr.png";
import logo from "../../../assets/images/header-logo.svg";
import { useLayoutContext } from "../../../context/layoutContext";
import CustomSearchField from "../../CustomInputs/CustomSeachField";
import LanguageButton from "../../CustomInputs/LanguageButton";
import DrawerAppBar from "./Drawer";
import NavItem from "./NavItem";
interface Props {
  window?: () => Window;
}

type menuItem = {
  name: string;
  smallMenu: boolean;
  smallMenuItems?: {
    name: string;
  }[];
};

export default function Header(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [navItems, setNavItems] = React.useState<any[]>([]);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const { t, i18n } = useTranslation();

  const { data } = useLayoutContext();
  const location = useLocation();
  useEffect(() => {
    setMobileOpen(false);
  }, [location.pathname]);

  const addApiLinks = (data: any) => {
    let fundsItems = data[2].map((item: any) => {
      return {
        name: item.title,
        link: `/services/baitk-liquidity-fund/${item.id}`,
      };
    });
    fundsItems.unshift({
      name: t("layout.header.subLinks.realEstateInvestmentFund"),
      link: "/services/baitk-liquidity-fund",
    });
    setNavItems([
      {
        name: t("layout.header.mainLinks.aboutUs"),
        // link: "/",

        subMenu: [
          {
            name: t("layout.header.subLinks.institutionalView"),
            link: "/institutional-view",
            smallMenu: false,
          },
          {
            name: t("layout.header.subLinks.visionAndMission"),
            smallMenu: false,
            link: "/institutional-view#section=vision",
          },
          {
            name: t("layout.header.subLinks.ourStrength"),
            link: "/fatwa-authority",
            smallMenu: false,
          },
          // {
          //   name: t("layout.header.subLinks.boardMessage"),
          //   link: "/board-message",
          //   smallMenu: false,
          // },
          {
            name: t("layout.header.subLinks.leadership"),
            link: "/leadership",
            smallMenu: true,
            subMenu: [
              {
                name: t("layout.header.subLinks.board"),
                link: "/leadership?value=1",
              },

              {
                name: t("layout.header.subLinks.executiveManagement"),
                link: "/leadership?value=2",
              },
            ],
          },
          {
            name: t("layout.header.subLinks.organizationalStructure"),
            link: "/organizational-structure",
            smallMenu: false,
          },
          {
            name: t("layout.header.subLinks.awards"),
            link: "/awards",
            smallMenu: false,
          },
          {
            name: t("layout.header.subLinks.reports"),
            link: "/reports?value=1",
            smallMenu: false,
          },
        ],
      },
      {
        name: t("layout.header.mainLinks.services"),
        link: "/services",
        subMenu: [
          {
            name: t("layout.header.subLinks.assetManagement"),
            smallMenu: true,
            link: "/services/asset-management",
            subMenu: [
              {
                name: t("layout.header.subLinks.investmentFunds"),
                // link: "/services/baitk-liquidity-fund",
                smallMenu: true,
                //add small menus from api
                subMenu: fundsItems,
              },
              // {
              //   name: t("layout.header.subLinks.investmentFunds"),
              // },
              {
                name: t("layout.header.subLinks.securitiesManagement"),
                link: "/services/securities-management",
                smallMenu: true,
                subMenu: [
                  {
                    name: t("layout.header.subLinks.saudiEquityFund"),
                    link: "/services/securities-management/murabahat-wallets",
                  },
                  {
                    name: t("layout.header.subLinks.auctionFund"),
                    link: "/services/securities-management/sukuk-wallets",
                  },
                  {
                    name: t(
                      "layout.header.subLinks.investmentPortfolioManagement"
                    ),
                    link: "/services/securities-management/investment-portfolio-management",
                  },
                ],
              },
              {
                name: t("layout.header.subLinks.preservation"),
                link: "/services/preservation",
                smallMenu: false,
              },
            ],
          },

          {
            name: t("layout.header.subLinks.investmentBanking"),
            smallMenu: true,
            link: "/services/investment-banking",
            subMenu: [
              {
                name: t("layout.header.subLinks.consulting"),
                link: "/services/investment-banking/consulting",
              },
              {
                name: t("layout.header.subLinks.ordering"),
                link: "/services/investment-banking/ordering",
              },
            ],
          },
        ],
      },
      {
        name: t("layout.header.mainLinks.mediaCenter"),
        subMenu: [
          {
            name: t("layout.header.subLinks.news"),
            link: "media-center/news",
            smallMenu: false,
          },
          {
            name: t("layout.header.subLinks.photos"),
            link: "media-center/photos",
            smallMenu: false,
          },
          {
            name: t("layout.header.subLinks.videos"),
            link: "media-center/videos",
            smallMenu: false,
          },
          {
            name: t("layout.header.subLinks.yourRights"),
            link: "media-center/your-rights",
            smallMenu: false,
          },
        ],
      },
      {
        name: t("layout.header.mainLinks.contactUs"),
        link: "/contact-us",
        subMenu: [
          {
            name: t("layout.header.subLinks.commonQuestions"),
            link: "/Q&A",
            smallMenu: false,
          },
          {
            name: t("layout.header.subLinks.contactUs"),
            link: "/contact-us",
            smallMenu: false,
          },
          {
            name: t("layout.header.subLinks.complaints"),
            link: "/complaints",
            smallMenu: false,
          },
        ],
      },
      {
        name: t("layout.header.mainLinks.jobs"),
        link: "jobs",
      },
    ]);
  };

  React.useEffect(() => {
    if (data) {
      addApiLinks(data);
    }
  }, [data]);

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const navigate = useNavigate();
  const [showMenu, setShowMenu] = React.useState<string>("");

  return (
    <>
      <CssBaseline />
      <AppBar
        component="nav"
        elevation={0}
        sx={{
          backgroundColor: location.pathname === "/" ? "transparent" : "white",

          zIndex: "1000",
          marginTop: {
            mobile: location.pathname === "/" ? "15px" : "0",
            sm: location.pathname === "/" ? "50px" : "0",
          },
        }}
        position="relative"
      >
        <Container
          maxWidth={false}
          sx={{
            maxWidth: {
              mobile: "90%",
              xs: "85%",
            },
          }}
        >
          <Toolbar
            sx={{
              padding: "0 !important",
              height:
                location.pathname === "/"
                  ? "90px"
                  : {
                      mobile: "70px",
                      xs: "100px",
                      md: "120px",
                    },
            }}
          >
            <Stack
              direction="row"
              sx={{
                width: "100%",
                gap: "1rem",
              }}
              alignItems="center"
              justifyContent="space-between"
            >
              <Box
                component="img"
                src={
                  location.pathname === "/"
                    ? i18n.dir() === "rtl"
                      ? logo
                      : logoLtr
                    : i18n.dir() === "rtl"
                    ? greenLogo
                    : leftGreenLogo
                }
                alt="logo"
                onClick={() => navigate("/")}
                sx={{
                  cursor: "pointer",
                  width: {
                    mobile: "165px",
                    xs: "200px",
                    sm: "280px",
                    md: "275px",
                    lg: "300px",
                    xl: "350px",
                  },
                }}
              />

              <Stack
                direction="row"
                spacing={{
                  sm: 1.5,
                  md: 1,
                  lg: 2,
                  xl: 3,
                }}
                sx={{ display: { mobile: "none", md: "flex" } }}
              >
                {navItems.map((item: any, i: number) => (
                  <NavItem
                    showMenu={showMenu}
                    setShowMenu={setShowMenu}
                    link={item.link}
                    name={item.name}
                    key={item.name + i}
                    subMenu={item.subMenu}
                  />
                ))}
              </Stack>

              <Box
                sx={{
                  display: {
                    mobile: "none",
                    xs: "block",
                  },
                }}
              >
                <CustomSearchField label={t("layout.header.searchButton")} />
              </Box>
              <Stack
                direction="row"
                spacing={{
                  mobile: 0,
                  xs: 1.5,
                  sm: 2,
                }}
              >
                <LanguageButton />
                <IconButton
                  color={location.pathname === "/" ? "secondary" : "primary"}
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{
                    display: { md: "none" },
                    fontSize: { mobile: "1.25rem", xs: "1.5rem" },
                    padding: "0 !important",
                  }}
                >
                  <AiOutlineMenu />
                </IconButton>
              </Stack>
            </Stack>
          </Toolbar>
        </Container>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: {
                mobile: "250px",
                xs: "350px",
              },
              backgroundImage:
                "linear-gradient(to bottom right, white, #c3ffba59);",
            },
          }}
        >
          <DrawerAppBar navItems={navItems} />
        </Drawer>
      </Box>
    </>
  );
}
