import React from "react";
import { Box, useMediaQuery } from "@mui/material";
import serviceSvgOne from "../../assets/images/icons/service-card.svg";
import serviceSvgTwo from "../../assets/images/icons/serviceIcon2.svg";
import serviceSvgThree from "../../assets/images/icons/serviceIcon3.svg";
import serviceSvgFour from "../../assets/images/icons/serviceIcon4.svg";
import serviceSvgFive from "../../assets/images/icons/serviceIcon5.svg";
import serviceSvgSix from "../../assets/images/icons/serviceIcon6.svg";
import Lottie from "react-lottie-player";
import animatedSvg from "../../assets/images/animated/animatedSvg.json";

type Props = {
  id: number;
};

const ServiceIcon = ({ id }: Props) => {
  const iconStyles = [
    {
      id: 200,
      width: "162px",
      height: "162px",
      top: "139px",
      left: "13.2px",
      icon: serviceSvgOne,
      //smaller than 1280px styles
      smallerTop: "109px",
      smallerLeft: "-18.8px",
      //smaller than 960px styles
      smallestTop: "93px",
      smallestLeft: "-38.8px",
    },
    {
      id: 201,
      width: "162px",
      height: "162px",
      top: "86px",
      icon: serviceSvgTwo,
      //smaller than 1280px styles
      smallerTop: "59px",
      //smaller than 960px styles
      smallestTop: "40px",
    },
    {
      id: 202,
      width: "162px",
      height: "162px",
      top: "83px",
      icon: serviceSvgThree,
      //smaller than 1280px styles
      smallerTop: "53px",
      //smaller than 960px styles
      smallestTop: "37px",
    },
    {
      id: 205,
      width: "92px",
      height: "92px",
      top: "70px",
      left: "50px",
      icon: serviceSvgFour,
      //smaller than 1280px styles
      smallerTop: "40px",
      smallerLeft: "20px",
      //smaller than 960px styles
      smallestTop: "22px",
      smallestLeft: "4px",
    },
    {
      id: 20501,
      width: "92px",
      height: "92px",
      top: "108px",
      right: "28px",
      icon: serviceSvgFive,
      //smaller than 1280px styles
      smallerTop: "78px",
      smallerRight: "8px",
      //smaller than 960px styles
      smallestTop: "57px",
      smallestRight: "-13px",
    },
    {
      id: 204,
      width: "162px",
      height: "162px",
      top: "93px",
      left: "9px",
      icon: serviceSvgSix,
      //smaller than 1280px styles
      smallerTop: "63px",
      smallerLeft: "-18px",
      //smaller than 960px styles
      smallestTop: "46px",
      smallestLeft: "-37px",
    },
  ];
  const matches = useMediaQuery("(max-width:1280px)");
  const matchesSmallest = useMediaQuery("(max-width:960px)");
  return (
    <Box
      sx={{
        width: { mobile: "100%", xs: "200px", sm: "240px", md: "300px" },
        height: { mobile: "100%", xs: "200px", sm: "240px", md: "300px" },
        backgroundColor: { mobile: "unset", xs: "#428E33" },
        padding: { xs: "8px", md: "10px", lg: "12px" },
        position: "relative",
        borderRadius: "10px",
        zIndex: "2",
        "&::after": {
          content: "''",
          position: "absolute",
          top: "0",
          right: "-20px",
          width: "100%",
          height: "100%",
          borderRadius: "10px",
          backgroundImage:
            "linear-gradient(180deg, rgba(25,43,22,0.05085784313725494) 0%, rgba(255,255,255,0) 100%)",
          zIndex: "-1",
          display: { mobile: "none", xs: "block" },
        },
      }}
    >
      {iconStyles
        .filter((icon) => icon.id === id)
        .map((icon) => (
          <Box
            //styles
            key={icon.id}
            sx={{
              position: "relative",
              width: "100%",
              height: { mobile: "auto", xs: "100%" },
              backgroundColor: { mobile: "#F6F6F6", xs: "white" },
              borderRadius: "9px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: {
                mobile: "12px",
                xs: "unset",
              },
            }}
          >
            <Box
              //styles
              sx={{
                borderRadius: "6px",
                position: "absolute",
                top: matchesSmallest
                  ? icon.smallestTop
                  : matches
                  ? icon.smallerTop
                  : icon.top,
                right: matchesSmallest
                  ? icon.smallestLeft || "unset"
                  : matches
                  ? icon.smallerLeft || "unset"
                  : icon.left || "unset",
                left: matchesSmallest
                  ? icon.smallestRight || "unset"
                  : matches
                  ? icon.smallerRight || "unset"
                  : icon.right || "unset",
                width: icon.width,
                height: icon.height,
                display: {
                  mobile: "none",
                  xs: "block",
                },
              }}
            >
              <Lottie
                loop
                animationData={animatedSvg}
                play
                //styles
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
            </Box>
            <Box
              component="img"
              src={icon.icon}
              alt="service-icon"
              //styles
              sx={{
                width: { mobile: "100%", xs: "unset" },
                height: {
                  mobile: "auto",
                  xs: "unset",
                },
                objectFit: { mobile: "cover", xs: "unset" },
              }}
            />
          </Box>
        ))}
    </Box>
  );
};

export default ServiceIcon;
