import { Box, Pagination, PaginationItem } from "@mui/material";
import queryString from "query-string";
import React from "react";
import { BsPlayFill } from "react-icons/bs";
import { TiMediaPlayReverse, TiMediaPlay } from "react-icons/ti";
import { useLocation, useNavigate } from "react-router-dom";

type Props = {
  pagesNumber: number;
  setPage?: any;
};

const CustomPagination = ({ pagesNumber, setPage }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const parsedLocation = queryString.parse(location.search);
  return (
    <Box
      sx={{
        width: "100%",
        display: pagesNumber > 0 ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Pagination
        sx={{
          padding: {
            mobile: "9px 18px",
            md: "12px 24px",
            lg: "18px 36px",
          },
          borderRadius: "15px",
          backgroundColor: "#aaaaaa12",
          "& .MuiPaginationItem-root": {
            fontSize: {
              mobile: "14px",
              md: "16px",
              lg: "20px",
            },
            fontWeight: "bold",
          },
        }}
        count={pagesNumber}
        onChange={(e, page) => {
          if (setPage) {
            setPage(page);
          } else {
            navigate(
              `${location.pathname}?${queryString.stringify({
                ...parsedLocation,
                page,
              })}`
            );
          }
        }}
        color="primary"
        renderItem={(item) => (
          <PaginationItem
            sx={{
              color: "#428E33",
            }}
            slots={{ previous: TiMediaPlayReverse, next: TiMediaPlay }}
            {...item}
          />
        )}
      />
    </Box>
  );
};

export default CustomPagination;
