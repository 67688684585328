import React from "react";
import LinearGradientContainer from "../Containers/LinearGradientContainer";
import SecondaryContainer from "../Containers/SecondaryContainer";
import { Typography } from "@mui/material";
import { SmallTextTitle } from "../TextInfo/SmallTextInfo";
import grayTriangle from "../../assets/images/gray-triangle.svg";
import { useParams } from "react-router-dom";

type Props = {
  index: number;
  title: string;
};

const SearchItem = ({ index, title }: Props) => {
  const { query } = useParams<{ query: string }>();
  if (index % 2 === 0) {
    return (
      <LinearGradientContainer>
        <SecondaryContainer>
          <Typography
            sx={{
              padding: { mobile: "50px 0", md: "75px 0", xl: "100px 0" },
              fontSize: {
                mobile: "20px",
                md: "24px",
                xl: "30px",
              },
              fontWeight: "bold",
              color: "primary.main",
            }}
          >
            {query
              ? title.split(query).map((part, index, array) => (
                  <React.Fragment key={index}>
                    {part}
                    {index !== array.length - 1 && (
                      <span
                        style={{ fontWeight: "bold", background: "yellow" }}
                      >
                        {query}
                      </span>
                    )}
                  </React.Fragment>
                ))
              : title}
          </Typography>
        </SecondaryContainer>
      </LinearGradientContainer>
    );
  } else {
    return (
      <SecondaryContainer>
        <SmallTextTitle
          startIcon={<img src={grayTriangle} alt="icon-triangle" />}
          title={title}
        />
      </SecondaryContainer>
    );
  }
};

export default SearchItem;
