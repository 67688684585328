import { Box } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import AssetManagementCard from "../../components/AssetManagementCard/AssetManagementCard";
import Banner from "../../components/Banner/Banner";
import ContainerWithPadding from "../../components/Containers/ContainerWithPadding";
import SecondaryContainer from "../../components/Containers/SecondaryContainer";
import ContactUs from "../../components/LayoutComponents/ContactUs/ContactUs";
import MainLoader from "../../components/Loaders/MainLoader";
import TextInfo from "../../components/TextInfo/TextInfo";
import { useLayoutContext } from "../../context/layoutContext";
import useGetSingleApi from "../../hooks/useGetSingleApi";

type Props = {};

const AssetManagement = (props: Props) => {
  const { data: contactData, loading: contactLoading } = useLayoutContext();
  const { t, i18n } = useTranslation();
  const apiUrl = useMemo(() => "/asset_management", []);
  const { data, loading } = useGetSingleApi(apiUrl);
  const cards = [
    {
      title: "صناديق الأسهم",
      description:
        "يتألّف فريق صناديق الأسهم من مختلف أصحاب الخبرة ومدراء صناديق ومحافظ مالية ممّن يتميزون بدرجة عالية من المهارة. كما تتصف الصناديق التي يديرها هذا الفريق بسجل مميّز من الإنجازات، وتحظى بمكانة رائدة وسط نظرائها وقد حصدت العديد من الجوائز في هذا القطاع",
    },
    {
      title: "صناديق العقارات",
      description:
        "يختار بيت التمويل السعودي الكويتي (SKFH) أفضل فرص الاستثمار في العقارات بالتعاون مع أبرز مطوّري العقارات وأصحاب الممتلكات، مركِّزاً على النمو الهائل في قطاع العقارات السعودي، ومستفيداً من قدراته وخبراته في هذا المجال",
    },
    {
      title: "صناديق رأس المال",
      description:
        "نخصِّص خدمات إدارة المحافظ التقديرية بحسب مطالب المؤسّسات الاستثمارية الكبيرة وأصحاب رؤوس الأموال الضخمة في أسواق السعودية ودول مجلس التعاون الخليجي. ويلبّي هذا المنتج احتياجات المحافظ، سواء أكانت هجومية أو شبه الهجومية أو من النوع الذي يحمل عائدات وأخطار قياسية، وذلك مع كل ما تشمله من منتجات متنوّعة ذات هيكلية فريدة",
    },
  ];
  if (loading) {
    return <MainLoader />;
  }
  return (
    <>
      {data && (
        <Box>
          <Banner image={data?.image} title={data?.title} />
          <ContainerWithPadding
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "7rem",
              "&::before": {
                backgroundSize: "contain",
                transform:
                  i18n.dir() === "rtl"
                    ? "translate(-50%,-50%)"
                    : "translate(-50%, -50%) rotate(180deg)",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              {data && (
                <SecondaryContainer>
                  {" "}
                  <TextInfo
                    headerColor="#428E33"
                    showFullText
                    link={t("homePage.aboutUs.more")}
                    header={data?.title}
                    text={data?.description}
                  />
                </SecondaryContainer>
              )}
            </Box>
            <Box
              component={"section"}
              sx={{
                display: "grid",
                gridTemplateRows: {
                  mobile: "unset",
                  sm: `repeat(${data?.details?.length} 1fr)`,
                },
                gap: "3rem",
              }}
            >
              {data?.details?.length > 0 &&
                data?.details?.map(
                  (
                    card: {
                      title: string;
                      description: string;
                    },
                    index: number
                  ) => <AssetManagementCard {...card} key={index} />
                )}
            </Box>
          </ContainerWithPadding>
        </Box>
      )}
      {contactData && contactData[0] && (
        <ContactUs contactInfo={contactData[0]} />
      )}
    </>
  );
};

export default AssetManagement;
