import { Box, Skeleton, Stack } from "@mui/material";
import AboutLoader from "./AboutLoader";

type Props = {};

const MainLoader = (props: Props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: { mobile: "30px", xs: "35px", md: "40px", lg: "50px" },
      }}
    >
      <Skeleton
        sx={{
          height: "250px",
          width: "100%",
          margin: "0px",
          transform: "none",
        }}
      />
      <AboutLoader />
      <Skeleton sx={{ height: "400px", width: "100%" }} />
    </Box>
  );
};

export default MainLoader;
