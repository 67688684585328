import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { FastField, Field, FieldProps } from "formik";
import React from "react";

type Props = {
  label: string;
  name?: string;
  options?: {
    title: string;
    id: string | number;
  }[];
};

const CustomSelect = ({ label, name, options }: Props) => {
  // const [value, setValue] = React.useState("");

  // const handleChangeLabel = (event: SelectChangeEvent) => {
  //   setValue(event.target.value);
  // };

  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => (
        <FormControl
          error={meta.touched && !!meta.error}
          sx={{
            width: "100%",
            m: 1,
            minWidth: 120,
            "& .MuiFormLabel-root": {
              "&.Mui-focused": {
                color: "#707070",
              },
            },
            "& .MuiInputBase-root": {
              "&::after": {
                borderBottom: "2px solid #707070",
              },
            },

            margin: "0 !important",
          }}
          variant="standard"
        >
          <InputLabel
            sx={{
              width: "100%",
              fontSize: { mobile: "16px", md: "18px", lg: "20px" },
            }}
            id={`demo-simple-input-standard"-${name}`}
          >
            {meta.touched && meta.error ? meta.error : label}
            {/* {label} */}
          </InputLabel>
          <Select
            {...field}
            fullWidth
            labelId={`demo-simple-select-standard-${name}`}
            id={`demo-simple-select-standard"-${name}`}
            value={field?.value || ""}
            onChange={(e) => {
              // handleChangeLabel(e);
              field.onChange(e);
            }}
            label={label}
            MenuProps={{
              sx: {
                "& .MuiPaper-root": {
                  boxShadow: "unset",
                  backgroundColor: "#707070",
                  borderRadius: "8px",
                },
                "& .MuiList-root": {
                  backgroundColor: "#707070",
                  borderRadius: "8px",
                  padding: {
                    mobile: "10px 16px",
                    md: "20px 32px",
                    lg: "32px 46px",
                  },
                  "& .MuiMenuItem-root": {
                    borderBottom: "1px solid #f2f2f242",
                    color: "#F2F2F2",
                    fontSize: { mobile: "16px", md: "18px", lg: "20px" },
                    marginBottom: "15px",
                    borderRadius: "8px",

                    "&.Mui-selected": {
                      backgroundColor: "#ffffff1c",
                    },
                  },
                },
              },
            }}
          >
            {options?.map((option, index) => {
              return (
                <MenuItem key={index} value={option.id}>
                  {option.title}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
    </Field>
  );
};

export default React.memo(CustomSelect);
