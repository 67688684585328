import { Box } from "@mui/material";
import StyledListItem from "../StyledComponents/StyledListItem";

type Props = {
  items: any[];
};

const TextList = ({ items }: Props) => {
  return (
    <>
      {items && items.length > 0 ? (
        <Box
          component="ul"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: { mobile: "8px", lg: "16px" },
          }}
        >
          {items?.map((item, index) => (
            <StyledListItem
              key={index}
              text={item?.title || item}
              file={item?.file}
            />
          ))}
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default TextList;
