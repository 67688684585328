import { Box, styled } from "@mui/material";
import React from "react";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./NewSlider.scss";

type Props = {
  slides: string[];
};
const settings = {
  slidesToShow: 1,
  swipeToSlide: false,
  swipe: true,
  infinite: true,
  speed: 1500,
  autoplay: true,
  autoplaySpeed: 6000,
};
const StyledSliderContainer = styled("div")({
  width: "calc(100vw - 560px)",
  position: "relative",
  "@media (max-width: 1650px)": {
    width: "calc(100vw - 480px)",
  },
  "@media (max-width: 1440px)": { width: "calc(100vw - 400px)" },
  "@media (max-width: 1280px)": { width: "calc(100vw - 280px)" },
  "@media (max-width: 960px)": { width: "calc(100vw - 200px)" },
  "@media (max-width: 600px)": { width: "calc(100vw - 80px)" },
});
const StyledImg = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "cover",
  borderRadius: "15px",
});
const StyledImgContainer = styled("div")({
  height: "550px",
  position: "relative",
  boxShadow: "0px 0px 20px #00000017",
  borderRadius: "15px",
  "&::before": {
    content: '""',
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundImage: "linear-gradient(180deg, #00000000 0%, rgba(0,0,0,.2))",
    borderRadius: "15px",
  },
  "@media (max-width: 1650px)": {
    height: "500px",
  },
  "@media (max-width: 1440px)": {
    height: "400px",
  },
  "@media (max-width: 960px)": {
    height: "350px",
  },
  "@media (max-width: 600px)": {
    height: "300px",
  },
});

const ArrowContainer = styled("div")({
  width: "40px",
  height: "45px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "& svg": {
    borderRadius: "6px",
    backgroundColor: "#f2f2f252",
    fontSize: "30px",
    width: "100%",
    height: "100%",
    "& polyline": {
      stroke: "white",
    },
  },
});

const NewsSlider = ({ slides }: Props) => {
  return (
    <StyledSliderContainer>
      {" "}
      <Slider
        prevArrow={
          <ArrowContainer>
            {" "}
            <GrFormNext />
          </ArrowContainer>
        }
        nextArrow={
          <ArrowContainer>
            <GrFormPrevious />
          </ArrowContainer>
        }
        {...settings}
      >
        {slides.map((slide, index) => {
          return (
            <StyledImgContainer key={index}>
              <StyledImg src={slide} />
            </StyledImgContainer>
          );
        })}
      </Slider>
    </StyledSliderContainer>
  );
};

export default NewsSlider;
