import { Box, Typography } from "@mui/material";
import greenDot from "../../assets/images/green-circle.svg";
import pdfImage from "../../assets/images/icons/pdf.svg";

type Props = {
  text: string;
  file?: string;
};

const StyledListItem = ({ text, file }: Props) => {
  return (
    <Box
      component="li"
      sx={{
        display: "flex",
        alignItems: file ? "flex-end" : "center",
        gap: { mobile: "5px", xl: file ? "8px" : "30px" },
        "& img": {
          width: { mobile: file ? "32px" : "6px", md: file ? "40px" : "12px" },
          height: { mobile: file ? "auto" : "6px", md: file ? "auto" : "12px" },
          borderRadius: file ? "0" : "50%",
          backgroundColor: file ? "unset" : "#428E33",
          marginRight: {
            mobile: file ? "0px" : "12px",
            md: file ? "0px" : "6px",
          },
        },
      }}
    >
      <img src={file ? pdfImage : greenDot} alt="green dot" />

      <Typography
        component={file ? "a" : "p"}
        href={file}
        target="_blank"
        sx={{
          cursor: file ? "pointer" : "unset",
          textDecoration: file ? "underline" : "unset",
          fontSize: {
            mobile: "14px",
            xs: "1rem",
            sm: "1.25rem",
            lg: "1.5rem",
          },
          fontWeight: "600",
          color: "#428E33",
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default StyledListItem;
