import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

type Props = {};

const LanguageButton = (props: Props) => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const changeLanguage = () => {
    if (i18n.language === "ar") {
      i18n.changeLanguage("en");
    } else {
      i18n.changeLanguage("ar");
    }
  };
  return (
    <Typography
      onClick={changeLanguage}
      sx={{
        color: location.pathname === "/" ? "white" : "primary.main",
        fontSize: { mobile: ".725rem", xs: "1rem", sm: "1.25rem" },
        fontWeight: "600",
        position: "relative",
        cursor: "pointer",
        transform: "translateX(-20px)",
        "&::before": {
          content: '""',
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
          right: {
            mobile: "-10px",
            xs: "-15px",
          },
          width: { xs: "10px", mobile: "8px" },
          height: { xs: "10px", mobile: "8px" },
          borderRadius: "50%",
          backgroundColor: "primary.main",
          border: "2px solid white",
        },
      }}
    >
      {i18n.language === "ar" ? "English" : "العربية"}
    </Typography>
  );
};

export default React.memo(LanguageButton);

// if (i18n.language === "ar") {
//   if (location.search.length > 0 && !location.search.includes("lng")) {
//     navigate(`${location.pathname}${location.search}${location.hash}&lng=en`);
//   } else {
//     navigate(`${location.pathname}${location.search}${location.hash}?lng=en`);
//   }

//   //  setLanguageLoading(true);

//   // i18n.changeLanguage("en");

//   window.location.reload();

//   // setLanguageLoading(false);
// } else {
//   if (location.search.length > 0 && !location.search.includes("lng")) {
//     navigate(`${location.pathname}${location.search}${location.hash}&lng=ar`);
//   } else {
//     navigate(`${location.pathname}${location.search}${location.hash}?lng=ar`);
//   }
//   // setLanguageLoading(true);

//   // i18n.changeLanguage("ar");

//   window.location.reload();

//   // setLanguageLoading(false);
// }
