import { Box, Stack } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import CustomArrow from "./CustomArrow";
import ServiceCard from "./ServiceCard";
import serviceSvgOne from "../../../assets/images/icons/service-card.svg";
import serviceSvgTwo from "../../../assets/images/icons/serviceIcon2.svg";
import serviceSvgThree from "../../../assets/images/icons/serviceIcon3.svg";
import serviceSvgFour from "../../../assets/images/icons/serviceIcon4.svg";
import serviceSvgFive from "../../../assets/images/icons/serviceIcon5.svg";
import serviceSvgSix from "../../../assets/images/icons/serviceIcon6.svg";
import { useTranslation } from "react-i18next";

type Props = {
  slides: {
    title: string;
    enum: number;
    id: number;
  }[];
};

const ServicesSlider = ({ slides }: Props) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [firstArrowDisabled, setFirstArrowDisabled] = useState(false);
  const [secondArrowDisabled, setSecondArrowDisabled] = useState(false);
  const { i18n, t } = useTranslation();

  const settings = {
    slidesToShow: 3.5,
    slidesToScroll: i18n.dir() === "rtl" ? 1 : -1,
    swipe: false,
    infinite: true,
    speed: 1500,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 3.25,
        },
      },
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 2.75,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.5,
        },
      },
      {
        breakpoint: 350,
        settings: {
          slidesToShow: 1.2,
        },
      },
    ],
  };
  const sliderRef = useRef<Slider>(null);
  const iconStyles = [
    {
      id: 200,
      icon: serviceSvgOne,
      link: "/services/asset-management",
    },
    {
      id: 201,
      icon: serviceSvgTwo,
      link: "/services/baitk-liquidity-fund",
    },
    {
      id: 202,
      icon: serviceSvgThree,
      link: "/services/securities-management",
    },
    {
      id: 205,
      link: "/services/investment-banking",
      icon: serviceSvgFour,
    },
    {
      id: 20501,
      link: "/services/investment-banking/consulting",
      icon: serviceSvgFive,
    },
    {
      id: 204,
      icon: serviceSvgSix,
      link: "/services/preservation",
    },
  ];
  // const slides = [
  //   {
  //     title: t("homePage.services.slideOne"),
  //   },
  //   {
  //     title: t("homePage.services.slideTwo"),
  //   },
  //   {
  //     title: t("homePage.services.slideOne"),
  //   },
  //   {
  //     title: t("homePage.services.slideTwo"),
  //   },
  // ];

  useEffect(() => {
    if (activeSlide + slides.length - 0.5 === slides.length) {
      setSecondArrowDisabled(true);
      setFirstArrowDisabled(false);
    } else if (activeSlide === 0) {
      setFirstArrowDisabled(true);
      setSecondArrowDisabled(false);
    } else {
      setFirstArrowDisabled(false);
      setSecondArrowDisabled(false);
    }
  }, [activeSlide]);

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100%",
        position: "relative",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          paddingRight: {
            mobile: "0px",
            xs: "100px",
            sm: "140px",
            md: "200px",
            lg: "240px",
            xl: "280px",
          },
          width: "100vw",
          position: "relative",
        }}
      >
        <Slider
          {...settings}
          ref={sliderRef}
          arrows={false}
          swipeToSlide={true}
        >
          {slides.map((slide) => {
            return (
              <ServiceCard
                key={slide.title}
                title={slide.title}
                icon={
                  iconStyles.filter((icon) => icon.id === slide.enum)[0]?.icon
                }
                link={
                  iconStyles.filter((icon) => icon.id === slide.enum)[0]?.link
                }
              />
            );
          })}
        </Slider>
      </Box>
      <Stack
        spacing={{
          mobile: "1rem",
          xs: 4,
          lg: 5,
          xl: 6,
        }}
        sx={{
          position: "absolute",
          right: { mobile: "50%", xs: "50px", lg: "140px", xl: "160px" },
          top: { mobile: i18n.dir() === "rtl" ? "110%" : "97%", xs: "50%" },
          transform: {
            mobile: "rotate(-90deg) translateX(-50%)",
            xs: "translateY(-50%)",
          },
        }}
      >
        <CustomArrow
          direction="up"
          action={() => {
            i18n.dir() === "rtl"
              ? sliderRef.current?.slickPrev()
              : sliderRef.current?.slickNext();
          }}
        />
        <CustomArrow
          action={() => {
            i18n.dir() === "rtl"
              ? sliderRef.current?.slickNext()
              : sliderRef.current?.slickPrev();
          }}
          direction="down"
        />
      </Stack>
      ;
    </Box>
  );
};

export default ServicesSlider;
