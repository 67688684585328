import {
  Box,
  CircularProgress,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { BsPlayFill } from "react-icons/bs";
import queryString from "query-string";

type Props = {
  id: number | string;
  setIsPlaying?: any;
  isPlaying?: any;
  title?: string;
  desc?: string;
  src: string;
};

const Video = ({ id, setIsPlaying, isPlaying, title, desc, src }: Props) => {
  const [control, setControl] = useState(false);
  const [layer, setLayer] = useState(true);
  const videoRef = useRef<HTMLIFrameElement | null>(null);
  const layerRef = useRef<HTMLDivElement | null>(null);

  const handlePlayButtonClick = () => {
    setControl(true);
    setLayer(false);
    setIsPlaying(id);
  };
  const videoCode = queryString.parseUrl(src).query.v;

  return (
    <Box
      data-aos="fade-up"
      sx={{
        width: "100%",
        height: { mobile: "200px", sm: "250px", md: "300px", lg: "350px" },
        borderRadius: "15px",
        boxShadow: "0px 0px 20px #00000017",
        position: "relative",
        overflow: "hidden",
        "& iframe": {
          width: "100%",
          height: "100%",
          objectFit: "cover",
          borderRadius: "15px",
          border: "none",
        },
      }}
    >
      {src && (
        <iframe
          onPlay={() => {
            setIsPlaying(id);
          }}
          onPause={() => {
            setIsPlaying(null);
          }}
          ref={videoRef}
          src={`https://www.youtube.com/embed/${videoCode}?autoplay=${
            isPlaying === id ? 1 : 0
          }&mute=0`}
          title={`YouTube video player ${id}}`}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      )}
      {layer && (
        <>
          <Box
            ref={layerRef}
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              borderRadius: "15px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1,
              "&::before": {
                content: "''",
                zIndex: -1,
                position: "absolute",
                width: "100%",
                height: "100%",
                backgroundImage:
                  "linear-gradient(180deg, #FFFFFF00 45%, #1F201F 100%)",
              },
            }}
          >
            {false ? (
              <CircularProgress color="secondary" />
            ) : (
              <IconButton
                sx={{
                  width: "60px",
                  height: "60px",
                  padding: "2px",
                  backgroundColor: "#e2e2e22b",
                  "&:hover": {
                    backgroundColor: "#e2e2e22b",
                  },
                  "& svg": {
                    color: "#428E33",
                  },
                }}
                onClick={() => {
                  handlePlayButtonClick();
                  // videoRef;
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    padding: "4px",
                    borderRadius: "50%",
                    backgroundColor: "#ffffff80",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "#fff",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <BsPlayFill />
                  </Box>
                </Box>
              </IconButton>
            )}
          </Box>
          {title && (
            <Stack
              spacing={1}
              sx={{
                position: "absolute",
                bottom: "10%",
                left: "5%",
                zIndex: 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: "28px",
                  color: "#fff",
                  fontWeight: "bold",
                }}
              >
                {title}
              </Typography>
              <Typography>{desc}</Typography>
            </Stack>
          )}
        </>
      )}
    </Box>
  );
};

export default React.memo(Video);
