import { styled } from "@mui/material";
import React from "react";
import AnimateTap from "../../Animations/AnimateTap";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import LastNavItem from "./LastNavItems";

type Props = {
  handleCloseSubMenu: () => void;
  smallMenuItems?: {
    name: string;
    link?: string;
    subMenu?: {
      name: string;
      link?: string;
    }[];
  }[];
};

const SubMenuContainer = styled("div")({
  position: "absolute",
  minWidth: "180px",
  left: "calc(100% + 20px)",
  top: "25%",
  width: "100%",
  background: "#F2F2F2",
  padding: "14px 16px",
  borderRadius: "4px",
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  zIndex: 2,
  "&::after": {
    content: "''",
    position: "absolute",
    top: "0",
    right: "100%",
    backgroundColor: "transparent",
    width: "32px",
    height: "100%",
  },
});

const StyledItem = styled("div")({
  fontSize: "14px",
  color: "#7BA526",
  cursor: "pointer",
  position: "relative",
  "&::after": {
    height: "100%",
    width: "1px",
    backgroundColor: "#7BA526",
    position: "absolute",
    top: "0",
    right: "0",
    content: "''",
    display: "none",
  },
});
const StyledLine = styled("div")({
  position: "absolute",
  width: "48px",
  height: "1px",
  backgroundColor: "#fff",
  left: "100%",
  zIndex: 1,
  top: "50%",
});
const StyledDot = styled("div")({
  position: "absolute",
  width: "8px",
  height: "8px",
  backgroundColor: "#fff",
  left: "calc(100% - 4px)",
  zIndex: 1,
  top: "calc(50% - 4px)",
  borderRadius: "50%",
});
const StyledVerticalLine = styled("div")({
  position: "absolute",
  width: "1px",
  height: "100%",
  backgroundColor: "#fff",
  left: "calc(100%)",
  top: "0",
  zIndex: 1,
});

const NavItemSubMenu = ({ handleCloseSubMenu, smallMenuItems }: Props) => {
  const [showLastMenu, setShowLastMenu] = React.useState<string | null>(null);
  return (
    <>
      <StyledVerticalLine />
      <StyledDot />
      <StyledLine />
      <SubMenuContainer
        onMouseLeave={handleCloseSubMenu}
        sx={{
          "&::before": {
            width: "80px",
            height: "calc(100% + 40px)",
            backgroundColor: "transparent",
            position: "absolute",
            left: "-20px",
            top: "-20px",
            content: "''",
            zIndex: -1,
          },
          "&::after": {
            height: "calc(100% - 28px)",
            width: "1px",
            backgroundColor: "#7BA526",
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            right: "16px",
            opacity: "0.3",
            content: "''",
          },
        }}
      >
        {smallMenuItems &&
          smallMenuItems?.length > 0 &&
          smallMenuItems.map((item, index) => {
            return (
              <StyledItem
                sx={{
                  "&:hover": {
                    "&::after": {
                      display:
                        item?.subMenu && item?.subMenu?.length > 0
                          ? "block"
                          : "none",
                    },
                  },
                }}
                key={item.name + index}
                onMouseEnter={() => {
                  if (item.subMenu && item.subMenu.length > 0) {
                    setShowLastMenu(item.name);
                  } else {
                    setShowLastMenu(null);
                  }
                }}
              >
                <AnimateTap>
                  {item.link ? (
                    <Link to={item.link}>{item.name}</Link>
                  ) : (
                    <>{item.name}</>
                  )}
                </AnimateTap>
                {item.subMenu &&
                  item.subMenu.length > 0 &&
                  showLastMenu === item.name && (
                    <LastNavItem
                      subMenu={item.subMenu}
                      handleCloseMenu={() => {
                        setShowLastMenu(null);
                      }}
                    />
                  )}
                {/* {item.subMenu && item.subMenu.length > 0 && showLastMenu && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.2 }}
                    >
                      <NavItemSubMenu
                        smallMenuItems={item.subMenu}
                        handleCloseSubMenu={() => {
                          setShowLastMenu(false);
                        }}
                      />
                    </motion.div>
                  )} */}
              </StyledItem>
            );
          })}
      </SubMenuContainer>
    </>
  );
};

export default NavItemSubMenu;
