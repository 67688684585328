import { Box } from "@mui/material";
import React from "react";
import SecondaryContainer from "./SecondaryContainer";
import { useTranslation } from "react-i18next";
import cardBg from "../../assets/images/asset-bg.png";
import SmallerContainer from "./SmallerContainer";

type Props = {
  children: React.ReactNode;
  sx?: any;
};

const LinearGradientContainer = ({ children, sx }: Props) => {
  const { i18n } = useTranslation();
  return (
    <Box
      //   data-aos={i18n.dir() === "rtl" ? "fade-left" : "fade-right"}
      //   data-aos-duration="1000"
      sx={{
        width: "100%",
        position: "relative",
        "&::before": {
          zIndex: -1,
          content: '""',
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "100%",
          height: "100%",
          transform:
            i18n.dir() === "rtl"
              ? "translate(-50%,-50%)"
              : "translate(-50%, -50%) rotate(180deg)",
          background: `url(${cardBg})`,
          backgroundRepeat: "repeat",
          backgroundSize: {
            mobile: "cover",
            xs: "contain",
            // sm: "contain",
            // md: "contain",
            // lg: "contain",
            xl: "cover",
          },
          backgroundPosition: "center",
        },
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default LinearGradientContainer;
