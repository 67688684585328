import React from "react";
import { MdOutlineExpandMore } from "react-icons/md";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

interface Props {
  navItems: NavItem[];
}

interface NavItem {
  name: string;
  link: string;
  subMenu?: NavItem[];
}

const mainFontStyle = {
  fontSize: "1rem",
  fontWeight: "700",
  cursor: "pointer",
  color: "#767676",
};

const DrawerNavItems: React.FC<Props> = ({ navItems }) => {
  const navigate = useNavigate();
  const renderNavItem = (item: NavItem) => {
    if (item.subMenu && item.subMenu.length > 0) {
      return (
        <Accordion
          key={item.name}
          sx={{
            margin: "0 !important",
            backgroundColor: "transparent",
            ".Mui-expanded": {
              color: "#767676",
            },
            boxShadow: "none",
            "&:before": {
              display: "none",
            },
          }}
        >
          <AccordionSummary
            expandIcon={<MdOutlineExpandMore />}
            aria-controls={item.name}
            id={item.name}
            sx={{
              transition: "all 0.1s ease-in-out",
              padding: "0",
              minHeight: "40px",
              "& .MuiAccordionSummary-content": {
                margin: "0",
              },
            }}
          >
            <Typography
              sx={{
                ...mainFontStyle,
              }}
              onClick={(e) => {
                e.stopPropagation();
                navigate(item.link);
              }}
            >
              {item.name}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: "transparent" }}>
            <Stack spacing={1}>
              {item.subMenu.map((subItem) => (
                <div key={subItem.name}>{renderNavItem(subItem)}</div>
              ))}
            </Stack>
          </AccordionDetails>
        </Accordion>
      );
    } else {
      return (
        <Link
          key={item.name}
          to={item.link}
          style={{
            minHeight: "40px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              ...mainFontStyle,
            }}
          >
            {item.name}
          </Typography>
        </Link>
      );
    }
  };

  return (
    <Stack
      sx={{
        gap: "16px",
      }}
      className="drawer-nav-items"
    >
      {navItems.map(renderNavItem)}
    </Stack>
  );
};

export default DrawerNavItems;
