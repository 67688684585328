import { Box } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Banner from "../../components/Banner/Banner";
import ContainerWithPadding from "../../components/Containers/ContainerWithPadding";
import SecondaryContainer from "../../components/Containers/SecondaryContainer";
import MainLoader from "../../components/Loaders/MainLoader";
import QuestionItems from "../../components/QuestionItem/QuestionItems";
import { StyledTitle } from "../../components/Services/StyledComponents";
import useGetSingleApi from "../../hooks/useGetSingleApi";

type Props = {};

const Qa = (props: Props) => {
  const { t, i18n } = useTranslation();
  const apiUrl = useMemo(() => "/our_strength", []);
  const { data, loading } = useGetSingleApi(apiUrl);
  if (loading) {
    return <MainLoader />;
  }
  return (
    <>
      {data && (
        <Box>
          <Banner
            image={data?.image}
            title={data?.title}
            text={data?.description}
          />

          <ContainerWithPadding
            sx={{
              "&::before": {
                transform:
                  i18n.dir() === "rtl"
                    ? "translate(-50%,-50%)"
                    : "translate(-50%, -50%) rotate(180deg)",
                backgroundSize: {
                  mobile: "cover",
                },
              },
            }}
          >
            <Box
              sx={{
                zIndex: 2,
                width: "100%",
              }}
            >
              <SecondaryContainer>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: {
                      mobile: "40px",
                      md: "60px",
                      lg: "80px",
                    },
                  }}
                >
                  <StyledTitle
                    sx={{
                      color: "#428E33",
                      fontSize: {
                        mobile: "30px",
                        md: "40px",
                        lg: "50px",
                      },
                    }}
                  >
                    {t("layout.header.subLinks.commonQuestions")}
                  </StyledTitle>
                  <QuestionItems />
                </Box>
              </SecondaryContainer>
            </Box>
          </ContainerWithPadding>
        </Box>
      )}
    </>
  );
};

export default Qa;
