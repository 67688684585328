// function to check if the sliderImage is a link for video or image
const checkVideoOrImage = (sliderItem: string) => {
  if (sliderItem.includes("mp4" || "webm" || "ogg" || "mov")) {
    return 1;
  } else {
    return 2;
  }
};

export default checkVideoOrImage;
