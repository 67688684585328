import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomTextField from "../../CustomInputs/CustomTextField";

type Props = {
  type?: "page";
};

const ContactFields = ({ type }: Props) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: { mobile: "1rem", sm: "2.5rem" },
      }}
    >
      <CustomTextField
        color={type && "warning"}
        name="name"
        label={t("layout.contactUs.form.name")}
      />
      <CustomTextField
        color={type && "warning"}
        name="email"
        label={t("layout.contactUs.form.email")}
      />
      <CustomTextField
        color={type && "warning"}
        name="address"
        label={t("layout.contactUs.form.title")}
      />
      <CustomTextField
        color={type && "warning"}
        name="message"
        label={t("layout.contactUs.form.message")}
      />
    </Box>
  );
};

export default ContactFields;
