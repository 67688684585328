import axios from "axios";

const baseUrl = process.env.REACT_APP_API_URL;
// const axiosService = axios.create({
//   headers: {
//     "Content-Type": "application/json",
//   },
// });

export const get = async (url: string) => {
  try {
    const response = await axios.get(baseUrl + '/api' + url);
    return response.data;
  } catch (error) {}
};

// const post = async (url: string, body: any) => {
//   try {
//     const response = await axiosService.post(url, body);
//     return response.data;
//   } catch (error) {
//   }
// };
