import { Box, Stack } from "@mui/material";
import React from "react";
import SmallTextInfo from "../TextInfo/SmallTextInfo";
import SecondaryContainer from "../Containers/SecondaryContainer";
import grayTriangle from "../../assets/images/gray-triangle.svg";

type Props = {
  fundData: {
    id: number;
    title: string;
    description: string;
    private_fund_details: {
      title: string;
      description: string;
    }[];
  }[];
};

const ClosedInvestmentFundTab = ({ fundData }: Props) => {
  return (
    <SecondaryContainer>
      <Stack spacing={4}>
        {fundData.map((fund) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: { mobile: "30px", sm: "38px", xl: "50px" },
            }}
          >
            <Box
              sx={{
                position: "relative",
                paddingBottom: "50px",
                "&::after": {
                  content: "''",
                  position: "absolute",
                  bottom: "0px",
                  left: "0px",
                  height: "1px",
                  width: { mobile: "100%", md: "340px" },
                  backgroundColor: "#4EA83C",
                },
              }}
            >
              <SmallTextInfo
                title={fund.title}
                titleColor="#4EA83C"
                text={fund.description}
                startIcon={
                  <Box
                    sx={{
                      width: "20px",
                      height: "20px",
                      backgroundColor: "#73BB65",
                      borderRadius: "50%",
                    }}
                  />
                }
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: { mobile: "30px", sm: "38px", xl: "50px" },
              }}
            >
              {fund?.private_fund_details?.map((item, index) => (
                <SmallTextInfo
                  key={index}
                  title={item.title}
                  text={item.description}
                  startIcon={
                    <Box
                      component="img"
                      src={grayTriangle}
                      alt="gray-triangle"
                      sx={{
                        marginTop: {
                          mobile: "7px",
                        },
                      }}
                    />
                  }
                />
              ))}
            </Box>
          </Box>
        ))}
      </Stack>
    </SecondaryContainer>
  );
};

export default ClosedInvestmentFundTab;
