import { styled } from "@mui/material";
import React from "react";
import serviceIcon from "../../../assets/images/icons/service-card.svg";
import { useNavigate } from "react-router-dom";

type Props = {
  title: string;
  icon?: string;
  link?: string;
};

const StyledCard = styled("div")(({ theme }) => ({
  width: "410px",
  cursor: "pointer",
  height: "300px",
  backgroundColor: "#fff",
  borderRadius: "10px",
  border: "1px solid #428E33",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: "30px",
  alignItems: "center",
  padding: "20px",
  "& img": {
    maxWidth: "130px",
    maxHeight: "130px",
    objectFit: "cover",
  },
  "& h3": {
    color: "#707070",
    fontSize: "30px",
    fontWeight: "700",
    textAlign: "center",
  },

  // 1650px
  "@media (max-width: 1650px)": {
    width: "330px",
    height: "250px",
    gap: "24px",
    "& img": {
      maxWidth: "80px",
    },
    "& h3": {
      fontSize: "24px",
    },
  },
  //1440px
  "@media (max-width:1440px)": {
    width: "260px",
    height: "200px",
    gap: "12px",
    "& img": {
      maxWidth: "60px",
    },
    "& h3": {
      fontSize: "20px",
    },
  },
  // 1280px
  "@media (max-width:1280px)": {
    width: "220px",
    height: "170px",
    gap: "8px",
    "& img": {
      maxWidth: "50px",
    },
    "& h3": {
      fontSize: "16px",
    },
  },
  // 960px
  "@media (max-width:960px)": {
    gap: "16px",
    width: "200px",
    height: "140px",
  },
}));

const ServiceCard = ({ title, icon, link }: Props) => {
  const navigate = useNavigate();
  return (
    <StyledCard
      onClick={() => {
        if (link) {
          navigate(link);
        }
      }}
    >
      <img src={icon || serviceIcon} alt={title} />
      <h3>{title}</h3>
    </StyledCard>
  );
};

export default React.memo(ServiceCard);
