import { CacheProvider } from "@emotion/react";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AOS from "aos";
import "aos/dist/aos.css";
import { AnimatePresence } from "framer-motion";
import { useEffect, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import "./App.scss";
import Layout from "./components/Layout/Layout";
import { LayoutProvider } from "./context/layoutContext";
import { cacheLtr, cacheRtl } from "./helpers/cache.js";
import { ltrTheme, rtlTheme } from "./helpers/themes.js";
import "./i18n";
import AssetManagement from "./pages/AssetManagement/AssetManagement";
import AuctionFund from "./pages/AuctionFund/AuctionFund";
import Awards from "./pages/Awards/Awards";
import Complaints from "./pages/Complaints/Complaints";
import ContactUsPage from "./pages/ContactUsPage/ContactUsPage";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import Home from "./pages/Home/Home";
import InstitutionalView from "./pages/InstitutionalView/InstitutionalView";
import Consulting from "./pages/InvestmentBanking/Consulting";
import InvestmentBanking from "./pages/InvestmentBanking/Index";
import Ordering from "./pages/InvestmentBanking/Ordering";
import InvestmentPortfolioManagement from "./pages/InvestmentPortfolioManagement/InvestmentPortfolioMangement";
import Jobs from "./pages/Jobs/Jobs";
import Leadership from "./pages/Leadership/Leadership";
import News from "./pages/News/News";
import SingleNews from "./pages/News/SingleNews";
import OrganizationalStructure from "./pages/OrganizationalStructure/OrganizationalStructure";
import OurStrength from "./pages/OurStrength/OurStrength";
import Photos from "./pages/Photos/Photos";
import Preservation from "./pages/Preservation/Preservation";
import Qa from "./pages/Q&A/Qa";
import InvestmentFund from "./pages/RealEstateInvestmentFund/InvestmentFund";
import RealEstateInvestmentFund from "./pages/RealEstateInvestmentFund/RealEstateInvestmentFund";
import Reports from "./pages/Reports/Reports";
import SaudiEquityFund from "./pages/SaudiEquityFund/SaudiEqutiyFund";
import SearchResults from "./pages/SearchResults/SearchResults";
import SecuritiesManagement from "./pages/SecuritiesManagement/SecuritiesManagement";
import Services from "./pages/Services/Services";
import Videos from "./pages/Videos/Videos";
import YourRights from "./pages/YourRights/YourRights";

function App() {
  // document.addEventListener("contextmenu", (e) => {
  //   e.preventDefault();
  // });
  // document.onkeydown = function (e) {
  //   if (
  //     e.ctrlKey &&
  //     (e.keyCode === 85 ||
  //       e.keyCode === 67 ||
  //       e.keyCode === 73 ||
  //       e.keyCode === 74)
  //   ) {
  //     return false;
  //   }
  // };
  const { i18n } = useTranslation();
  const dir = i18n.dir();
  useLayoutEffect(() => {
    document.body.setAttribute("dir", dir);
  }, [dir]);

  //add the lang to axios headers
  // axios.defaults.headers.common["language"] = i18n.language;
  // axios.defaults.headers.common["Access-Control-Allow-Headers"] = "*";

  useEffect(() => {
    AOS.init({
      once: true,
    });
    AOS.refresh();
  }, []);

  return (
    <>
      <CacheProvider value={dir === "rtl" ? cacheRtl : cacheLtr}>
        <ThemeProvider theme={dir === "rtl" ? rtlTheme : ltrTheme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <LayoutProvider>
              <AnimatePresence>
                <Layout>
                  <Routes>
                    {/* first Main Routes */}
                    <Route path="/" element={<Home />} />
                    <Route path="/institutional-view">
                      <Route index element={<InstitutionalView />} />
                    </Route>
                    <Route path="/fatwa-authority" element={<OurStrength />} />
                    {/* <Route path="/board-message" element={<BoardWord />} /> */}
                    <Route path="/leadership" element={<Leadership />} />
                    <Route
                      path="/organizational-structure"
                      element={<OrganizationalStructure />}
                    />
                    <Route path="/reports" element={<Reports />} />
                    <Route path="/awards" element={<Awards />} />
                    {/* Second Main Routes */}
                    <Route path="/services">
                      <Route index element={<Services />} />
                      <Route
                        path="asset-management"
                        element={<AssetManagement />}
                      />
                      <Route path="baitk-liquidity-fund">
                        <Route index element={<RealEstateInvestmentFund />} />
                        <Route path=":id" element={<InvestmentFund />} />
                      </Route>
                      <Route path="preservation" element={<Preservation />} />
                      <Route path="investment-banking">
                        <Route index element={<InvestmentBanking />} />
                        <Route path="ordering" element={<Ordering />} />
                        <Route path="consulting" element={<Consulting />} />
                      </Route>
                      <Route path="securities-management">
                        <Route index element={<SecuritiesManagement />} />
                        <Route
                          path="investment-portfolio-management"
                          element={<InvestmentPortfolioManagement />}
                        />
                        {/* main funds */}
                        <Route
                          path="murabahat-wallets"
                          element={<SaudiEquityFund />}
                        />
                        <Route path="sukuk-wallets" element={<AuctionFund />} />
                      </Route>
                    </Route>
                    {/* media routes */}
                    <Route path="/media-center">
                      <Route path="videos" element={<Videos />} />
                      <Route path="photos" element={<Photos />} />
                      <Route path="news" element={<News />} />
                      <Route path="news/:id" element={<SingleNews />} />
                      {/* <Route
                      path="social-responsibility"
                      element={<SocialResponsibility />}
                    /> */}
                      <Route path="your-rights" element={<YourRights />} />
                    </Route>
                    {/* non dynamic routes */}
                    <Route path="/jobs" element={<Jobs />} />
                    <Route path="/Q&A" element={<Qa />} />
                    <Route path="/contact-us" element={<ContactUsPage />} />
                    <Route path="/complaints" element={<Complaints />} />
                    <Route
                      path="/search-results/:query"
                      element={<SearchResults />}
                    />

                    {/* error */}
                    <Route path="*" element={<ErrorPage />} />
                  </Routes>
                </Layout>
              </AnimatePresence>
            </LayoutProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </CacheProvider>
    </>
  );
}

export default App;
