import { Box, Stack, Typography } from "@mui/material";
import { useLayoutContext } from "../../context/layoutContext";
import { useNavigate } from "react-router-dom";

interface PhotoLineProps {
  odd?: boolean;
  photos: any;
  type: "photo" | "news";
}
const PhotoLine = ({ odd, photos, type }: PhotoLineProps) => {
  const { photoModal, setPhotoModal, setPhotoModalData } = useLayoutContext();
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: {
          mobile: odd ? "1.5fr 1fr" : "1fr 1.5fr",
          md: odd ? "1.5fr 1fr 1fr" : "1fr 1fr 1.5fr",
        },
        gap: "16px",
      }}
    >
      {photos.map((photo: any) => (
        <Box
          data-aos="fade-up"
          key={photo.id}
          onClick={() => {
            if (type === "photo") {
              setPhotoModal(true);
              setPhotoModalData({
                title: photo?.title,
                file: photo?.file,
                id: photo?.id,
              });
            } else {
              navigate(`/media-center/news/${photo.id}`);
            }
          }}
          sx={{
            position: "relative",
            borderRadius: "15px",
            cursor: "pointer",
            height: {
              mobile: "300px",
              sm: "400px",
              md: "420px",
              xl: "480px",
            },
          }}
        >
          <Box
            component="img"
            key={photo.id}
            src={photo.file}
            alt={photo.title}
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "15px",
            }}
          />
          <Box
            className="overlay"
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
              width: "100%",
              height: "100%",
              borderRadius: "15px",
              padding: { mobile: "20px 10px", sm: "48px 36px" },
              display: "flex",
              alignItems: "flex-end",
              "&::before": {
                zIndex: -1,
                borderRadius: "15px",
                content: '""',
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
                width: "100%",
                height: "100%",
                backgroundImage:
                  "linear-gradient(180deg, #FFFFFF00 45%, #1F201F 100%)",
              },
            }}
          >
            {photo?.title && (
              <Stack spacing={1}>
                <Typography
                  sx={{
                    fontSize: {
                      mobile: "18px",
                      sm: "20px",
                      md: "24px",
                      xl: "28px",
                    },
                    color: "#fff",
                    fontWeight: "bold",
                  }}
                >
                  {photo?.title}
                </Typography>
                {photo.date && (
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: "#D6D6D6",
                    }}
                  >
                    بتاريخ: {photo?.date}
                  </Typography>
                )}
              </Stack>
            )}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default PhotoLine;
