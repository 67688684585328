import { Box, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { contactInfoType } from "../../../helpers/types";

type Props = {
  contactInfo: contactInfoType;
  type?: "page";
};
const StyledTitle = styled("h2")({
  width: "fit-content",
  fontSize: "1.125rem",
  fontWeight: 700,
  color: "#fff",
  "@media (max-width: 1440px)": {
    fontSize: "1rem",
  },
  "@media (max-width:960px)": {
    fontSize: ".8rem",
  },
});
const StyledInfo = styled("p")({
  width: "fit-content",
  fontSize: "1.125rem",
  fontWeight: 600,
  color: "#95EC84",
  "@media (max-width: 1440px)": {
    fontSize: "1rem",
  },
  "@media (max-width:960px)": {
    fontSize: ".8rem",
  },
});

const ContactInfo = ({ contactInfo, type }: Props) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        paddingTop: "1rem",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
        gap: { mobile: "20px", sm: "20px" },
      }}
    >
      {contactInfo &&
        contactInfo.map((item: any, index: number) => {
          if (item.contact_type === 2) {
            return (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "6px",
                }}
                key={item.contact_type}
              >
                <StyledTitle
                  sx={{
                    color: type ? "#707070" : "#fff",
                  }}
                >
                  {t("layout.contactUs.phone")}
                </StyledTitle>
                <StyledInfo
                  sx={{
                    color: type ? "#428E33" : "#95EC84",
                  }}
                >
                  {item?.contact_input}
                </StyledInfo>
              </Box>
            );
          }
          if (item.contact_type === 1) {
            return (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "6px",
                }}
                key={item.contact_type}
              >
                <StyledTitle
                  sx={{
                    color: type ? "#707070" : "#fff",
                  }}
                >
                  {" "}
                  {t("layout.contactUs.email")}
                </StyledTitle>
                <StyledInfo
                  sx={{
                    color: type ? "#428E33" : "#95EC84",
                  }}
                >
                  {item?.contact_input}
                </StyledInfo>
              </Box>
            );
          }
          if (item.contact_type === 4) {
            return (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "6px",
                }}
                key={item.contact_type}
              >
                <StyledTitle
                  sx={{
                    color: type ? "#707070" : "#fff",
                  }}
                >
                  {t("layout.contactUs.fax")}
                </StyledTitle>
                <StyledInfo
                  sx={{
                    color: type ? "#428E33" : "#95EC84",
                  }}
                >
                  {item?.contact_input}
                </StyledInfo>
              </Box>
            );
          }
          if (item.contact_type === 3) {
            return (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "6px",
                }}
                key={item.contact_type}
              >
                <StyledTitle
                  sx={{
                    color: type ? "#707070" : "#fff",
                  }}
                >
                  {" "}
                  {t("layout.contactUs.address")}
                </StyledTitle>
                <StyledInfo
                  sx={{
                    color: type ? "#428E33" : "#95EC84",
                  }}
                >
                  {item?.contact_input}
                </StyledInfo>
              </Box>
            );
          }
        })}
    </Box>
  );
};

export default ContactInfo;
