import { Box } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Banner from "../../components/Banner/Banner";
import ContainerWithPadding from "../../components/Containers/ContainerWithPadding";
import SecondaryContainer from "../../components/Containers/SecondaryContainer";
import MainLoader from "../../components/Loaders/MainLoader";
import TextInfo from "../../components/TextInfo/TextInfo";
import useGetSingleApi from "../../hooks/useGetSingleApi";

type Props = {};

const SaudiEquityFund = (props: Props) => {
  const { t, i18n } = useTranslation();
  const apiUrl = useMemo(() => "/stock_share_fund", []);
  const { data, loading } = useGetSingleApi(apiUrl);
  if (loading) {
    return <MainLoader />;
  }
  return (
    <>
      {data && (
        <Box>
          <Banner
            image={data?.image}
            title={data?.title}
            // text={data?.description}
          />

          {data && (
            <ContainerWithPadding
              sx={{
                "&::before": {
                  backgroundSize: "contain",
                  transform:
                    i18n.dir() === "rtl"
                      ? "translate(-50%,-50%)"
                      : "translate(-50%, -50%) rotate(180deg)",
                },
              }}
            >
              <SecondaryContainer>
                <TextInfo
                  headerColor="#428E33"
                  link={t("homePage.aboutUs.more")}
                  header={data?.title}
                  text={data?.description}
                />
              </SecondaryContainer>
              {/* <MainFund data={data} /> */}
            </ContainerWithPadding>
          )}
        </Box>
      )}
    </>
  );
};

export default SaudiEquityFund;
