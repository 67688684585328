import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import SecondaryContainer from "../Containers/SecondaryContainer";
import StyledHeader from "../StyledComponents/StyledHeader";
import { makeASmallerText } from "../../helpers/smallerText";

type Props = {
  image: string;
  title: string;
  text?: string;
};

const Banner = (props: Props) => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${props.image})`,
        position: "relative",

        "&::before": {
          content: '""',
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          height: "100%",
          backgroundImage:
            "linear-gradient(180deg, rgba(25,43,22,0.74) 0%, rgba(255,255,255,0.029972) 100%)",
        },

        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: {
          mobile: "250px",
          xs: "260px",
          sm: "280px",
          md: "300px",
          lg: "320px",
          xl: "330px",
        },
      }}
    >
      <Box
        sx={{
          position: "relative",
          zIndex: 1,
          height: "100%",
        }}
      >
        <SecondaryContainer>
          <Stack
            justifyContent="center"
            spacing={1}
            sx={{
              height: "100%",
            }}
          >
            <StyledHeader
              sx={{
                color: "white",
                paddingTop: {
                  mobile: "16px",
                  md: "24px",
                },
              }}
            >
              {props.title}
            </StyledHeader>
            {props.text && (
              <Typography
                variant="h2"
                sx={{
                  fontWeight: "600",
                  fontSize: {
                    mobile: "22px",
                    xs: "24px",
                    md: "28px",
                    lg: "30px",
                  },
                  color: "white",
                }}
              >
                {props.text && makeASmallerText(props.text, 5)}
              </Typography>
            )}
          </Stack>
        </SecondaryContainer>
      </Box>
    </Box>
  );
};

export default Banner;
