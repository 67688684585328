import { Box, Button, styled, useMediaQuery } from "@mui/material";
import { makeASmallerText } from "../../helpers/smallerText";
import ServiceIcon from "./ServiceIcon";
import { StyledDesc, StyledTitle } from "./StyledComponents";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { s } from "@fullcalendar/core/internal-common";

type Props = {
  title: string;
  description: string;
  id: number;
  index: number;
};

const Service = ({ title, description, id, index }: Props) => {
  const isMobile = useMediaQuery("(max-width:960px)");
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Box
      data-aos="fade-zoom-in"
      data-aos-duration="200"
      //styles
      sx={{
        position: "relative",
        display: "grid",
        width: "100%",
        alignItems: "center",
        gridTemplateColumns: {
          mobile: "1fr",
          xs: "1fr",
          sm: "1fr 80px",
          md: "1fr 120px",
          xl: "1fr 150px",
        },
        gap: { mobile: "20px", sm: "40px", md: "60px", lg: "70px", xl: "80px" },
        padding: "50px 0 0 0",
        "&::after": {
          display: {
            mobile: index === 5 ? "none" : "block",
            xs: "none",
          },
          position: "absolute",
          content: "''",
          height: "5px",
          width: "calc(100% + 80px)",
          left: "-40px",
          backgroundColor: "#F3F3F3",
          bottom: "-30px",
        },
      }}
    >
      <Box
        //styles
        sx={{
          display: "grid",
          gridTemplateColumns: {
            mobile: "70px 1fr",
            xs: "200px 1fr",
            sm: "240px 1fr",
            md: "300px 1fr",
          },
          gap: {
            mobile: "20px",
            xs: "0px",
          },
        }}
      >
        <ServiceIcon id={id} />
        <Box
          //styles
          sx={{
            padding: { mobile: "0px", xs: "20px 0 0 0" },
            marginLeft: { mobile: "0px", xs: "-30px" },
          }}
        >
          <Box
            //styles
            sx={{
              borderTop: "3px solid #ffffff54",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: {
                mobile: "0",
                xs: "12px 12px 12px 40px",
                sm: "22px 30px 22px 65px",
                md: "22px 60px",
                lg: "24px 65px",
                xl: "30px 75px",
              },
              gap: { mobile: "10px", xs: "15px" },
              backgroundColor: {
                mobile: "unset",
                xs:
                  index === 0 || index === 5
                    ? "#3C7431"
                    : index === 1 || index === 3
                    ? "#59A44A"
                    : "#6BA85F",
              },
              borderRadius: "0 18px 18px 0",
              height: "100%",
            }}
          >
            <StyledTitle>{title}</StyledTitle>
            <StyledDesc id={String(id)}>
              {" "}
              {makeASmallerText(description, isMobile ? 25 : 40)}
            </StyledDesc>
          </Box>
        </Box>
      </Box>
      <Button
        variant="contained"
        //styles
        sx={{
          maxWidth: "150px",
          alignSelf: "center",
          justifySelf: "end",
          flex: "1",
          fontSize: { xs: "14px", md: "16px", xl: "20px" },
          height: { xs: "45px", md: "60px", xl: "70px" },
          width: { mobile: "70px", xs: "100%", md: "100%" },
          fontWeight: "600",
          borderRadius: "10px",
          backgroundColor:
            id === 1 || id === 6
              ? "#3C7431"
              : id === 2 || id === 4
              ? "#59A44A"
              : "#6BA85F",
        }}
        onClick={() => {
          switch (id) {
            case 200:
              navigate("/services/asset-management");
              break;
            case 201:
              navigate("/services/baitk-liquidity-fund");
              break;
            case 202:
              navigate("/services/securities-management");
              break;
            case 20501:
              navigate("/services/investment-banking/consulting");
              break;
            case 204:
              navigate("/services/preservation");
              break;
            case 205:
              navigate("/services/investment-banking");
              break;
          }
        }}
      >
        {t("homePage.services.more")}
      </Button>

      <Box
        component="span"
        //styles
        sx={{
          display: { mobile: "none", xs: "flex" },
          position: "absolute",
          top: "0",
          left: "-35px",
          width: { xs: "45px", sm: "60px", md: "65px", lg: "75px", xl: "85px" },
          height: "100%",
          backgroundColor:
            index === 0 || index === 5
              ? "#3C7431"
              : index === 1 || index === 3
              ? "#59A44A"
              : "#6BA85F",
          zIndex: "5",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "48px",
          color: "#fff",
          fontWeight: "700",
          padding: { xs: "50px 0 65px 0", sm: "50px 0 0 0" },
          borderRadius:
            index === 0 ? "25px 25px 0 0" : index === 5 ? "0 0 25px 25px" : "0",
        }}
      >
        {index + 1}
      </Box>
    </Box>
  );
};

export default Service;
