import React, { useContext, useMemo, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import useGetMultiApi from "../hooks/useGetMultiApi";
import reducer from "../reducers/mainReducer";

const LayoutContext = React.createContext();
const initialState = {};

export const LayoutProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [photoModal, setPhotoModal] = useState(false);
  const [photoModalData, setPhotoModalData] = useState({
    title: "",
    file: "",
    id: null,
  });

  const { i18n } = useTranslation();
  const dir = i18n.dir();

  React.useLayoutEffect(() => {
    document.body.setAttribute("dir", i18n.dir());
  }, [dir]);

  const apiUrls = useMemo(
    () => ["/contact_info", "/social_links", "/funds"],
    []
  );

  const { data, loading } = useGetMultiApi(apiUrls);

  return (
    <LayoutContext.Provider
      value={{
        data,
        loading,
        photoModal,
        setPhotoModal,
        ...state,
        setPhotoModalData,
        photoModalData,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};
// make sure use
export const useLayoutContext = () => {
  return useContext(LayoutContext);
};
