import { Box, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LinearGradientContainer from "../Containers/LinearGradientContainer";
import StyledHeader from "../StyledComponents/StyledHeader";
import assetsDivider from "../../assets/images/assets-divider.svg";
import SecondaryContainer from "../Containers/SecondaryContainer";

type Props = {
  setTab: React.Dispatch<React.SetStateAction<number>>;
  tabHeaders?: {
    first: string;
    second: string;
    third: string;
  };
};
const headerStyles = {
  flex: "1",
  alignSelf: "center",
  color: "#707070",
  cursor: "pointer",
  fontWeight: "bold",
  textAlign: "center",
  fontSize: {
    mobile: "16px",
    sm: "24px",
    md: "30px",
  },
};

const RealEstateTabsHeader = ({ setTab, tabHeaders }: Props) => {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const { t, i18n } = useTranslation();
  return (
    <LinearGradientContainer
      sx={{
        "&::before": {
          backgroundSize: "contain",
        },
      }}
    >
      <SecondaryContainer>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: { mobile: "16px", xs: "35px" },
            position: "relative",
            zIndex: 1,
            padding: {
              mobile: tabHeaders ? "60px 0" : "35px 0 65px 0",
              xl: tabHeaders ? "125px 0" : "65px 0 115px 0",
            },
          }}
        >
          {!tabHeaders && (
            <Box
              sx={{
                position: "relative",
                display: "flex",
                width: "100%",
                gap: { mobile: "0px", xs: "20px", md: "35px" },
                alignItems: "center",
              }}
            >
              <Box
                component={"img"}
                src={assetsDivider}
                alt="Assets-Divider"
                sx={{
                  // marginTop: { mobile: "16px" },
                  width: {
                    mobile: "16px",
                    md: "35px",
                  },
                  transform: i18n.dir() === "ltr" ? "rotate(180deg)" : "unset",
                }}
              />
              <StyledHeader
                sx={{
                  color: "#428E33",
                }}
              >
                {t("realEstateInvestmentFund.title")}
              </StyledHeader>
            </Box>
          )}
          <Box
            sx={{
              backgroundColor: "#fff",
              width: "100%",
              display: "flex",
              alignItems: "center",
              padding: {
                mobile: "12px 5px",
                xs: "16px 8px",
                sm: "24px 16px",
                xl: "28px 70px",
              },
              border: "2px solid #428E33",
              borderRadius: "10px",
            }}
          >
            <Stack
              direction="row"
              sx={{
                width: "100%",
                gap: "5px",
              }}
              justifyContent={tabHeaders ? "space-around" : "space-between"}
              divider={
                <Divider
                  sx={{
                    borderWidth: "1px",
                    borderColor: "primary.main",
                    opacity: ".35",
                  }}
                />
              }
            >
              <Typography
                variant="h4"
                onClick={() => {
                  setValue(0);
                  setTab(0);
                }}
                sx={{
                  ...headerStyles,
                  opacity: value === 0 ? "1" : ".35",
                }}
              >
                {tabHeaders
                  ? tabHeaders.first
                  : t("realEstateInvestmentFund.generalInvestmentFunds")}
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  ...headerStyles,
                  opacity: value === 1 ? "1" : ".35",
                }}
                onClick={() => {
                  setValue(1);
                  setTab(1);
                }}
              >
                {tabHeaders
                  ? tabHeaders.second
                  : t("realEstateInvestmentFund.closedInvestmentFunds")}
              </Typography>
              <Typography
                variant="h4"
                onClick={() => {
                  setValue(2);
                  setTab(2);
                }}
                sx={{
                  ...headerStyles,
                  opacity: value === 2 ? "1" : ".35",
                }}
              >
                {tabHeaders
                  ? tabHeaders.third
                  : t("realEstateInvestmentFund.indirectInvestmentFunds")}
              </Typography>
            </Stack>
          </Box>
        </Box>
      </SecondaryContainer>
    </LinearGradientContainer>
  );
};

export default RealEstateTabsHeader;
