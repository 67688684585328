import { Stack, Typography } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import React from "react";
import { useTranslation } from "react-i18next";
import AnimateStaggered from "../Animations/AnimateStaggered";
import MainButton from "../Buttons/MainButton";
import MediaCard from "./MediaCard";
import { useNavigate } from "react-router-dom";

type Props = {
  data: any;
};

const MediaWithTabs = ({ data }: Props) => {
  const { t } = useTranslation();
  const [alignment, setAlignment] = React.useState("news");

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setAlignment(newAlignment);
  };

  const newsData = data && data.length > 0 ? data[0]?.news_details : null;
  const albumData =
    data && data.length > 0 ? data[1]?.page_details?.ALBUM : null;

  const navigate = useNavigate();

  return (
    <Stack
      alignItems="center"
      sx={{
        display: { mobile: "flex", sm: "none" },
      }}
    >
      <ToggleButtonGroup
        color="primary"
        value={alignment}
        exclusive
        onChange={handleChange}
        aria-label="Platform"
        sx={{
          width: "100%",
          marginBottom: "1.5rem",
        }}
      >
        <ToggleButton
          disabled={alignment === "news"}
          sx={{
            width: "100%",
            borderRadius: "10px",
            fontSize: { mobile: "1rem", xs: "1.5rem" },
            fontWeight: "700",
            transition: "all 0.3s ease-in-out",
            "&.MuiToggleButton-root": {
              "&:hover": {
                backgroundColor: "#428e331f",
              },
              "&.Mui-selected": {
                backgroundColor: "#428e33",
                color: "white",
              },
            },
          }}
          value="news"
        >
          {t("homePage.mediaCenter.news")}
        </ToggleButton>
        <ToggleButton
          disabled={alignment === "photos"}
          sx={{
            width: "100%",
            borderRadius: "10px",
            fontSize: { mobile: "1rem", xs: "1.5rem" },
            fontWeight: "700",
            transition: "all 0.3s ease-in-out",

            "&.MuiToggleButton-root": {
              "&:hover": {
                backgroundColor: "#428e331f",
              },
              "&.Mui-selected": {
                backgroundColor: "#428e33",
                color: "white",
              },
            },
          }}
          value="photos"
        >
          {t("homePage.mediaCenter.photos")}
        </ToggleButton>
      </ToggleButtonGroup>

      {alignment === "news" && (
        // <Collapse in={alignment === "news"}>
        <Stack
          spacing={2}
          data-aos="fade-zoom-in"
          sx={{
            width: "100%",
            padding: {
              mobile: "0 1rem",
              xs: "0 4rem",
            },
          }}
        >
          <AnimateStaggered index={0}>
            <MediaCard
              type="news"
              id={newsData[0]?.id}
              title={newsData[0]?.title}
              image={newsData[0]?.file}
            />
          </AnimateStaggered>
          <AnimateStaggered index={1}>
            <MediaCard
              type="news"
              id={newsData[1]?.id}
              title={newsData[1]?.title}
              image={newsData[1]?.file}
            />
          </AnimateStaggered>
        </Stack>
      )}

      {alignment === "photos" && albumData?.length > 0 && (
        <Stack
          spacing={2}
          data-aos="fade-zoom-in"
          sx={{
            width: "100%",
            padding: {
              mobile: "0 1rem",
              xs: "0 4rem",
            },
          }}
        >
          {albumData[0] && (
            <AnimateStaggered index={0}>
              <MediaCard
                type="photo"
                id={albumData[0]?.id}
                title={albumData[0]?.title}
                image={albumData[0]?.file}
              />
            </AnimateStaggered>
          )}
          {albumData[1] && (
            <AnimateStaggered index={1}>
              <MediaCard
                type="photo"
                id={albumData[1]?.id}
                title={albumData[1]?.title}
                image={albumData[1]?.file}
              />
            </AnimateStaggered>
          )}
        </Stack>
      )}

      <MainButton
        variant="contained"
        sx={{
          marginTop: "1.5rem",
        }}
        onClick={() => {
          navigate(`/media-center/${alignment}`);
        }}
      >
        {t("homePage.mediaCenter.more")}
      </MainButton>
    </Stack>
  );
};

export default MediaWithTabs;
