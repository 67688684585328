import { styled } from "@mui/material";
import React, { useState } from "react";
import NavItemSubMenu from "./NavItemSubMenu";
import { subMenuType } from "../../../helpers/types";
import { motion } from "framer-motion";
import AnimateTap from "../../Animations/AnimateTap";
import { Link } from "react-router-dom";

type Props = {
  subMenu: subMenuType;
};

const StyledMenu = styled("div")({
  backgroundColor: "#428E33",
  minWidth: "185px",
  display: "flex",
  flexDirection: "column",
  gap: "14px",
  width: "100%",
  fontWeight: "400",
  padding: "14px 16px",
  borderRadius: "12px",
  position: "relative",
  "&::after": {
    content: "''",
    position: "absolute",
    top: "14px",
    left: "calc(100% - 16px)",
    width: "1px",
    height: "calc(100% - 28px)",
    backgroundColor: "#ffffff5c",
  },
});

const StyledLink = styled("div")({
  fontSize: "1rem",
  fontWeight: "400",
  cursor: "pointer",
  "&::before": {
    display: "block",
    content: "attr(title)",
    fontWeight: 600,
    height: 0,
    overflow: "hidden",
    visibility: "hidden",
  },
  "&::after": {
    content: "''",
    position: "absolute",
    top: "0",
    right: "-1px",
    backgroundColor: "#ffffff5c",
    width: "1px",
    height: "100%",
    opacity: 0,
  },

  "&:hover": {
    fontWeight: 600,
    "&::after": {
      opacity: 1,
    },
  },
});

const StyledSubLink = styled("div")({
  position: "relative",
  textAlign: "left",
});

const NavItemMenu = ({ subMenu }: Props) => {
  const [showSubMenu, setShowSubMenu] = useState<number | null>(null);
  const handleShowSubMenu = (n: number) => {
    setShowSubMenu(n);
  };
  const handleCloseSubMenu = () => {
    setShowSubMenu(null);
  };
  return (
    <StyledMenu>
      {subMenu &&
        subMenu?.length > 0 &&
        subMenu.map((item, index) => {
          return (
            <StyledSubLink
              key={item.name + index}
              onMouseEnter={() => {
                handleShowSubMenu(index);
              }}
              onClick={handleCloseSubMenu}
            >
              <StyledLink title={item.name}>
                {item.link ? (
                  <Link to={item.link}>
                    {" "}
                    <AnimateTap>{item.name}</AnimateTap>
                  </Link>
                ) : (
                  <AnimateTap>{item.name}</AnimateTap>
                )}
              </StyledLink>
              {/* showSubMenu === index */}
              {item.subMenu && showSubMenu === index && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <NavItemSubMenu
                    smallMenuItems={item.subMenu}
                    handleCloseSubMenu={handleCloseSubMenu}
                  />
                </motion.div>
              )}
            </StyledSubLink>
          );
        })}
    </StyledMenu>
  );
};

export default NavItemMenu;
