// import {
//   Accordion,
//   AccordionDetails,
//   AccordionSummary,
//   Divider,
//   ListItem,
//   ListItemButton,
//   ListItemText,
//   Stack,
//   TextField,
//   Typography,
// } from "@mui/material";
// import Box from "@mui/material/Box";
// import CssBaseline from "@mui/material/CssBaseline";
// import { MdOutlineExpandMore } from "react-icons/md";
// import { Link, useNavigate } from "react-router-dom";
// import logo from "../../../assets/images/footer-logo.svg";
// import { useTranslation } from "react-i18next";
// import CustomSearchField from "../../CustomInputs/CustomSeachField";
// import SocialMedia from "../SocialMedia/SocialMedia";

// interface Props {
//   window?: () => Window;
//   navItems?: any;
// }

// export default function DrawerAppBar({ navItems }: Props) {
//   const navigate = useNavigate();
//   const { t } = useTranslation();

//   return (
//     <Box
//       sx={{
//         backgroundColor: "#fff",
//         height: "100%",
//         width: "100%",
//       }}
//     >
//       <CssBaseline />
//       <Stack
//         justifyContent="space-between"
//         sx={{
//           height: "100%",
//         }}
//         divider={
//           <Divider
//             sx={{
//               borderWidth: "1px",
//               borderColor: "rgba(0, 0, 0, 0.08)",
//             }}
//           />
//         }
//       >
//         <Stack
//           sx={{
//             marginBottom: "auto",
//           }}
//         >
//           <Box
//             sx={{
//               width: "100%",
//               display: "flex",
//               justifyContent: "center",
//               flexDirection: "column",
//               position: "relative",
//               padding: "0 1rem",
//               marginBottom: "2rem",
//             }}
//           >
//             <Box
//               sx={{
//                 width: "100%",
//                 display: "flex",

//                 height: "60px",
//                 cursor: "pointer",
//                 "& img": {
//                   width: "100%",
//                   height: "100%",
//                   objectFit: "contain",
//                 },
//               }}
//               onClick={() => {
//                 navigate("/");
//               }}
//             >
//               <img src={logo} alt="logo" />
//             </Box>
//             <CustomSearchField label={t("layout.header.searchButton")} />
//             <Divider
//               sx={{
//                 position: "absolute",
//                 bottom: "-1.5rem",
//                 left: "50%",
//                 transform: "translateX(-50%)",
//                 borderWidth: "1px",
//                 borderColor: "rgba(0, 0, 0, 0.08)",
//                 width: "100%",
//               }}
//             />
//           </Box>
//           {navItems.map((item: any, index: number) =>
//             item.subMenu.length > 0 ? (
//               <Accordion
//                 // expanded={expanded === index}
//                 key={index}
//                 sx={{
//                   margin: "0 !important",
//                   backgroundColor: "transparent",
//                   ".Mui-expanded": {
//                     // backgroundColor: "#FBFBFB",
//                     color: "#767676",
//                   },
//                   boxShadow: "none",
//                   "&:before": {
//                     display: "none",
//                   },
//                 }}
//               >
//                 <AccordionSummary
//                   sx={{
//                     transition: "all 0.1s ease-in-out",
//                     svg: {
//                       fontSize: "1.5rem",
//                     },
//                     "& .MuiAccordionSummary-content": {
//                       margin: "0",
//                     },
//                   }}
//                   expandIcon={<MdOutlineExpandMore />}
//                   aria-controls={`${item.name}${index}`}
//                   id={index.toString()}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: "1rem",
//                       fontWeight: 700,
//                       color: "#767676",
//                       cursor: "pointer",
//                     }}
//                     onClick={(e) => {
//                       e.stopPropagation();
//                       navigate(item.link);
//                     }}
//                   >
//                     {item.name}
//                   </Typography>
//                 </AccordionSummary>
//                 <AccordionDetails
//                   sx={{
//                     backgroundColor: "transparent",
//                   }}
//                 >
//                   <Stack
//                     sx={{
//                       gap: "12px",
//                     }}
//                   >
//                     {item.subMenu.map((subLink: any, index: number) => {
//                       if (subLink?.smallMenuItems?.length > 0) {
//                         return (
//                           <Accordion
//                             // expanded={expanded === index}
//                             key={index}
//                             sx={{
//                               margin: "0 !important",
//                               backgroundColor: "transparent",
//                               ".Mui-expanded": {
//                                 // backgroundColor: "#FBFBFB",
//                                 color: "#767676",
//                               },
//                               boxShadow: "none",
//                               "&:before": {
//                                 display: "none",
//                               },
//                             }}
//                           >
//                             <AccordionSummary
//                               sx={{
//                                 transition: "all 0.1s ease-in-out",
//                                 padding: "0",

//                                 svg: {
//                                   fontSize: "1.5rem",
//                                 },
//                                 "& .MuiAccordionSummary-content": {
//                                   margin: "0",
//                                 },
//                               }}
//                               expandIcon={<MdOutlineExpandMore />}
//                               aria-controls={`${subLink.name}${index}`}
//                               id={index.toString()}
//                             >
//                               <Typography
//                                 sx={{
//                                   fontSize: "1rem",
//                                   // fontWeight: 600,
//                                   color: "#767676",
//                                   cursor: "pointer",
//                                 }}
//                                 onClick={(e) => {
//                                   e.stopPropagation();
//                                   navigate(subLink.link);
//                                 }}
//                               >
//                                 {subLink.name}
//                               </Typography>
//                             </AccordionSummary>
//                             <AccordionDetails
//                               sx={{
//                                 backgroundColor: "transparent",
//                               }}
//                             >
//                               <Stack
//                                 sx={{
//                                   "& a": {
//                                     fontSize: "1rem",
//                                   },
//                                 }}
//                               >
//                                 {subLink.smallMenuItems.map(
//                                   (smallLink: any, index: number) => (
//                                     <Link
//                                       style={{
//                                         fontSize: "1rem",
//                                       }}
//                                       key={index}
//                                       to={smallLink.link}
//                                     >
//                                       <Stack direction="row" spacing={1}>
//                                         <span
//                                           style={{
//                                             fontSize: "1rem",
//                                           }}
//                                         >
//                                           {smallLink.name}
//                                         </span>
//                                       </Stack>
//                                     </Link>
//                                   )
//                                 )}
//                               </Stack>
//                             </AccordionDetails>
//                           </Accordion>
//                         );
//                       } else {
//                         return (
//                           <Link key={index} to={subLink.link}>
//                             <Stack direction="row" spacing={1}>
//                               <span>{subLink.name}</span>
//                             </Stack>
//                           </Link>
//                         );
//                       }
//                     })}
//                   </Stack>
//                 </AccordionDetails>
//               </Accordion>
//             ) : (
//               <Link key={index} to={item.link} style={{}}>
//                 <ListItem disablePadding>
//                   <ListItemButton sx={{ textAlign: "center" }}>
//                     <ListItemText
//                       primary={item.name}
//                       sx={{
//                         textAlign: "left",
//                         "& .MuiTypography-root": {
//                           fontSize: "1rem",
//                           color: "#767676",
//                           fontWeight: "700",
//                         },
//                       }}
//                     />
//                   </ListItemButton>
//                 </ListItem>
//               </Link>
//             )
//           )}
//         </Stack>
//         <Box
//           sx={{
//             width: "100%",
//             display: "flex",
//             justifyContent: "center",
//             padding: "1.5rem 0 .75rem",
//             justifySelf: "flex-end",
//             alignItems: "center",
//           }}
//         >
//           <SocialMedia color="#428E33" size="small" />
//         </Box>
//       </Stack>
//     </Box>
//   );
// }

import { Box, Divider, Stack } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/images/footer-logo.svg";
import CustomSearchField from "../../CustomInputs/CustomSeachField";
import SocialMedia from "../SocialMedia/SocialMedia";
import DrawerNavItems from "./DrawerNavItems";

interface Props {
  navItems: any[];
}

const DrawerAppBar: React.FC<Props> = ({ navItems }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleLogoClick = () => {
    navigate("/");
  };

  return (
    <Stack
      justifyContent="space-between"
      sx={{
        height: "100%",
        padding: "0 1rem",
      }}
      divider={
        <Divider
          sx={{
            borderWidth: "1px",
            borderColor: "rgba(0, 0, 0, 0.08)",
            width: "calc(100% + 2rem)",
            marginLeft: "-1rem",
          }}
        />
      }
    >
      {/* top section */}
      <Stack
        sx={{
          marginBottom: "auto",
          gap: "20px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            position: "relative",
            marginBottom: "2rem",
            gap: "25px",
            padding: "10px 0",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",

              height: "60px",
              cursor: "pointer",
              "& img": {
                width: "100%",
                height: "100%",
                objectFit: "contain",
              },
            }}
            onClick={handleLogoClick}
          >
            <img src={logo} alt="logo" />
          </Box>
          <CustomSearchField
            label={t("layout.header.searchButton")}
            color="white"
          />
          <Divider
            sx={{
              position: "absolute",
              bottom: "-1.5rem",
              left: "50%",
              transform: "translateX(-50%)",
              borderWidth: "1px",
              borderColor: "rgba(0, 0, 0, 0.08)",
              width: "calc(100% + 2rem)",
              marginLeft: "-1rem",
            }}
          />
        </Box>

        <DrawerNavItems navItems={navItems} />
      </Stack>

      {/* Bottom section */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          padding: "1.5rem 0 .75rem",
          justifySelf: "flex-end",
          alignItems: "center",
        }}
      >
        <SocialMedia color="#428E33" size="small" />
      </Box>
    </Stack>
  );
};

export default DrawerAppBar;
