import { Box } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { useLayoutContext } from "../../context/layoutContext";
import AlertMessage from "../AlertMessage/AlertMessage";
import AttentionBox from "../AttentionBox/AttentionBox";
import Footer from "../LayoutComponents/Footer/Footer";
import Header from "../LayoutComponents/Header/Header";
import WhatsAppBtn from "../WhatsAppBtn/WahtsAppBtn";

type Props = {
  children: React.ReactNode;
};

const Layout = (props: Props) => {
  const { data, loading } = useLayoutContext();
  const location = useLocation();
  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            width: "100vw",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ClipLoader color="#428E33" size={75} />
        </Box>
      ) : (
        <>
          <Box
            sx={{
              overflowX: {
                mobile: "hidden",
                md: "auto",
              },
            }}
          >
            <Header />
            <Box
              sx={{
                marginTop: {
                  mobile: location.pathname === "/" ? "-105px" : "0",
                  sm: location.pathname === "/" ? "-140px" : "0",
                },
              }}
            >
              {props.children}
            </Box>
            {/* contact us */}
            {/* {data && data[0] && <ContactUs contactInfo={data[0]} />} */}
            {/* footer */}
            <Footer />
          </Box>
          <AlertMessage />
        </>
      )}
      <WhatsAppBtn />
      <AttentionBox />
    </>
  );
};

export default Layout;
