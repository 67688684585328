import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import checkVideoOrImage from "../../../helpers/checkVideoOrImage";
import i18n from "../../../i18n";
import SecondaryContainer from "../../Containers/SecondaryContainer";
import "./MainSlider.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";

type Props = {
  sliderImage: string;
  title: string;
  description: string;
  sliderLength: number;
};

const MainSliderCard = ({
  sliderImage,
  title,
  description,
  sliderLength,
}: Props) => {
  const firstTwoWords = title.split(" ").slice(0, 2).join(" ");
  const restOfWords = title.split(" ").slice(2).join(" ");
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        height: "100vh",
        minHeight: { mobile: "500px", sm: "600px" },
        position: "relative",
        "&::after": {
          content: '""',
          position: "absolute",
          zIndex: 1,
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          height: "100%",
          width: "100%",
          backgroundImage:
            "linear-gradient(180deg, rgba(0,0,0,0.5970763305322129) 0%, rgba(0,0,0,0.4542191876750701) 33%, rgba(255,255,255,0.15449929971988796) 100%)",
        },
        "& img": {
          height: "100%",
          width: "100%",
          objectFit: "cover",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          zIndex: -1,
        },
        "& video": {
          height: "100%",
          width: "100%",
          objectFit: "cover",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          zIndex: -1,
          source: {
            height: "100%",
            width: "100%",
          },
        },
      }}
    >
      <SecondaryContainer>
        <Stack
          alignItems={
            sliderLength === 1
              ? "flex-start"
              : i18n.language === "ar"
              ? "flex-end"
              : "flex-start"
          }
          justifyContent="flex-end"
          sx={{
            paddingBottom: { mobile: "30vh", sm: "25vh", md: "21vh" },
            height: "100%",
            position: "relative",
            zIndex: 2,
            "@media (max-height: 770px)": {
              paddingBottom: { mobile: "20vh", sm: "15vh", md: "10vh" },
            },
            "@media (max-height:820px)": {
              paddingBottom: {
                mobile: "18vh",
                xs: "17vh",
                sm: "16vh",
                md: "17vh",
                xl: "21vh",
              },
            },
          }}
        >
          <Typography
            variant="h1"
            textAlign="left"
            sx={{
              color: "#fff ",
              fontSize: {
                mobile: "36px",
                xs: "42px",
                sm: "65px",
                md: "75px",
              },
              fontWeight: 700,
              "@media (max-height: 770px)": {
                fontSize: {
                  mobile: "30px",
                  xs: "36px",
                  sm: "50px",
                },
              },
              "@media (max-height: 870px)": {
                fontSize: {
                  mobile: "36px",
                  xs: "42px",
                  sm: "46px",
                  md: "50px",
                  xl: "68px",
                },
              },
              "@media screen and (max-height: 600px)": {
                fontSize: {
                  mobile: "36px",
                  xs: "42px",
                  md: "50px",
                  xl: "56px",
                },
              },
              "@media screen and (max-height: 550px)": {
                fontSize: {
                  mobile: "30px",
                  xs: "36px",
                  sm: "50px",
                },
              },
              "@media (max-height: 500px)": {
                fontSize: {
                  lg: "42px",
                },
              },
            }}
          >
            {firstTwoWords}{" "}
          </Typography>
          <Typography
            variant="h2"
            textAlign="left"
            sx={{
              color: "#6EDB58",
              fontSize: {
                mobile: "36px",
                xs: "42px",
                sm: "65px",
                md: "75px",
              },
              fontWeight: 600,
              "@media (max-height: 770px)": {
                fontSize: {
                  mobile: "30px",
                  xs: "36px",
                  sm: "48px",
                },
              },
              "@media (max-height: 870px)": {
                fontSize: {
                  mobile: "36px",
                  xs: "42px",
                  sm: "46px",
                  md: "50px",
                  xl: "64px",
                },
              },
              "@media screen and (max-height: 600px)": {
                fontSize: {
                  mobile: "36px",
                  xs: "42px",
                  sm: "46px",

                  xl: "56px",
                },
              },
              "@media screen and (max-height: 550px)": {
                fontSize: {
                  mobile: "30px",
                  xs: "36px",
                  sm: "48px",
                },
              },
              "@media (max-height: 500px)": {
                fontSize: {
                  lg: "42px",
                },
              },
            }}
          >
            {restOfWords}
          </Typography>
          <Typography
            variant="h6"
            textAlign="left"
            sx={{
              fontWeight: 400,
              color: "#fff",
              fontSize: { mobile: "16px", xs: "1.4rem", sm: "1.625rem" },
              maxWidth: {
                mobile: "340px",
                xs: "600px",
                sm: "700px",
                md: "750px",
                lg: "850px",
              },
              margin: {
                mobile: ".9rem 0",
                xs: "1.75rem 0",
                md: "3rem 0",
              },
              "@media (max-height: 870px)": {
                fontSize: { mobile: "16px", xs: "1.6rem", sm: "1.625rem" },
                margin: {
                  mobile: ".8rem 0",
                  xs: "1rem 0",
                  sm: "1.5rem 0",
                  xl: "2rem 0",
                },
              },
              "@media screen and (max-height: 670px)": {
                margin: {
                  mobile: ".8rem 0",
                  xs: "1rem 0",
                  sm: ".5rem 0",
                  xl: "1rem 0",
                },
              },
              "@media screen and (max-height: 550px)": {
                margin: {
                  mobile: ".8rem 0",
                  xs: ".8rem 0",
                  sm: "1rem 0",
                  md: "1.5rem 0",
                },
              },
              "@media (max-height: 500px)": {
                margin: {
                  lg: ".5rem 0",
                },
              },
            }}
          >
            {description}
          </Typography>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent:
                sliderLength === 1
                  ? "flex-start"
                  : i18n.language === "ar"
                  ? "flex-end"
                  : "flex-start",
            }}
          >
            <Button
              aria-label="slider button"
              disableElevation
              variant="contained"
              color="primary"
              sx={{
                height: {
                  mobile: "40px",
                  xs: "50px",
                  sm: "55px",
                  lg: "65px",
                  xl: "70px",
                },
                width: {
                  mobile: "120px",
                  xs: "150px",
                  sm: "175px",
                  lg: "200px",
                  xl: "215px",
                },
                fontSize: {
                  mobile: "14px",

                  sm: "1rem",
                  lg: "1.5rem",
                },
                "&:hover": {
                  backgroundColor: "#48b931",
                },
                "@media (max-height: 500px)": {
                  height: {
                    mobile: "40px",
                    xs: "50px",
                  },
                  width: {
                    mobile: "120px",
                    xs: "150px",
                  },
                  fontSize: {
                    mobile: "14px",
                    xs: "1rem",
                    sm: "1.25rem",
                  },
                },
              }}
              onClick={() => {
                navigate("/contact-us");
              }}
            >
              {t("homePage.sliderButton")}{" "}
            </Button>
          </Box>
        </Stack>
      </SecondaryContainer>
      {checkVideoOrImage(sliderImage) === 2 && (
        <LazyLoadImage
          alt="slider-image"
          src={sliderImage}
          height={"100%"}
          width={"100%"} // use normal <img> attributes as props
        />
      )}
      {checkVideoOrImage(sliderImage) === 1 && (
        <video
          id={title}
          muted
          loop
          autoPlay
          // onError={(e) => {
          //   if (e.currentTarget.error) {
          //     // Do something, such as pausing the video or showing a message to the user
          //   }
          // }}
        >
          <source
            src={sliderImage}
            type={`video/${sliderImage.split(".").pop()}`}
          />
        </video>
      )}
    </Box>
  );
};

export default React.memo(MainSliderCard);
