import { Box, Button, Collapse, Typography } from "@mui/material";
import React, { useState } from "react";
import { SmallTextTitle } from "../../TextInfo/SmallTextInfo";
import { FiDownload } from "react-icons/fi";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
type Props = {
  report: {
    id: number;
    title: string;
    report_files: {
      id: number;
      title: string;
      file: string;
    }[];
  };
};

const MultiReport = ({ report }: Props) => {
  const [collapse, setCollapse] = useState<boolean>(false);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: { mobile: "8px", sm: "16px", md: "20px", lg: "26px" },
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "12px",
              height: "12px",
              backgroundColor: "#707070",
              borderRadius: "50%",
            }}
          />
          <Typography
            sx={{
              fontSize: { mobile: "16px", sm: "20px", md: "22px", xl: "24px" },
              fontWeight: "bold",
              color: "#707070",
            }}
          >
            {report.title}{" "}
          </Typography>
        </Box>
        <Button
          variant="contained"
          sx={{
            borderRadius: "4px",
            width: { mobile: "32px", md: "41px" },
            height: { mobile: "30px", md: "38px" },
            minWidth: { mobile: "32px", md: "41px" },
            padding: "0",
            "& svg": {
              fontSize: { mobile: "20px", md: "30px" },
            },
          }}
          onClick={() => setCollapse(!collapse)}
        >
          {collapse ? <AiOutlineMinus /> : <AiOutlinePlus />}
        </Button>
      </Box>
      <Collapse in={collapse} unmountOnExit={true}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          {report.report_files.map(
            (
              file: { id: number; title: string; file: string },
              index: number
            ) => {
              return <SmallModalReport key={index} reportFile={file} />;
            }
          )}
        </Box>
      </Collapse>
    </>
  );
};

export default MultiReport;

interface smallModalProps {
  reportFile: {
    id: number;
    title: string;
    file: string;
  };
}
const SmallModalReport = ({ reportFile }: smallModalProps) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      backgroundColor: "#F2F2F2",
      borderRadius: "4px",
      padding: {
        mobile: "12px 10px",

        lg: "12px 32px",
      },
    }}
  >
    <Typography
      sx={{
        fontSize: { mobile: "14px", sm: "16px", lg: "18px" },
        fontWeight: "regular",
        color: "#428E33",
      }}
    >
      {reportFile.title}{" "}
    </Typography>
    <Button
      variant="contained"
      sx={{
        borderRadius: "4px",
        width: "24px",
        height: "22px",
        minWidth: "20px",
        padding: "0",
        "& svg": {
          fontSize: "16px",
        },
      }}
      onClick={() => {
        if (reportFile?.file) {
          window.open(reportFile.file, "_blank");
        }
      }}
    >
      <FiDownload />
    </Button>
  </Box>
);
