import { Box } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Banner from "../../components/Banner/Banner";
import ContainerWithPadding from "../../components/Containers/ContainerWithPadding";
import SecondaryContainer from "../../components/Containers/SecondaryContainer";
import SmallerContainer from "../../components/Containers/SmallerContainer";
import MainLoader from "../../components/Loaders/MainLoader";
import TextInfo from "../../components/TextInfo/TextInfo";
import TextList from "../../components/TextList/TextList";
import useGetSingleApi from "../../hooks/useGetSingleApi";

type Props = {};

const YourRights = (props: Props) => {
  const { t, i18n } = useTranslation();
  const apiUrl = useMemo(() => "/your_rights", []);
  const { data, loading } = useGetSingleApi(apiUrl);
  if (loading) {
    return <MainLoader />;
  }
  return (
    <>
      {data && (
        <Box>
          <Banner
            image={data?.image}
            title={data?.title}
            text={data?.description}
          />

          <ContainerWithPadding
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "50px",
              "&::before": {
                transform:
                  i18n.dir() === "rtl"
                    ? "translate(-50%,-50%)"
                    : "translate(-50%, -50%) rotate(180deg)",
              },
            }}
          >
            <SecondaryContainer>
              <TextInfo
                showFullText
                headerColor="#428E33"
                link={t("homePage.aboutUs.more")}
                header={data?.title}
                // text={data?.description}
              />
            </SecondaryContainer>
            <SmallerContainer>
              {data?.list?.length > 0 ? <TextList items={data?.list} /> : null}{" "}
            </SmallerContainer>
          </ContainerWithPadding>
        </Box>
      )}
    </>
  );
};

export default YourRights;
