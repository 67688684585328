import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import cardBg from "../../assets/images/asset-bg.png";
import assetsDivider from "../../assets/images/assets-divider.svg";
import SecondaryContainer from "../Containers/SecondaryContainer";

type Props = {
  title: string;
  description: string;
};

const AssetManagementCard = ({ title, description }: Props) => {
  const { i18n } = useTranslation();
  return (
    <Box
      data-aos={i18n.dir() === "rtl" ? "fade-left" : "fade-right"}
      data-aos-duration="1000"
      sx={{
        width: "100%",
        position: "relative",
        "&::before": {
          content: '""',
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "100%",
          height: "100%",
          zIndex: -1,
          transform:
            i18n.dir() === "rtl"
              ? "translate(-50%,-50%)"
              : "translate(-50%, -50%) rotate(180deg)",
          background: `url(${cardBg})`,
          backgroundRepeat: "repeat",
          backgroundSize: {
            mobile: "cover",
            xs: "contain",
            sm: "cover",
            xl: "cover",
          },
          backgroundPosition: "center",
        },
      }}
    >
      <SecondaryContainer>
        <Box
          sx={{
            display: "grid",
            padding: { mobile: "18px 0", md: "30px 0", xl: "80px 0" },
            gridTemplateColumns: {
              mobile: "1fr",
              sm: "120px 1fr",
              xl: "150px 1fr",
            },
            gap: { mobile: "5px", xs: "15px", md: "40px", xl: "80px" },
            height: "100%",
          }}
        >
          <Typography
            sx={{
              width: {
                mobile: "145px",
                xs: "170px",
                sm: "100px",
                md: "120px",
                xl: "150px",
              },
              color: "primary.main",
              fontSize: {
                mobile: "18px",
                xs: "20px",
                sm: "22px",
                md: "24px",
                lg: "26px",
                xl: "30px",
              },
              fontWeight: "700",
              position: "relative",
            }}
          >
            {title}
            <Box
              component={"img"}
              src={assetsDivider}
              alt="Assets-Divider"
              sx={{
                position: "absolute",
                top: "5px",
                right: { mobile: "-30px", xl: "-37px" },
                width: {
                  mobile: "16px",
                  xs: "18px",
                  md: "20px",
                  lg: "28px",
                  xl: "auto",
                },
                transform: i18n.dir() === "ltr" ? "rotate(180deg)" : "none",
              }}
            />
          </Typography>

          <Typography
            sx={{
              fontSize: {
                mobile: "14px",
                xs: "16px",
                sm: "18px",
                md: "20px",
                lg: "24px",
                xl: "28px",
              },
              color: "#505050",
              fontWeight: "600",
              padding: { mobile: "10px 0", xs: "0 18px", xl: 0 },
            }}
          >
            {description}
          </Typography>
        </Box>
      </SecondaryContainer>
    </Box>
  );
};

export default AssetManagementCard;
