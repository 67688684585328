import { Box } from "@mui/material";
import { motion } from "framer-motion";
import { useMemo, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import useSliderItems from "../../../hooks/useGetSingleApi";
import SocialMedia from "../../LayoutComponents/SocialMedia/SocialMedia";
import "./MainSlider.css";
import MainSliderCard from "./MainSliderCard";
import { useTranslation } from "react-i18next";

type Props = {};

const MainSlider = (props: Props) => {
  const { i18n } = useTranslation();
  const settings = {
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    slidesToShow: 1,

    // slidesToScroll: i18n.dir() === "rtl" ? 1 : -1,
  };

  const sliderRef = useRef<Slider>(null);

  // Get slider items from API
  const apiUrl = useMemo(() => {
    return "/slider";
  }, []);
  const { data: sliderItems } = useSliderItems(apiUrl);

  const goNext = () => {
    sliderRef.current?.slickNext();
  };
  const goPrev = () => {
    sliderRef.current?.slickPrev();
  };

  // Track timeout IDs to clear timeouts later
  const timeoutIDs: any[] = [];

  const playNextSlide = (duration: number) => {
    // Clear existing timeouts before setting a new one
    for (let i = 0; i < timeoutIDs.length; i++) {
      clearTimeout(timeoutIDs[i]);
    }

    const timeout = setTimeout(() => {
      if (i18n.dir() === "rtl") {
        goNext();
      } else {
        goPrev();
      }
    }, duration);

    // Store the new timeout ID
    timeoutIDs.push(timeout);
  };

  // Get duration of each slide

  const getDuration = (index: number): number => {
    let duration;
    if (sliderItems) {
      const content = sliderItems[index]?.slider_content;

      // Check if content includes video formats
      if (content && content.includes("mp4" || "webm" || "ogg")) {
        const video = document.getElementById(
          sliderItems[index].title
        ) as HTMLVideoElement | null;
        duration = Number(video?.duration) * 1000;
        if (video) {
          video.currentTime = 0;
          video.play();
        }
        return duration || 6000;
      } else {
        // Default duration for non-video slides
        duration = 4000;
        return duration;
      }
    } else {
      return duration || 4000;
    }
  };

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        position: "relative",
      }}
    >
      {sliderItems ? (
        <Slider
          customPaging={(i) => <div className="slider-dot"></div>}
          ref={sliderRef}
          {...settings}
          speed={1500}
          className="slider-container"
          pauseOnFocus={false}
          pauseOnHover={false}
          onInit={() => {
            playNextSlide(getDuration(0));
          }}
          beforeChange={(current, next) => {
            let videos = document.getElementsByTagName("video");
            for (let i = 0; i < videos.length; i++) {
              videos[i].pause();
            }
          }}
          afterChange={(current) => {
            let videos = document.getElementsByTagName("video");
            for (let i = 0; i < videos.length; i++) {
              videos[i].currentTime = 0;
              videos[i].play();
            }
            playNextSlide(getDuration(current));
          }}
        >
          {sliderItems?.map((item: any) => {
            return (
              <MainSliderCard
                sliderLength={sliderItems.length}
                key={item.title}
                sliderImage={item.slider_content}
                title={item.title}
                description={item.description}
              />
            );
          })}
        </Slider>
      ) : (
        // Placeholder content when sliderItems are not loaded
        <motion.div
          style={{
            height: "100vh",
            width: "100%",
            backgroundColor: "#000",
          }}
          initial={{ opacity: 0.8 }}
          animate={{ opacity: 1 }}
          transition={{
            repeat: Infinity,
            repeatType: "reverse",
            duration: 0.7,
          }}
        ></motion.div>
      )}
      <Box
        sx={{
          position: "absolute",
          bottom: "16px",
          right: {
            mobile: "5%",
            xs: "7.5%",
            xl: "280px",
            lg: "240px",
            md: "200px",
            sm: "140px",
          },
        }}
      >
        <SocialMedia color="white" size="big" />
      </Box>
    </Box>
  );
};

export default MainSlider;
