import { styled } from "@mui/material";
import { Link } from "react-router-dom";
import AnimateTap from "../../Animations/AnimateTap";

type Props = {
  subMenu: {
    name: string;
    link?: string;
  }[];
  handleCloseMenu: () => void;
};

const StyledMenu = styled("div")({
  backgroundColor: "#428E33",
  // minWidth: "191px",
  width: "max-content",
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  fontWeight: "400",
  padding: "14px 16px",
  borderRadius: "4px",
  position: "absolute",
  left: "calc(100% + 20px)",
  top: 0,
  "&::after": {
    position: "absolute",
    content: "''",
    top: "0",
    left: "-25px",
    width: "50px",
    backgroundColor: "transparent",
    zIndex: -1,
    height: "100%",
  },
  "&::before": {
    width: "80px",
    height: "calc(100% + 40px)",
    backgroundColor: "transparent",
    position: "absolute",
    left: "-20px",
    top: "-20px",
    content: "''",
    zIndex: -1,
  },
});

const StyledLink = styled("div")({
  fontSize: ".875rem",
  fontWeight: "400",
  cursor: "pointer",
  color: "white",
  textWrap: "nowrap",
  "&::before": {
    display: "block",
    content: "attr(title)",
    fontWeight: 600,
    height: 0,
    overflow: "hidden",
    visibility: "hidden",
  },

  // "&:hover": {
  //   fontWeight: 600,
  // },
});

const StyledSubLink = styled("div")({
  position: "relative",
  textAlign: "left",
});
const StyledLine = styled("div")({
  position: "absolute",
  width: "48px",
  height: "1px",
  backgroundColor: "#428E33",
  left: "100%",
  zIndex: 1,
  top: "50%",
});
const StyledDot = styled("div")({
  position: "absolute",
  width: "8px",
  height: "8px",
  backgroundColor: "#428E33",
  left: "calc(100% - 4px)",
  zIndex: 1,
  top: "calc(50% - 4px)",
  borderRadius: "50%",
});

const LastNavItem = ({ subMenu, handleCloseMenu }: Props) => {
  return (
    <>
      <StyledLine />
      <StyledDot />
      <StyledMenu
        onMouseLeave={() => {
          handleCloseMenu();
        }}
      >
        {subMenu &&
          subMenu?.length > 0 &&
          subMenu.map((item, index) => {
            return (
              <StyledSubLink key={item.name + index} onClick={handleCloseMenu}>
                <StyledLink title={item.name}>
                  {item.link ? (
                    <Link to={item.link}>
                      {" "}
                      <AnimateTap>{item.name}</AnimateTap>
                    </Link>
                  ) : (
                    <AnimateTap>{item.name}</AnimateTap>
                  )}
                </StyledLink>
              </StyledSubLink>
            );
          })}
      </StyledMenu>
    </>
  );
};

export default LastNavItem;
