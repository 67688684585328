import React from "react";
import SecondaryContainer from "../Containers/SecondaryContainer";
import { Box } from "@mui/material";
import SmallTextInfo from "../TextInfo/SmallTextInfo";
import SingleIndirectFund from "./SingleIndirectFund";
import IndirectFundMenu from "./IndirectFundMenu";

type Props = {
  fundData: {
    id: number;
    title: string;
    description: string;
    indirect_fund_details: {
      id: number;
      title: string;
      indirect_fund_details: {
        title: string;
        description: string;
      }[];
    }[];
  }[];
};

const IndirectInvestmentFundTab = ({ fundData }: Props) => {
  const [selectedItem, setSelectedItem] = React.useState(
    fundData[0]?.indirect_fund_details[0]?.id
  );
  return (
    <SecondaryContainer>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "42px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { mobile: "column", xs: "row" },
            gap: {
              mobile: "24px",
              xs: "50px",
              sm: "70px",
              md: "100px",
              lg: "120px",
              xl: "150px",
            },
          }}
        >
          <Box
            sx={{
              flex: 1,
              width: "100%",
            }}
          >
            <SmallTextInfo
              titleColor="#428E33"
              title={fundData[0]?.title}
              text={fundData[0]?.description}
              startIcon={
                <Box
                  sx={{
                    width: "20px",
                    height: "20px",
                    borderRadius: "50%",
                    backgroundColor: "#73BB65",
                  }}
                />
              }
            />
          </Box>
          <Box
            sx={{
              flex: 1.35,
            }}
          >
            {fundData[0].indirect_fund_details && (
              <IndirectFundMenu
                items={fundData[0].indirect_fund_details}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
              />
            )}
          </Box>
        </Box>
        {fundData[0].indirect_fund_details.find(
          (item) => item.id === selectedItem
        ) && (
          <SingleIndirectFund
            data={fundData[0].indirect_fund_details.find(
              (item) => item.id === selectedItem
            )}
          />
        )}
      </Box>
    </SecondaryContainer>
  );
};

export default IndirectInvestmentFundTab;
