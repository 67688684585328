import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

type Props = {
  startIcon?: React.ReactNode;
  title?: any;
  text?: string;
  titleColor?: string;
  type?: string;
  gap?: {
    mobile: string;
    md: string;
  };
};
export const SmallTextTitle = ({
  startIcon,
  title,
  titleColor,
  gap,
  type,
}: Props) => {
  const { i18n } = useTranslation();
  const { query } = useParams<{ query: string }>();
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "20px 1fr",
        gap: gap ? gap : { mobile: "8px", xs: "20px", xl: "30px" },
        alignItems: "flex-start",
        "& img": {
          transform: i18n.dir() === "ltr" ? "rotate(180deg)" : "rotate(0deg)",
        },
        "& div": {
          marginTop: { mobile: "8px", md: "12px", xl: "14px" },
        },
      }}
    >
      {startIcon && startIcon}
      <Typography
        sx={{
          fontSize: {
            mobile: type === "small" ? "16px" : "20px",
            md: type === "small" ? "18px" : "24px",
            xl: type === "small" ? "20px" : "30px",
          },
          fontWeight: "bold",
          color: titleColor ? titleColor : "#707070",
        }}
      >
        {query
          ? title.split(query).map((part: any, index: number, array: any) => (
              <React.Fragment key={index}>
                {part}
                {index !== array.length - 1 && (
                  <span style={{ fontWeight: "bold", background: "yellow" }}>
                    {query}
                  </span>
                )}
              </React.Fragment>
            ))
          : title}
      </Typography>
    </Box>
  );
};

const SmallTextInfo = ({ startIcon, title, text, titleColor }: Props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: { mobile: "18px", sm: "24px", xl: "32px" },
      }}
    >
      <SmallTextTitle
        startIcon={startIcon}
        title={title}
        titleColor={titleColor}
      />
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "18px 1fr",
          gap: { mobile: "12px", xl: "30px" },
        }}
      >
        <Box />
        <Typography
          sx={{
            fontSize: { mobile: "16px", md: "20px", xl: "24px" },
            color: "#707070",
          }}
        >
          {text}
        </Typography>
      </Box>
    </Box>
  );
};

export default SmallTextInfo;
