import { Box, Button, styled } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineDownload } from "react-icons/ai";

type Props = {
  title: string;
  file: string;
  year?: string;
};

const StyledMainText = styled("h4")({
  color: "#707070",
  fontSize: "22px",
  fontWeight: "bold",
  flex: 1,
  "@media (max-width: 960px)": {
    fontSize: "18px",
  },
  "@media (max-width: 600px)": {
    fontSize: "14px",
  },
});

const Report = ({ title, file, year }: Props) => {
  const { t, i18n } = useTranslation();
  return (
    <Box
      sx={{
        width: "100%",
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        justifyContent: "center",
        justifyItems: "center",
        alignContent: "center",
        alignItems: "center",
        padding: { mobile: "16px 12px", sm: "30px 38px" },
        borderRadius: "10px",
        backgroundColor: "#428E3312",
      }}
    >
      <StyledMainText
        sx={{
          justifySelf: "flex-start",
        }}
      >
        {title}{" "}
      </StyledMainText>
      <StyledMainText
        sx={{
          marginRight: i18n.dir() === "rtl" ? "16px" : "52px",
        }}
      >
        {year}
      </StyledMainText>
      <Button
        variant="contained"
        color="primary"
        sx={{
          width: { mobile: "100%", sm: "150px" },
          height: "100%",
          borderRadius: "8px",
          justifySelf: "flex-end",
          fontSize: { mobile: ".875rem", sm: "1rem" },
        }}
        endIcon={<AiOutlineDownload />}
        onClick={() => window.open(file, "_blank")}
      >
        {t("reports.download")}
      </Button>
    </Box>
  );
};

export default Report;
