import React, { useMemo } from "react";
import useGetSingleApi from "../../hooks/useGetSingleApi";
import { Box, Skeleton } from "@mui/material";
import QuestionItem from "./QuestionItem";

type Props = {};

const QuestionItems = (props: Props) => {
  const apiUrl = useMemo(() => "/faqs", []);
  const { data, loading } = useGetSingleApi(apiUrl);
  if (loading) {
    return (
      <Skeleton
        sx={{
          width: "100%",
          height: "300px",
        }}
      />
    );
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      {data?.map((item: any, index: number) => (
        <QuestionItem
          key={index}
          question={item.question}
          answer={item.answer}
        />
      ))}
    </Box>
  );
};

export default QuestionItems;
