import { Box, useMediaQuery } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Banner from "../../components/Banner/Banner";
import ContainerWithPadding from "../../components/Containers/ContainerWithPadding";
import SecondaryContainer from "../../components/Containers/SecondaryContainer";
import CustomPagination from "../../components/CusomPagination/CustomPagination";
import MainLoader from "../../components/Loaders/MainLoader";
import PhotoLine from "../../components/PhotoLine/PhotoLine";
import PhotoModal from "../../components/PhotoModal/PhotoModal";
import StyledHeader from "../../components/StyledComponents/StyledHeader";
import useGetSingleApi from "../../hooks/useGetSingleApi";
import useScrollToTop from "../../hooks/useScrollToTop";

type Props = {};

const Photos = (props: Props) => {
  const { t, i18n } = useTranslation();
  const apiUrl = useMemo(() => "/albums", []);
  const { data, loading } = useGetSingleApi(apiUrl);

  const [photoGroups, setPhotoGroups] = useState<any>([]);
  const matchesMd = useMediaQuery("(max-width:1280px)");
  useScrollToTop();

  useEffect(() => {
    if (matchesMd) {
      const groupedPhotos = [];
      for (let i = 0; i < data?.page_details?.ALBUM?.length; i += 2) {
        groupedPhotos.push(data?.page_details?.ALBUM?.slice(i, i + 2));
      }
      setPhotoGroups(groupedPhotos);
    } else {
      const groupedPhotos = [];
      for (let i = 0; i < data?.page_details?.ALBUM?.length; i += 3) {
        groupedPhotos.push(data?.page_details?.ALBUM.slice(i, i + 3));
      }
      setPhotoGroups(groupedPhotos);
    }
  }, [data]);
  if (loading) {
    return <MainLoader />;
  }
  return (
    <>
      <Box>
        <Banner
          image={data?.image}
          title={data?.title}
          text={data?.description}
        />

        <ContainerWithPadding
          sx={{
            "&::before": {
              transform:
                i18n.dir() === "rtl"
                  ? "translate(-50%,-50%)"
                  : "translate(-50%, -50%) rotate(180deg)",
              backgroundSize: "contain",
            },
          }}
        >
          <Box
            sx={{
              width: "100%",
            }}
          >
            <SecondaryContainer>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <StyledHeader
                  sx={{
                    color: "#428E33",
                    marginBottom: {
                      mobile: "32px",
                      md: "60px",
                      xl: "80px",
                    },
                  }}
                >
                  {data?.title}
                </StyledHeader>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  {data?.page_details?.ALBUM &&
                    photoGroups.map((photo: any, index: number) => {
                      if (index % 2 === 0) {
                        return (
                          <PhotoLine
                            type="photo"
                            key={index}
                            odd
                            photos={photo}
                          />
                        );
                      } else {
                        return (
                          <PhotoLine type="photo" key={index} photos={photo} />
                        );
                      }
                    })}
                </Box>
              </Box>
            </SecondaryContainer>
            <CustomPagination
              pagesNumber={
                data?.page_details?.ALBUM?.length / 6 > 1
                  ? Math.ceil(data?.page_details?.ALBUM?.length / 6)
                  : 0
              }
            />
          </Box>
        </ContainerWithPadding>
      </Box>
      <PhotoModal />
    </>
  );
};

export default Photos;
