import { Box, Container } from "@mui/material";
import React from "react";

type Props = {
  children: React.ReactNode;
};

const SecondaryContainer = ({ children }: Props) => {
  return (
    <Box
      sx={{
        zIndex: 2,
        margin: "0 280px",
        "@media (max-width: 1650px)": {
          margin: "0 240px",
        },
        "@media (max-width: 1440px)": {
          margin: "0 200px",
        },
        "@media (max-width: 1280px)": {
          margin: "0 140px",
        },
        "@media (max-width: 960px)": {
          margin: "0 100px",
        },
        "@media (max-width: 600px)": {
          margin: "0 40px",
        },
        height: "100%",
      }}
    >
      {children}
    </Box>
  );
};

export default SecondaryContainer;
