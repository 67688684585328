import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import bg from "../../assets/images/error.gif";
import SecondaryContainer from "../../components/Containers/SecondaryContainer";

type Props = {};

const ErrorPage = (props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Box>
      <SecondaryContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            minHeight: "40vh",
          }}
        >
          <Box
            component="img"
            src={bg}
            alt="error"
            sx={{
              width: "100%",
              height: "auto",
              maxWidth: "500px",
              objectFit: "contain",
            }}
          />
          <Typography
            variant="h1"
            sx={{
              color: "gray",
              fontWeight: "bold",
            }}
          >
            404
          </Typography>
          <Typography
            variant="h3"
            sx={{
              fontWeight: "bold",
              color: "gray",
              fontSize: {
                mobile: "1.5rem",
                sm: "2rem",
                md: "2.5rem",
              },
            }}
          >
            {t("errorPage.title")}{" "}
          </Typography>
          <Button
            sx={{
              margin: "2rem 0",
              fontSize: {
                mobile: "1rem",
                sm: "1.2rem",
                md: "1.5rem",
              },
            }}
            onClick={() => navigate("/")}
            variant="outlined"
          >
            {t("errorPage.home")}{" "}
          </Button>
        </Box>
      </SecondaryContainer>
    </Box>
  );
};

export default ErrorPage;
