import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import timeGridPlugin from "@fullcalendar/timegrid"; // a plugin!
import { Box, Button, ButtonGroup, IconButton } from "@mui/material";
import { useMemo, useRef, useEffect } from "react";
import { GrNext, GrPrevious } from "react-icons/gr";
import useGetSingleApi from "../../hooks/useGetSingleApi";
import MainLoader from "../Loaders/MainLoader";
import ar from "@fullcalendar/core/locales/ar";

import { useTranslation } from "react-i18next";
import "./calender.scss";
import PhotoModal from "../PhotoModal/PhotoModal";
import { useLayoutContext } from "../../context/layoutContext";
type event = {
  id: number;
  title: string;
  description: string;
  image: string;
  event_date: string;
};

type Props = {
  events: event[];
};

const Calendar = ({ events }: Props) => {
  const calendarRef = useRef<FullCalendar | null>(null);

  const { i18n, t } = useTranslation();
  const handleTodayButtonClick = () => {
    if (calendarRef.current) {
      const today = new Date(); // Get the current date
      calendarRef.current.getApi().gotoDate(today);
    }
  };

  const handleMonthButtonClick = () => {
    if (calendarRef.current) {
      calendarRef.current.getApi().changeView("dayGridMonth");
    }
  };
  const handleWeekButtonClick = () => {
    if (calendarRef.current) {
      calendarRef.current.getApi().changeView("timeGridWeek");
    }
  };

  const handleDayButtonClick = () => {
    if (calendarRef.current) {
      calendarRef.current.getApi().changeView("timeGridDay");
    }
  };
  const handlePreviousButtonClick = () => {
    if (calendarRef.current) {
      calendarRef.current.getApi().prev();
    }
  };
  const handleNextButtonClick = () => {
    if (calendarRef.current) {
      calendarRef.current.getApi().next();
    }
  };

  useEffect(() => {
    const timeGridHeaders = document.getElementsByClassName(
      "fc-timegrid-axis-cushion fc-scrollgrid-sync-inner"
    );
    Array.from(timeGridHeaders).forEach((header: any) => {
      header.style.display = "none";
    });
  }, []);
  const { photoModal, setPhotoModal, photoModalData, setPhotoModalData } =
    useLayoutContext();
  return (
    <Box sx={{ width: "100%", height: "100%" }} data-aos="fade-zoom-in">
      <Box sx={{ width: "100%", position: "relative" }}>
        <Box
          sx={{
            display: "flex",
            position: "absolute",
            top: {
              mobile: "26px",
              sm: "1rem",
            },
            gap: "50px",
          }}
        >
          <ButtonGroup variant="outlined" aria-label="outlined button group">
            <Button
              sx={{
                padding: { mobile: "2px 5px", sm: "5px 12px" },
              }}
              onClick={handleWeekButtonClick}
            >
              {t("calendar.week")}
            </Button>
            <Button
              sx={{
                padding: { mobile: "2px 5px", sm: "5px 12px" },
              }}
              onClick={handleMonthButtonClick}
            >
              {t("calendar.month")}
            </Button>
          </ButtonGroup>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            position: "absolute",
            right: "0",
            top: {
              mobile: "26px",
              sm: "1rem",
            },
            direction: i18n.dir() === "rtl" ? "ltr" : "rtl",
          }}
        >
          <IconButton
            sx={{
              width: {
                mobile: "30px",
                xs: "40px",
              },
              height: {
                mobile: "30px",
                xs: "40px",
              },
              borderRadius: "50%",
              fontSize: "20px",
              color: "primary.light",
              "& svg": {
                "& polyline": {
                  stroke: "#428E33",
                },
              },
            }}
            onClick={handleNextButtonClick}
          >
            <GrNext />
          </IconButton>
          <IconButton
            sx={{
              width: {
                mobile: "30px",
                xs: "40px",
              },
              height: {
                mobile: "30px",
                xs: "40px",
              },
              borderRadius: "50%",
              fontSize: "20px",
              color: "primary.light",
              "& svg": {
                "& polyline": {
                  stroke: "#428E33",
                },
              },
            }}
            onClick={handlePreviousButtonClick}
          >
            <GrPrevious />
          </IconButton>
          <Button
            onClick={handleTodayButtonClick}
            sx={{
              display: {
                mobile: "none",
                xs: "block",
              },
            }}
          >
            {t("calendar.today")}
          </Button>
        </Box>
        <FullCalendar
          navLinks={true}
          locale={i18n.language === "ar" ? ar : undefined}
          headerToolbar={{
            left: "monthButton",
            center: "title",
            right: "weekButton,dayButton",
          }}
          ref={calendarRef}
          eventClick={(info) => {
            const event = events.find((e) => e.id === Number(info.event.id));
            if (event) {
              setPhotoModalData({
                description: event.description,
                file: event.image,
                id: String(event.id),
              });
              setPhotoModal(true);
            }
          }}
          droppable={true}
          plugins={[dayGridPlugin, timeGridPlugin]}
          initialView="dayGridMonth"
          views={{
            dayGridMonth: {
              dayMaxEventRows: 2,
              slotDuration: undefined,
              slotLabelInterval: undefined,
            },
            timeGridWeek: {
              titleFormat: {
                year: "numeric",
                month: "short",
                day: "numeric",
              },
              allDaySlot: true,
              slotDuration: "24:00:00",
              slotLabelInterval: null,
            },
            timeGridDay: {
              titleFormat: {
                year: "numeric",
                month: "short",
                day: "numeric",
              },
              allDaySlot: true,
            },
          }}
          events={events.map((e) => {
            return {
              id: String(e.id),
              title: e.title,
              date: e.event_date,
            };
          })}
        />
      </Box>
      <PhotoModal />
    </Box>
  );
};

export default Calendar;
