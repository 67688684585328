import { Button } from "@mui/material";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";

type Props = {
  direction: "up" | "down";
  action: () => void;
  disabled?: boolean;
};

const CustomArrow = ({ direction, action }: Props) => {
  return (
    <Button
      variant="contained"
      onClick={action}
      // disabled={disabled}
      sx={{
        minWidth: "0px",
        width: { mobile: "36px", lg: "45px", xl: "60px" },
        height: { mobile: "36px", lg: "45px", xl: "60px" },
        padding: "0px",
        backgroundColor: "#428E33",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        fontSize: "4rem",
        "&:hover": {
          backgroundColor: "#4ba938",
        },
        "&.Mui-disabled": {
          backgroundColor: "#428e338c",
          "& svg": {
            width: "100%",
            height: "100%",
            fill: "#f1e7e7",
          },
        },
      }}
    >
      {direction === "up" ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}{" "}
    </Button>
  );
};

export default CustomArrow;
