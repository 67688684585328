import { Box, Button, Grow, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { useEffect, useMemo, useState } from "react";
import { BsInfoLg } from "react-icons/bs";
import Cookies from "universal-cookie";
import useGetSingleApi from "../../hooks/useGetSingleApi";

const AttentionBox = () => {
  // State to control the visibility of the box
  const [openBox, setOpenBox] = useState(true);

  // Initialize cookies
  const cookies = new Cookies();

  // State to determine if it's the user's first visit
  const [firstTime, setFirstTime] = useState(true);

  // Handle the closing of the attention box
  const handleClose = () => {
    setOpenBox(false);
    cookies.set("attentionBox", true, {
      path: "/",
      expires: new Date(Date.now() + 1000 * 60 * 60 * 24), // Expires in one day
    });
    setFirstTime(false);
  };

  // Memoize the API URL
  const apiUrl = useMemo(() => "/announcement", []);

  // Fetch data from the API
  const { data } = useGetSingleApi(apiUrl);

  // Check for cookie on component mount
  useEffect(() => {
    if (cookies.get("attentionBox")) {
      setFirstTime(false);
    }
  }, [cookies]);

  // Do not render if there's no data
  if (!data) return null;

  return (
    <>
      {firstTime && (
        <Box
          component={motion.div}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          sx={{
            position: "fixed",
            bottom: "1rem",
            right: "1rem",
            zIndex: 9999,
          }}
        >
          {/* Close button */}
          <Button
            variant="contained"
            color="error"
            onClick={handleClose}
            sx={{
              // Styling for the close button
              position: "fixed",
              bottom: "1rem",
              right: "1rem",
              borderRadius: "50%",
              minWidth: "unset",
              padding: "0 !important",
              width: { mobile: "2rem", md: "2.5rem" },
              height: { mobile: "2rem", md: "2.5rem" },
              "& svg": {
                fontSize: { mobile: "1.2rem", md: "2rem" },
              },
            }}
          >
            <BsInfoLg />
          </Button>

          {/* The actual attention box */}
          <Grow in={openBox}>
            <Box
              sx={{
                // Styling for the attention box
                position: "absolute",
                bottom: { mobile: "2.5rem", md: "3rem" },
                right: "0",
                padding: "1rem",
                width: { mobile: "250px", md: "350px" },
                height: { mobile: "220px", md: "300px" },
                boxShadow: "0px 0px 10px 0px #428e3340",
                backgroundColor: "#fff",
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                borderRadius: { mobile: "12px", md: "24px" },
                justifyContent: "center",
                gap: { mobile: ".5rem", md: "1rem" },
              }}
            >
              {/* Displaying data from the API */}
              <Typography
                sx={{
                  color: "error.main",
                  fontWeight: "bold",
                  fontSize: { mobile: "1.25rem", md: "1.5rem" },
                  textDecoration: "underline",
                }}
              >
                {data?.title}
              </Typography>
              <Typography
                sx={{
                  fontSize: { mobile: "1rem", md: "1.25rem" },
                }}
              >
                {data?.description}
              </Typography>
            </Box>
          </Grow>
        </Box>
      )}
    </>
  );
};

export default AttentionBox;
