import { Box } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import grayTriangle from "../../assets/images/gray-triangle.svg";
import Banner from "../../components/Banner/Banner";
import ContainerWithPadding from "../../components/Containers/ContainerWithPadding";
import SecondaryContainer from "../../components/Containers/SecondaryContainer";
import MainLoader from "../../components/Loaders/MainLoader";
import StyledHeader from "../../components/StyledComponents/StyledHeader";
import SmallTextInfo from "../../components/TextInfo/SmallTextInfo";
import TextList from "../../components/TextList/TextList";
import useGetSingleApi from "../../hooks/useGetSingleApi";
import ErrorPage from "../ErrorPage/ErrorPage";

type Props = {};

const InvestmentBanking = (props: Props) => {
  const { t, i18n } = useTranslation();
  const apiUrl = useMemo(() => "/investment_banking", []);
  const { data, loading } = useGetSingleApi(apiUrl);
  if (loading) {
    return <MainLoader />;
  }
  return (
    <>
      {data && (
        <Box>
          <Banner
            image={data?.image}
            title={data?.title}
            text={data?.description}
          />

          {data?.details?.length > 0 ? (
            <ContainerWithPadding
              sx={{
                "&::before": {
                  transform:
                    i18n.dir() === "rtl"
                      ? "translate(-50%,-50%)"
                      : "translate(-50%, -50%) rotate(180deg)",
                  backgroundSize: "contain",
                },
              }}
            >
              <SecondaryContainer>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "32px",
                  }}
                >
                  <StyledHeader
                    sx={{
                      color: "primary.main",
                    }}
                  >
                    {data?.title}{" "}
                  </StyledHeader>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: { mobile: "40px", md: "60px", xl: "80px" },
                    }}
                  >
                    {data?.details?.map((item: any, index: number) => {
                      return (
                        <InvestmentBankingSection
                          key={index}
                          title={item?.title}
                          description={item.description}
                        />
                      );
                    })}
                  </Box>
                </Box>
              </SecondaryContainer>
            </ContainerWithPadding>
          ) : (
            <ErrorPage />
          )}
        </Box>
      )}
    </>
  );
};

export default InvestmentBanking;

interface InvestmentBankingSectionProps {
  title: string;
  description: string[];
}

const InvestmentBankingSection = ({
  title,
  description,
}: InvestmentBankingSectionProps) => {
  return (
    <Box
      component="section"
      id="investment-banking-section"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: { mobile: "24px", sm: "32px", md: "38px", xl: "46px" },
        padding: { mobile: "24px 0", md: "48px 0", xl: "64px 0" },
        position: "relative",
        "&::after": {
          content: "''",
          position: "absolute",
          bottom: "-30px",
          left: "0",
          width: {
            mobile: "calc(100% + 40px)",
            xs: "calc(100% + 100px)",
            sm: "calc(100% + 140px)",
            md: "calc(100% + 200px)",
            lg: "calc(100% + 240px)",
            xl: "calc(100% + 280px)",
          },
          height: "7px",
          backgroundColor: "#F2F2F2",
        },
      }}
    >
      <SmallTextInfo
        title={title}
        startIcon={
          <Box
            component="img"
            sx={{
              mt: {
                mobile: "5px",
                md: "10px",
              },
            }}
            src={grayTriangle}
            alt="gray-triangle"
          />
        }
      />
      <TextList items={description} />
    </Box>
  );
};
