import { Box, Typography } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import grayTriangle from "../../assets/images/gray-triangle.svg";
import Banner from "../../components/Banner/Banner";
import ContainerWithPadding from "../../components/Containers/ContainerWithPadding";
import LinearGradientContainer from "../../components/Containers/LinearGradientContainer";
import SmallerContainer from "../../components/Containers/SmallerContainer";
import MainLoader from "../../components/Loaders/MainLoader";
import StyledHeader from "../../components/StyledComponents/StyledHeader";
import useGetSingleApi from "../../hooks/useGetSingleApi";
import ErrorPage from "../ErrorPage/ErrorPage";

type Props = {};

const SecuritiesManagement = (props: Props) => {
  const { t, i18n } = useTranslation();
  const apiUrl = useMemo(() => "/securities_management", []);
  const { data, loading } = useGetSingleApi(apiUrl);
  if (loading) {
    return <MainLoader />;
  }
  return (
    <>
      {data && (
        <Box>
          <Banner
            image={data?.image}
            title={data?.title}
            text={data?.description}
          />

          {data?.details[0] ? (
            <ContainerWithPadding
              sx={{
                display: "flex",
                flexDirection: "column",

                gap: "50px",
                "&::before": {
                  transform:
                    i18n.dir() === "rtl"
                      ? "translate(-50%,-50%)"
                      : "translate(-50%, -50%) rotate(180deg)",
                  backgroundSize: "contain",
                },
              }}
            >
              <SmallerContainer>
                <StyledHeader
                  sx={{
                    maxWidth: { mobile: "100%", md: "60%" },
                  }}
                >
                  {data?.details[0]?.title}{" "}
                </StyledHeader>
              </SmallerContainer>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: { mobile: "30px", md: "45px", xl: "60px" },
                  zIndex: 2,
                }}
              >
                {data?.details[0]?.description?.map(
                  (item: any, index: number) => {
                    if (index === 0) {
                      return (
                        <LinearGradientContainer
                          key={index}
                          sx={{
                            margin: {
                              mobile: "0",
                              xs: "20px 0",
                              sm: "30px 0",
                              md: "40px 0",
                              lg: "60px 0",
                              xl: "80px 0",
                            },
                          }}
                        >
                          <SmallerContainer>
                            <Typography
                              sx={{
                                position: "relative",
                                zIndex: 2,
                                fontSize: {
                                  mobile: "20px",
                                  md: "24px",
                                  xl: "28px",
                                },
                                fontWeight: 700,
                                color: "primary.main",
                                padding: {
                                  mobile: "40px 0",
                                  md: "60px 0",
                                  xl: "80px 0",
                                },
                                lineHeight: { mobile: "36px", lg: "52px" },
                              }}
                            >
                              {item}{" "}
                            </Typography>
                          </SmallerContainer>
                        </LinearGradientContainer>
                      );
                    } else {
                      return (
                        <SmallerContainer key={index}>
                          <ParagraphWithIcon text={item} />
                        </SmallerContainer>
                      );
                    }
                  }
                )}
              </Box>
            </ContainerWithPadding>
          ) : (
            <ErrorPage />
          )}
        </Box>
      )}
    </>
  );
};

export default SecuritiesManagement;

type ParagraphProps = {
  text: string;
};

const ParagraphWithIcon = ({ text }: ParagraphProps) => {
  const { i18n } = useTranslation();
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "20px 1fr",
        gap: { mobile: "15px", lg: "50px", xl: "50px" },
        // alignItems: "center",
      }}
    >
      <Box
        component="img"
        src={grayTriangle}
        alt="grayTriangle"
        sx={{
          transform: i18n.dir() === "ltr" ? "rotate(180deg)" : "rotate(0deg)",
          paddingTop: { mobile: "5px", md: "10px", xl: "10px" },
        }}
      />
      <Typography
        sx={{
          fontSize: { mobile: "16px", md: "24px", xl: "24px" },
          fontWeight: "600",
          color: "#505050",
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};
