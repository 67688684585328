import { Box } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Banner from "../../components/Banner/Banner";
import ComplaintsForm from "../../components/ComplaintsForm/ComplaintsForm";
import ContainerWithPadding from "../../components/Containers/ContainerWithPadding";
import SecondaryContainer from "../../components/Containers/SecondaryContainer";
import MainLoader from "../../components/Loaders/MainLoader";
import TextInfo from "../../components/TextInfo/TextInfo";
import useGetSingleApi from "../../hooks/useGetSingleApi";

type Props = {};

const Complaints = (props: Props) => {
  const { t, i18n } = useTranslation();
  const apiUrl = useMemo(() => "/complaint_page_details", []);
  const { data, loading } = useGetSingleApi(apiUrl);
  if (loading) {
    return <MainLoader />;
  }
  return (
    <>
      {data && (
        <Box>
          <Banner image={data?.image} title={data?.title} />

          {data && (
            <ContainerWithPadding
              sx={{
                "&::before": {
                  transform:
                    i18n.dir() === "rtl"
                      ? "translate(-50%,-50%)"
                      : "translate(-50%, -50%) rotate(180deg)",
                  backgroundSize: "contain",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <SecondaryContainer>
                  <TextInfo
                    headerColor="#428E33"
                    link={t("homePage.aboutUs.more")}
                    header={data?.title}
                    text={data?.description}
                  />
                </SecondaryContainer>
                <ComplaintsForm />
              </Box>
            </ContainerWithPadding>
          )}
        </Box>
      )}
    </>
  );
};

export default Complaints;
