import { Box, ClickAwayListener, Fade, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { useState } from "react";
import { BsFillCaretDownFill } from "react-icons/bs";

interface Props {
  items: {
    id: number;
    title: string;
  }[];
  selectedItem: number;
  setSelectedItem: React.Dispatch<React.SetStateAction<number>>;
}

const IndirectFundMenu = ({ items, selectedItem, setSelectedItem }: Props) => {
  const [collapse, setCollapse] = useState(false);
  return (
    <ClickAwayListener onClickAway={() => setCollapse(false)}>
      <Box
        sx={{
          position: "relative",
        }}
      >
        <Button
          disableRipple
          id="basic-menu-button"
          fullWidth
          sx={{
            width: { mobile: "100%", sm: "400px", md: "100%" },
            backgroundColor: "#F1F1F1",
            borderRadius: "8px",
            height: "70px",
            fontSize: { mobile: "1rem", md: "1.25rem" },
            transition: "all 0.2s ease-in",
            fontWeight: "bold",

            "& .MuiButton-endIcon": {
              transition: "all 0.1s ease-in-out",
              marginLeft: { mobile: "24px", lg: "64px" },
              transform: collapse ? "rotate(180deg)" : "rotate(0deg)",
            },
            "&:hover": {
              backgroundColor: "#dadada",
            },
          }}
          endIcon={<BsFillCaretDownFill />}
          onClick={() => setCollapse(!collapse)}
          data-testid={items?.find((item) => item.id === selectedItem)?.title}
        >
          {items?.find((item) => item.id === selectedItem)?.title}{" "}
        </Button>
        <Fade in={collapse}>
          <Box
            sx={{
              zIndex: 5,
              position: "absolute",
              bottom: "0",
              transform: "translateY(105%)",
              width: { mobile: "100%", sm: "400px", md: "100%" },
              borderRadius: "8px",
              backgroundColor: "primary.main",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              padding: { mobile: "36px 24px 20px", lg: "36px 24px 20px" },
            }}
          >
            {items?.map((item, index: number) => {
              return (
                <Typography
                  key={item?.id}
                  sx={{
                    color: "#fff",
                    fontSize: { mobile: "1rem", md: "1.25rem" },
                    "&:hover": { fontWeight: "bold" },
                    textAlign: "center",
                    width: "100%",
                    borderBottom:
                      items.length === index + 1
                        ? "none"
                        : "1px solid #ffffff63",
                    paddingBottom: "20px",
                    cursor: "pointer",
                    transition: "all 0.1s ease-in-out",
                  }}
                  onClick={() => {
                    setSelectedItem(item?.id);
                    setCollapse(false);
                  }}
                >
                  {item.title}
                </Typography>
              );
            })}
          </Box>
        </Fade>
      </Box>
    </ClickAwayListener>
  );
};

export default IndirectFundMenu;
