import { Box } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Banner from "../../components/Banner/Banner";
import ContainerWithPadding from "../../components/Containers/ContainerWithPadding";
import SecondaryContainer from "../../components/Containers/SecondaryContainer";
import ContactUs from "../../components/LayoutComponents/ContactUs/ContactUs";
import LeadershipCard from "../../components/LeadershipComponents/LeadershipCard/LeadershipCard";
import MainLoader from "../../components/Loaders/MainLoader";
import TextInfo from "../../components/TextInfo/TextInfo";
import { useLayoutContext } from "../../context/layoutContext";
import useGetSingleApi from "../../hooks/useGetSingleApi";

type Props = {};
const containerStyles = {
  display: "grid",
  gap: "16px",
  width: "100%",
  gridTemplateColumns: {
    mobile: "repeat(1, 1fr)",
    xs: "repeat(2, 1fr)",
    sm: "repeat(3, 1fr)",
  },
};

const OurStrength = (props: Props) => {
  const { t, i18n } = useTranslation();
  const { data: contactData, loading: contactLoading } = useLayoutContext();

  const apiUrl = useMemo(() => "/fatwa_shariaa", []);
  const { data, loading } = useGetSingleApi(apiUrl);
  if (loading) {
    return <MainLoader />;
  }
  return (
    <>
      {data && (
        <Box>
          <Banner image={data?.image} title={data?.title} />

          <ContainerWithPadding
            sx={{
              "&::before": {
                transform:
                  i18n.dir() === "rtl"
                    ? "translate(-50%,-50%)"
                    : "translate(-50%, -50%) rotate(180deg)",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                gap: {
                  xs: "2rem",
                  md: "5rem",
                },
                flexDirection: {
                  mobile: "column",
                },
              }}
            >
              <SecondaryContainer>
                <TextInfo
                  headerColor="#428E33"
                  link={t("homePage.aboutUs.more")}
                  header={data?.title}
                />
              </SecondaryContainer>
              <SecondaryContainer>
                <Box sx={containerStyles}>
                  {data?.page_details?.map((item: any) => {
                    return <LeadershipCard key={item.id} data={item} />;
                  })}
                </Box>
              </SecondaryContainer>
            </Box>
          </ContainerWithPadding>
        </Box>
      )}
      {contactData && contactData[0] && (
        <ContactUs contactInfo={contactData[0]} />
      )}
    </>
  );
};

export default OurStrength;
