import { Box } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Banner from "../../components/Banner/Banner";
import ContainerWithPadding from "../../components/Containers/ContainerWithPadding";
import SecondaryContainer from "../../components/Containers/SecondaryContainer";
import CustomPagination from "../../components/CusomPagination/CustomPagination";
import MainLoader from "../../components/Loaders/MainLoader";
import Video from "../../components/MediaComponents/Video";
import StyledHeader from "../../components/StyledComponents/StyledHeader";
import useGetSingleApi from "../../hooks/useGetSingleApi";
import useScrollToTop from "../../hooks/useScrollToTop";

type Props = {};

const Videos = (props: Props) => {
  const { t, i18n } = useTranslation();
  const apiUrl = useMemo(() => "/videos", []);
  const { data, loading } = useGetSingleApi(apiUrl);
  const [page, setPage] = useState(1);

  const [paginatedVideos, setPaginatedVideos] = useState<any>([]);
  useScrollToTop();
  useEffect(() => {
    if (data?.page_details?.VIDEO) {
      const videos = data?.page_details?.VIDEO.slice((page - 1) * 8, page * 8);
      setPaginatedVideos(videos);
    }
  }, [data, page]);

  const [isPlaying, setIsPlaying] = useState(null);
  if (loading) {
    return <MainLoader />;
  }

  return (
    <>
      <Box>
        <Banner
          image={data?.image}
          title={data?.title}
          text={data?.description}
        />

        <ContainerWithPadding
          sx={{
            "&::before": {
              transform:
                i18n.dir() === "rtl"
                  ? "translate(-50%,-50%)"
                  : "translate(-50%, -50%) rotate(180deg)",
              backgroundSize: "cover",
            },
          }}
        >
          <Box
            sx={{
              width: "100%",
            }}
          >
            <SecondaryContainer>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <StyledHeader
                  sx={{
                    color: "#428E33",
                    marginBottom: {
                      mobile: "32px",
                      md: "60px",
                      xl: "80px",
                    },
                  }}
                >
                  {data?.title}{" "}
                </StyledHeader>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: { mobile: "1fr", xs: "repeat(2,1fr)" },
                    gap: "16px",
                  }}
                >
                  {paginatedVideos?.map(
                    (video: { id: number; title: string; url: string }) => (
                      <Video
                        id={video.id}
                        setIsPlaying={setIsPlaying}
                        isPlaying={isPlaying}
                        title={video.title}
                        src={video.url}
                        key={video.id}
                      />
                    )
                  )}
                </Box>
              </Box>
            </SecondaryContainer>
            {data?.page_details?.VIDEO &&
              Math.ceil(data.page_details.VIDEO.length / 8) > 1 && (
                <CustomPagination
                  pagesNumber={Math.ceil(data.page_details.VIDEO.length / 8)}
                  setPage={setPage}
                />
              )}
          </Box>
        </ContainerWithPadding>
      </Box>
    </>
  );
};

export default Videos;
