import React from "react";
import { motion } from "framer-motion";

type Props = {
  children: React.ReactNode;
};

const AnimateTap = ({ children }: Props) => {
  return (
    <motion.div
      // whileHover={{
      //   scale: 1.1,
      // }}
      whileTap={{
        scale: 0.9,
      }}
    >
      {children}{" "}
    </motion.div>
  );
};

export default AnimateTap;
