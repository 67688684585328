import { Box } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Banner from "../../components/Banner/Banner";
import ContainerWithPadding from "../../components/Containers/ContainerWithPadding";
import SecondaryContainer from "../../components/Containers/SecondaryContainer";
import ContactUs from "../../components/LayoutComponents/ContactUs/ContactUs";
import MainLoader from "../../components/Loaders/MainLoader";
import ServicesContainer from "../../components/Services/ServicesContainer";
import TextInfo from "../../components/TextInfo/TextInfo";
import { useLayoutContext } from "../../context/layoutContext";
import useGetSingleApi from "../../hooks/useGetSingleApi";

type Props = {};

const Services = (props: Props) => {
  const { t, i18n } = useTranslation();
  const { data: contactData, loading: contactLoading } = useLayoutContext();

  const apiUrl = useMemo(() => "/service_page", []);
  const { data, loading } = useGetSingleApi(apiUrl);
  if (loading) {
    return <MainLoader />;
  }
  return (
    <>
      {data && (
        <Box>
          <Banner image={data?.image} title={data?.title} />
          <ContainerWithPadding
            sx={{
              display: "flex",
              flexDirection: "column",
              "&::before": {
                backgroundSize: "contain",
                transform:
                  i18n.dir() === "rtl"
                    ? "translate(-50%,-50%)"
                    : "translate(-50%, -50%) rotate(180deg)",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
                paddingBottom: "180px",

                "@media (max-width: 1650px)": {
                  paddingBottom: "160px",
                },
                "@media (max-width: 1440px)": {
                  paddingBottom: "140px",
                },
                "@media (max-width: 1280px)": {
                  paddingBottom: "100px",
                },
                "@media (max-width: 960px)": {
                  paddingBottom: "60px",
                },
              }}
            >
              {data && (
                <SecondaryContainer>
                  <TextInfo
                    headerColor="#428E33"
                    link={t("homePage.aboutUs.more")}
                    header={data?.title}
                    text={data?.description}
                    showFullText
                  />
                </SecondaryContainer>
              )}
            </Box>

            <ServicesContainer services={data?.list} />
          </ContainerWithPadding>
        </Box>
      )}
      {contactData && contactData[0] && (
        <ContactUs contactInfo={contactData[0]} />
      )}
    </>
  );
};

export default Services;
