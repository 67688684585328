import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import * as React from "react";
import { useTranslation } from "react-i18next";
import greenTriangle from "../../../assets/images/green-triangle.svg";
import modalBg from "../../../assets/images/modal-bg.jpg";
import { SmallTextTitle } from "../../TextInfo/SmallTextInfo";
import MultiReport from "./MultiReport";
import SingleReport from "./SingleReport";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60vw",
  minHeight: "400px",
  height: "90vh",
  minWidth: "300px",
  bgcolor: "background.paper",
  boxShadow: "0 3px 6px #00000029",
  borderRadius: "12px",
  p: "70px 100px",
  overflowY: "scroll" as "scroll",
  backgroundImage: `url(${modalBg})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "100% 100%",
  backgroundPosition: "center",
  //change the styles of the scroll bar
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-track": {
    width: "8px",
  },

  "&::-webkit-scrollbar-thumb": {
    background: "#8BCE7D",
    borderRadius: "24px",
  },
  "@media (max-width: 1280px)": {
    width: "80vw",
    padding: "60px 40px",
    height: "85vh",
  },
  "@media (max-width: 960px)": {
    width: "70vw",
    padding: "50px 30px",
    height: "80vh",
  },
  "@media (max-width: 600px)": {
    width: "80vw",
    padding: "30px 20px",
    height: "65vh",
  },
};

interface Props {
  reports: {
    id: number;
    title: string;
    report_files: { id: number; title: string; file: string }[];
  }[];
}

const GeneralInvestmentModal = ({ reports }: Props) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { t, i18n } = useTranslation();
  return (
    <div>
      <Button
        variant="contained"
        onClick={handleOpen}
        sx={{
          borderRadius: "10px",
          fontSize: { mobile: "18px", xs: "20px", md: "22px" },
          fontWeight: "bold",
          height: { mobile: "40px", xs: "50px", md: "70px" },
          width: {
            mobile: "150px",
            xs: "200px",
            sm: "250px",
            md: "300px",
            lg: "340px",
          },
        }}
      >
        {t("realEstateInvestmentFund.reports")}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableScrollLock={true}
      >
        <Box sx={style}>
          <Box
            sx={{
              padding: {
                mobile: "0 0 16px 0",
                sm: "0 0 24px 0",
                lg: "0 0 35px 0",
              },
              marginBottom: {
                mobile: "32px",
                sm: "54px",
                lg: "70px",
              },
              borderBottom: "1px solid #4EA83C",
              "& img": {
                scale: { mobile: ".5", sm: ".8", md: "1" },
                transform: {
                  mobile:
                    i18n.language === "en"
                      ? "rotate(180deg) translateX(20px) translateY(5px)"
                      : "rotate(0deg) translateY(-5px)",
                  md:
                    i18n.language === "en"
                      ? "rotate(180deg) translateX(20px) translateY(0px)"
                      : "rotate(0deg) translateY(0px)",
                },
              },
            }}
          >
            <SmallTextTitle
              titleColor="#4EA83C"
              title={t("realEstateInvestmentFund.reports")}
              startIcon={<img src={greenTriangle} alt="green-triangle" />}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "32px",
            }}
          >
            {reports.map((report) => {
              if (report.report_files.length > 1) {
                return <MultiReport key={report.id} report={report} />;
              } else {
                return <SingleReport key={report.id} report={report} />;
              }
            })}
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default GeneralInvestmentModal;
