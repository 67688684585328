import { Box } from "@mui/material";
import SecondaryContainer from "../Containers/SecondaryContainer";
import Service from "./Service";

type Props = {
  services: {
    title: string;
    description: string;
    enum: number;
  }[];
};

const ServicesContainer = ({ services }: Props) => {
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <SecondaryContainer>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {services.map((service, index) => (
            <Service
              key={service.enum}
              {...service}
              id={service.enum}
              index={index}
            />
          ))}
        </Box>
      </SecondaryContainer>
    </Box>
  );
};

export default ServicesContainer;
