import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LeadershipCard from "../LeadershipCard/LeadershipCard";
import { Button, Divider, Stack, styled } from "@mui/material";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface LeadershipTabsProps {
  data?: any;
}
export default function LeadershipTabs({ data }: LeadershipTabsProps) {
  const containerStyles = {
    display: "grid",
    gap: "16px",
    gridTemplateColumns: {
      mobile: "repeat(1, 1fr)",
      xs: "repeat(2, 1fr)",
      sm: "repeat(3, 1fr)",
    },
    justifyItems: "center",
  };
  const headerStyles = {
    // flex: "1",
    alignSelf: "center",
    color: "#707070",
    cursor: "pointer",
    fontWeight: "bold",
    textAlign: "center",
    fontSize: {
      mobile: "18px",
      sm: "24px",
      md: "30px",
      lg: "36px",
      xl: "48px",
    },
  };
  const location = useLocation();

  const parsedLocation = queryString.parse(location.search);

  const navigate = useNavigate();
  const [value, setValue] = React.useState(Number(parsedLocation.value) || 1);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { t } = useTranslation();
  React.useEffect(() => {
    if (parsedLocation.value) {
      setValue(Number(parsedLocation.value));
    }
  }, [parsedLocation]);
  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          padding: { mobile: "16px 8px", xs: "24px 16px", xl: "28px 70px" },
          border: "2px solid #428E33",
          borderRadius: "10px",
          marginBottom: {
            mobile: "16px",
            xs: "24px",
            sm: "30px",
            md: "40px",
            xl: "50px",
          },
        }}
      >
        <Stack
          direction="row"
          sx={{
            width: "100%",
          }}
          justifyContent="space-around"
          divider={
            <Divider
              sx={{
                borderWidth: "1px",
                borderColor: "primary.main",
                opacity: ".35",
              }}
            />
          }
        >
          <Typography
            variant="h4"
            onClick={() => {
              navigate(`/leadership?value=1`);
            }}
            sx={{
              ...headerStyles,
              opacity: value === 1 ? "1" : ".35",
            }}
          >
            {t("layout.header.subLinks.board")}{" "}
          </Typography>
          {/* <Typography
          variant="h4"
            sx={{
              ...headerStyles,
              opacity: value === 1 ? "1" : ".35",
            }}
            onClick={() => {
              navigate(`/leadership?value=1`);
            }}
          >
            {t("layout.header.subLinks.boardCommittees")}
          </Typography> */}
          <Typography
            variant="h4"
            onClick={() => {
              navigate(`/leadership?value=2`);
            }}
            sx={{
              ...headerStyles,
              opacity: value === 2 ? "1" : ".35",
            }}
          >
            {t("layout.header.subLinks.executiveManagement")}
          </Typography>
        </Stack>
      </Box>

      <TabPanel value={value} index={1}>
        <Box sx={containerStyles}>
          {data["10401"]?.map((item: any) => {
            return <LeadershipCard key={item.id} data={item} />;
          })}
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box sx={containerStyles}>
          {" "}
          {data["10402"]?.map((item: any) => {
            return <LeadershipCard key={item.id} data={item} />;
          })}
        </Box>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Box sx={containerStyles}>
          {data["10403"]?.map((item: any) => {
            return <LeadershipCard key={item.id} data={item} />;
          })}
        </Box>
      </TabPanel>
    </Box>
  );
}

{
}
