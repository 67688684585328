import { Button, styled } from "@mui/material";
import React from "react";

type Props = {};

const MainButton = styled(Button)({
  width: "200px",
  borderRadius: "10px",
  height: "60px",
  fontSize: "1.25rem",
  boxShadow: "none",
  "&:hover": {
    backgroundColor: "#4ba938",
  },
  // 1440px
  "@media (max-width: 1440px)": {
    width: "180px",
    height: "55px",
    fontSize: "1.125rem",
  },
  "@media (max-width: 960px)": {
    width: "150px",
    height: "45px",
  },
  //600px
});

export default MainButton;
