import { Box, Stack, styled } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Banner from "../../components/Banner/Banner";
import ContainerWithPadding from "../../components/Containers/ContainerWithPadding";
import SecondaryContainer from "../../components/Containers/SecondaryContainer";
import MainLoader from "../../components/Loaders/MainLoader";
import TextInfo from "../../components/TextInfo/TextInfo";
import useGetSingleApi from "../../hooks/useGetSingleApi";

type Props = {};

const InvestmentFund = (props: Props) => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const apiUrl = useMemo(() => `/fund_details/${id}`, []);
  const { data, loading } = useGetSingleApi(apiUrl);
  if (loading) {
    return <MainLoader />;
  }
  return (
    <>
      {data && (
        <Box>
          <Banner
            image={data?.image}
            title={data?.title}
            // text={data?.description}
          />

          {data && (
            <ContainerWithPadding
              sx={{
                "&::before": {
                  backgroundSize: {
                    mobile: "contain",
                    xl: "cover",
                  },
                  transform:
                    i18n.dir() === "rtl"
                      ? "translate(-50%,-50%)"
                      : "translate(-50%, -50%) rotate(180deg)",
                },
              }}
            >
              <Box
                sx={{
                  width: "100%",
                }}
              >
                <SecondaryContainer>
                  <Stack
                    spacing={{
                      mobile: 5,
                      md: 13,
                      lg: 15,
                    }}
                  >
                    <Box
                      data-aos="fade-zoom-in"
                      data-aos-duration="1500"
                      sx={{
                        display: "grid",
                        gridTemplateColumns: {
                          mobile: "1fr",
                          sm: "1fr 1fr",
                        },
                        alignItems: "center",
                        gap: {
                          mobile: "30px",
                          xs: "40px",
                          sm: "50px",
                          md: "60px",
                          lg: "80px",
                          xl: "100px",
                        },
                      }}
                    >
                      <StyledImageContainer
                        sx={{
                          flex: 1,

                          height: {
                            mobile: "160px",
                            xs: "220px",
                            sm: "200px",
                            md: "250px",
                            lg: "270px",
                            xl: "300px",
                          },
                          padding: {
                            mobile: "20px 40px",
                            xs: "50px 40px",
                            sm: "40px 50px",
                            xl: "50px 100px",
                          },
                          "& img": {
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "15px",
                          },
                        }}
                      >
                        <Box
                          component="img"
                          src={data?.logo}
                          alt="investment-image"
                        />
                      </StyledImageContainer>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: {
                            mobile: "18px",
                            md: "23px",
                            lg: "25px",
                            xl: "28px",
                          },
                          flex: 1,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            gap: { mobile: "20px", md: "30px", xl: "40px" },
                          }}
                        >
                          <StyledInfoTitle
                            sx={{
                              flex: 1,
                            }}
                          >
                            {t("investmentFund.date")}
                          </StyledInfoTitle>
                          <StyledInfo
                            sx={{
                              flex: 1.5,
                            }}
                          >
                            {data?.start_date}
                          </StyledInfo>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            gap: { mobile: "20px", md: "30px", xl: "40px" },
                          }}
                        >
                          <StyledInfoTitle
                            sx={{
                              flex: 1,
                            }}
                          >
                            {t("investmentFund.size")}{" "}
                          </StyledInfoTitle>
                          <StyledInfo
                            sx={{
                              flex: 1.5,
                            }}
                          >
                            {data?.fund_size}{" "}
                          </StyledInfo>
                        </Box>
                      </Box>
                    </Box>
                    <TextInfo
                      headerColor="#428E33"
                      link={t("homePage.aboutUs.more")}
                      header={data?.title}
                      text={data?.description}
                      hideLine
                    />
                  </Stack>
                </SecondaryContainer>
              </Box>
            </ContainerWithPadding>
          )}
        </Box>
      )}
    </>
  );
};

export default InvestmentFund;

const StyledImageContainer = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: "0px 0px 20px #00000017",
  borderRadius: "15px",
  backgroundColor: "white",
});

const StyledInfoTitle = styled("h6")({
  color: "white",
  backgroundColor: "#428E33",
  fontSize: "1.25rem",
  fontWeight: "bold",
  padding: "1rem 0",
  width: "100%",
  textAlign: "center",
  borderRadius: "12px",
  "@media (max-width:1650px)": {
    fontSize: "1rem",
  },
  "@media (max-width:1280px)": {
    fontSize: "0.875rem",
  },
});
const StyledInfo = styled("h6")({
  borderRadius: "12px",
  color: "#428E33",
  backgroundColor: "#F2F2F2",
  fontSize: "1.25rem",
  fontWeight: "bold",
  padding: "1rem 0",
  width: "100%",
  textAlign: "center",
  "@media (max-width:1650px)": {
    fontSize: "1rem",
  },
  "@media (max-width:1280px)": {
    fontSize: "0.875rem",
  },
});
