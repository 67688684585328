import { IconButton, TextField } from "@mui/material";
import { Formik, Form } from "formik";
import { FiSearch } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";

type Props = {
  label: string;
  color?: string;
};

const CustomSearchField = ({ label, color }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const initialValues = {
    search: "",
  };
  const handleSearch = (values: any) => {
    if (values.search !== "") {
      navigate(`/search-results/${values.search}`);
    }
  };
  return (
    <Formik initialValues={initialValues} onSubmit={handleSearch}>
      {({ values, handleChange, handleSubmit }) => (
        <Form>
          <TextField
            onChange={handleChange}
            name="search"
            placeholder={label}
            variant="standard"
            color={location.pathname === "/" ? "secondary" : "primary"}
            InputProps={{
              startAdornment: (
                <IconButton
                  sx={{
                    color: color
                      ? "primary.main"
                      : location.pathname === "/"
                      ? "white"
                      : "primary.main",
                    "& svg": {
                      fontSize: {
                        mobile: "1.25rem",
                        xs: "1.25rem",
                        sm: "1.625rem",
                      },
                      marginRight: "8px",
                      cursor: "pointer",
                    },
                  }}
                  disableRipple
                  type="submit"
                >
                  {" "}
                  <FiSearch />
                </IconButton>
              ),
            }}
            sx={{
              maxWidth: "250px",
              margin: "0 .5rem",
              "& .MuiInputBase-root": {
                color: color
                  ? "primary.main"
                  : location.pathname === "/"
                  ? "white"
                  : "primary.main",
                fontSize: {
                  mobile: ".725rem",
                  xs: "1rem",
                  sm: "1.25rem",
                },
                transform: "translateY(-0.4rem)",

                "&:hover": {
                  "&::before": {
                    borderBottom: color
                      ? "1px solid #428E33 !important"
                      : location.pathname === "/"
                      ? "1px solid white !important"
                      : "1px solid #428E33 !important",
                  },
                },
                "&::before": {
                  borderBottom: color
                    ? "1px solid #428E33 !important"
                    : location.pathname === "/"
                    ? "1px solid white !important"
                    : "1px solid #428E33 !important",
                },
              },
            }}
          />
        </Form>
      )}
    </Formik>
  );
};

export default CustomSearchField;
