import { styled } from "@mui/material";
import aboutBg from "../../assets/images/about-us-bg.png";

const ContainerWithPadding = styled("section")({
  display: "flex",
  // alignItems: "center",
  position: "relative",
  "&::before": {
    content: '""',
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "100%",
    zIndex: -1,
    height: "100%",
    backgroundImage: `url(${aboutBg})`,
    backgroundRepeat: "repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    transform: "translate(-50%, -50%)",
  },

  paddingTop: "180px",
  paddingBottom: "180px",

  "@media (max-width: 1650px)": {
    paddingTop: "160px",
    paddingBottom: "160px",
  },
  "@media (max-width: 1440px)": {
    paddingTop: "140px",
    paddingBottom: "140px",
  },
  "@media (max-width: 1280px)": {
    paddingTop: "100px",
    paddingBottom: "100px",
  },
  "@media (max-width: 960px)": {
    paddingTop: "60px",
    paddingBottom: "60px",
  },
});

export default ContainerWithPadding;
