import { Box, Container } from "@mui/material";
import React from "react";

type Props = {
  children: React.ReactNode;
  sx?: any;
};

const SmallerContainer = ({ children, sx }: Props) => {
  return (
    <Box
      sx={{
        zIndex: 2,
        margin: "0 280px 0 350px",
        "@media (max-width: 1650px)": {
          margin: "0 240px 0 310px",
        },
        "@media (max-width: 1440px)": {
          margin: "0 200px 0 270px",
        },
        "@media (max-width: 1280px)": {
          margin: "0 140px 0 175px",
        },
        // "@media (max-width: 960px)": {
        //   margin: "0 100px 0 116px",
        // },
        "@media (max-width: 960px)": {
          margin: "0 100px",
        },
        "@media (max-width: 600px)": {
          margin: "0 40px",
        },
        height: "100%",
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default SmallerContainer;
