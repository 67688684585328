import { Box, Stack } from "@mui/material";
import React from "react";
import newsImage from "../../assets/images/news-image.png";
import styled from "@emotion/styled";
import layer from "../../assets/images/shadow-layer.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type Props = {
  id?: number;
  title?: string;
  desc?: string;
  type?: "news" | "photo";
  image?: string;
};
const StyledHeader = styled("h5")({
  color: "white",
  fontSize: "1.5rem",
  fontWeight: "700",
  position: "relative",
  zIndex: 2,
  "@media (max-width: 1440px)": {
    fontSize: "1.25rem",
  },
  "@media (max-width: 1280px)": {
    fontSize: "1rem",
  },
  "@media (max-width: 960px)": {
    fontSize: "14px",
  },
});
const StyledText = styled("p")({
  textOverflow: "ellipsis",
  color: "white",
  fontSize: "14px",
  position: "relative",
  zIndex: 2,
  lineClamp: 2,
  WebkitLineClamp: 2,
  "@media (max-width: 1440px)": {
    fontSize: "12px",
  },
});

const MediaCard = ({ id, title, desc, type, image }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Box
      onClick={() => {
        if (type === "news") {
          navigate(`/media-center/news/${id}`);
        }
      }}
      sx={{
        padding: { mobile: "1.75rem", md: "2.5rem", xl: "2.7rem" },
        position: "relative",
        overflow: "hidden",
        backgroundImage: `url(${image})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderRadius: "10px",
        height: { mobile: "220px", xs: "280px", md: "340px", xl: "390px" },
        "&::after": {
          transition: "all 0.3s ease-in-out",
          zIndex: 1,
          content: '""',
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          borderRadius: "10px",
          opacity: "0",
          backgroundImage:
            " linear-gradient(0deg, rgba(0,0,0,0.8332282913165268) 0%, rgb(0 0 0 / 27%) 35%, rgb(255 255 255 / 0%) 100%)",
        },
        "&:hover": {
          "&::after": {
            opacity: "1",
          },
        },
      }}
    >
      <Box
        sx={{
          height: "60%",
        }}
      ></Box>
      <Stack
        spacing={{
          mobile: 1.5,
          md: 2,
          xl: 3,
        }}
        sx={{
          // marginTop: "25px",
          flex: "1",
        }}
      >
        <StyledHeader>{title} </StyledHeader>
        {/* <StyledText>{t("mediaCard.desc")}</StyledText> */}
      </Stack>
    </Box>
  );
};

export default MediaCard;
