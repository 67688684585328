export const getNumberOfWords = (text: string) => {
  return text.split(" ").length;
};
export const makeASmallerText = (text: string, textLength: number) => {
  if (text.split(" ").length > textLength) {
    return text.split(" ").slice(0, textLength).join(" ") + "...";
  } else {
    return text;
  }
};
