import { Box } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Banner from "../../components/Banner/Banner";
import ContainerWithPadding from "../../components/Containers/ContainerWithPadding";
import SecondaryContainer from "../../components/Containers/SecondaryContainer";
import MainLoader from "../../components/Loaders/MainLoader";
import TextInfo from "../../components/TextInfo/TextInfo";
import useGetSingleApi from "../../hooks/useGetSingleApi";
import JobsForm from "../../components/JobsForm/JobsForm";
import useGetMultiApi from "../../hooks/useGetMultiApi";
import React from "react";
import { ScrollRestoration } from "react-router-dom";

type Props = {};

const Jobs = (props: Props) => {
  const { t, i18n } = useTranslation();
  const apiUrls = useMemo(
    () => ["/job_page_details", "/jobs", "/departments"],
    []
  );

  const { data, loading } = useGetMultiApi(apiUrls);

  if (loading) {
    return <MainLoader />;
  }

  return (
    <>
      {data && data[0] && (
        <Box>
          <Banner image={data[0]?.image} title={data[0]?.title} />

          {data && (
            <ContainerWithPadding
              sx={{
                "&::before": {
                  transform:
                    i18n.dir() === "rtl"
                      ? "translate(-50%,-50%)"
                      : "translate(-50%, -50%) rotate(180deg)",
                  backgroundSize: "contain",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <SecondaryContainer>
                  <TextInfo
                    headerColor="#428E33"
                    link={t("homePage.aboutUs.more")}
                    header={data[0]?.title}
                    text={data[0]?.description}
                  />
                </SecondaryContainer>
                <JobsForm
                  selectItems={{
                    jobs: data[1],
                    departments: data[2],
                  }}
                />
              </Box>
            </ContainerWithPadding>
          )}
        </Box>
      )}
    </>
  );
};

export default React.memo(Jobs);
