import { Box, Stack, Typography, styled, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import footerBg from "../../../assets/images/footer-bg.png";
import footerLogo from "../../../assets/images/footer-logo.svg";
import SecondaryContainer from "../../Containers/SecondaryContainer";
import SocialMedia from "../SocialMedia/SocialMedia";
import { useTranslation } from "react-i18next";
import logoLtr from "../../../assets/images/footer-logo-ltr.png";
import { motion } from "framer-motion";
import AnimateX from "../../Animations/AnimateX";

type Props = {};

const StyledLink = styled("a")({
  cursor: "pointer",
  textDecoration: "none",
  color: "#707070",
  fontSize: "1rem",
  "&:hover": {
    color: "#428E33",
  },
  "@media (max-width: 1440px)": {
    fontSize: "0.875rem",
  },
  "@media (max-width: 960px)": {
    fontSize: "1.125rem",
  },
});

const Footer = (props: Props) => {
  const navigate = useNavigate();
  const matches = useMediaQuery("(max-width: 960px)");
  const { i18n, t } = useTranslation();
  const links = [
    {
      linkTitle: t("layout.footer.links.firstLink.title"),
      subLinks: [
        {
          subLinkTitle: t("layout.footer.links.firstLink.subLink1"),
          link: "/institutional-view",
        },
        {
          subLinkTitle: t("layout.footer.links.firstLink.subLink3"),
          link: "/leadership",
        },
        {
          subLinkTitle: t("layout.footer.links.firstLink.subLink2"),
          link: "/fatwa-authority",
        },

        {
          subLinkTitle: t("layout.footer.links.firstLink.subLink4"),
          link: "/reports",
        },
      ],
    },
    {
      linkTitle: t("layout.footer.links.secondLink.title"),
      subLinks: [
        {
          subLinkTitle: t("layout.footer.links.secondLink.subLink1"),
          link: "/services/asset-management",
        },
        {
          subLinkTitle: t("layout.footer.links.secondLink.subLink2"),
          link: "/services/baitk-liquidity-fund",
        },
        {
          subLinkTitle: t("layout.footer.links.secondLink.subLink3"),
          link: "/services/securities-management",
        },
        {
          subLinkTitle: t("layout.footer.links.secondLink.subLink4"),
          link: "/services/securities-management/investment-portfolio-management",
        },
      ],
    },
    {
      linkTitle: t("layout.footer.links.thirdLink.title"),
      subLinks: [
        {
          subLinkTitle: t("layout.footer.links.thirdLink.subLink1"),
          link: "/media-center/news",
        },
        {
          subLinkTitle: t("layout.footer.links.thirdLink.subLink2"),
          link: "/media-center/photos",
        },
        {
          subLinkTitle: t("layout.footer.links.thirdLink.subLink3"),
          link: "/media-center/videos",
        },
      ],
    },
  ];
  return (
    <Box
      sx={{
        position: " relative",
        backgroundColor: "white",
        "&::before": {
          position: "absolute",
          content: '""',
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          height: "100%",
          backgroundImage: `url(${footerBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        },
        padding: {
          mobile: "24px 0 40px",
          sm: "80px 0 40px",
          lg: "105px 0 50px",
          xl: "125px 0px 60px",
        },
      }}
    >
      {!matches && (
        <SecondaryContainer>
          <Stack spacing={4}>
            <Stack direction="row" justifyContent="space-between">
              <AnimateX direction="right">
                <Stack
                  sx={{
                    zIndex: 2,
                    maxWidth: {
                      mobile: "250px",
                      xs: "280px",
                      lg: "330px",
                      xl: "380px",
                    },
                    height: "100%",
                    justifyContent: "flex-start",
                  }}
                >
                  <Box
                    onClick={() => navigate("/")}
                    component={"img"}
                    src={i18n.dir() === "ltr" ? logoLtr : footerLogo}
                    alt="footer-logo"
                    sx={{
                      marginBottom: { mobile: "1rem", xl: "1.5rem" },
                      cursor: "pointer",
                    }}
                  />
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#707070",
                      marginBottom: "1rem",
                      textAlign: "justify",
                    }}
                  >
                    {t("layout.footer.desc")}
                  </Typography>
                  <SocialMedia color="#428E33" size="small" />
                </Stack>
              </AnimateX>
              <Box
                sx={{
                  display: "flex",
                  gap: { mobile: "25px", md: "25px", lg: "60px", xl: "90px" },
                }}
              >
                {links.map((item, i) => {
                  return (
                    <Stack
                      spacing={2}
                      key={i}
                      sx={{
                        zIndex: 2,
                      }}
                    >
                      <Typography
                        // data-aos="fade-zoom-in"
                        sx={{
                          color: "primary.main",
                          fontSize: {
                            mobile: ".9rem",
                            md: "1.2rem",
                            lg: "1.375",
                            xl: "1.5rem",
                          },
                          fontWeight: 700,
                        }}
                      >
                        {item.linkTitle}
                      </Typography>
                      {item.subLinks.map((subItem, i) => {
                        return (
                          <motion.div
                            initial={{ scale: 0, opacity: 0.2 }}
                            whileInView={{ scale: 1, opacity: 1 }}
                            transition={{ delay: 0.2 * i, duration: 0.25 }}
                            key={i}
                            viewport={{
                              once: true,
                            }}
                            onClick={() => navigate(subItem.link)}
                          >
                            <StyledLink>{subItem.subLinkTitle}</StyledLink>
                          </motion.div>
                        );
                      })}
                    </Stack>
                  );
                })}
              </Box>
            </Stack>
            <Typography
              sx={{
                zIndex: "5",
                width: "100%",
                textAlign: "center",
                fontWeight: "600",
                fontSize: {
                  mobile: "20px",
                  md: "24px",
                },
                color: "primary.main",
                "& a": {
                  margin: "0 .25rem",
                  cursor: "pointer",
                  textDecoration: "underline",
                },
              }}
            >
              {t("layout.footer.copyrightsStart")}{" "}
              <a href="https://www.dimofinf.net/" target="__blank">
                Dimofinf
              </a>
              {t("layout.footer.copyrights", {
                year: new Date().getFullYear(),
              })}
            </Typography>
          </Stack>
        </SecondaryContainer>
      )}
      {matches && (
        <SecondaryContainer>
          <Stack
            alignItems="center"
            sx={{
              width: "100%",
              height: "100%",
              justifyContent: "center",
              padding: { mobile: "25px 0 0 0", xs: "50px 0 0 0" },
            }}
            data-aos="fade-in"
          >
            <Box
              onClick={() => navigate("/")}
              component={"img"}
              src={footerLogo}
              alt="footer-logo"
              sx={{
                cursor: "pointer",
                marginBottom: { mobile: "1rem", xl: "1.5rem" },
                maxWidth: { mobile: "250px", xs: "380px" },
              }}
            />
            <Typography
              variant="body2"
              sx={{
                color: "#707070",
                marginBottom: "1.5rem",
                textAlign: "center",
                fontSize: { mobile: "16px", xs: "1rem" },
              }}
            >
              {t("layout.footer.desc")}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <SocialMedia color="#428E33" size="small" />
            </Box>
            <Stack>
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "14px",
                }}
              >
                {t("layout.footer.copyrights", {
                  year: new Date().getFullYear(),
                })}
              </Typography>
            </Stack>
          </Stack>
        </SecondaryContainer>
      )}
    </Box>
  );
};

export default Footer;
