import { Box, Stack } from "@mui/material";
import React from "react";
import StyledHeader from "../StyledComponents/StyledHeader";
import TextWithTitleCard from "../TextWithTitleCard/TextWithTitleCard";
import visionBg from "../../assets/images/vision-mission-bg.svg";
import visionImage from "../../assets/images/vision.jpg";
import AnimateScale from "../Animations/AnimateScale";
import { useTranslation } from "react-i18next";

type Props = {
  data: any;
  id: string;
};

const VisionAndMission = ({ data, id }: Props) => {
  const { t } = useTranslation();
  return (
    <Box
      id={id}
      component="section"
      sx={{
        display: "grid",
        gridTemplateColumns: {
          mobile: "1fr",
          xs: "5fr 3fr",
          sm: "6fr 3fr",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#E8F1E8",
          gap: { mobile: "30px", md: "40px", lg: "50px", xl: "60px" },
          padding: {
            mobile: "40px 40px 40px 40px",
            xs: "40px 60px 40px 100px",
            sm: "60px 100px 60px 140px",
            md: "70px 110px 70px 200px",
            lg: "75px 130px 75px 240px",
            xl: "80px 150px 80px 280px",
          },
        }}
      >
        <AnimateScale duration={0.3}>
          <StyledHeader
            sx={{
              color: "#428E33",
            }}
          >
            {t("InstitutionalView.visionAndMission")}{" "}
          </StyledHeader>
        </AnimateScale>
        <AnimateScale>
          <Stack
            spacing={{
              mobile: 2,
              xs: 3,
              sm: 4,
              md: 5,
              lg: 6,
            }}
          >
            {data?.map((item: any) => {
              return (
                <TextWithTitleCard
                  key={item.title}
                  title={item?.title}
                  text={item?.description}
                />
              );
            })}
          </Stack>
        </AnimateScale>
      </Box>
      <Box
        sx={{
          backgroundImage: `url(${visionBg})`,
          backgroundRepeat: "repeat",
          backgroundSize: "contain",
          backgroundPosition: "center",
          display: { mobile: "none", xs: "block" },
          padding: {
            mobile: "75px 0px",
            xs: "65px 0",
            md: "75px 0",
            // xs: "100px 0px",
            // sm: "60px 0px",
            // md: "70px 0px",
            // lg: "75px 0px",
            // xl: "80px 0px",
          },
        }}
      >
        <Box
          data-aos="fade-in"
          data-aos-duration="1000"
          component="img"
          src={visionImage}
          alt="vision"
          sx={{
            boxShadow: "-4px 4px 15px rgba(0, 0, 0, 0.45)",

            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "20px",
            position: "relative",
            "&::after": {
              content: '""',
              position: "absolute",
              width: "100%",
              height: "100%",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
              backgroundColor: "#428E33",
              opacity: "0.43",
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default VisionAndMission;
