import { Box } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Banner from "../../components/Banner/Banner";
import ContainerWithPadding from "../../components/Containers/ContainerWithPadding";
import SecondaryContainer from "../../components/Containers/SecondaryContainer";
import ContactUs from "../../components/LayoutComponents/ContactUs/ContactUs";
import LeadershipTabs from "../../components/LeadershipComponents/LeadershipTabs/LeadershipTabs";
import MainLoader from "../../components/Loaders/MainLoader";
import { useLayoutContext } from "../../context/layoutContext";
import useGetSingleApi from "../../hooks/useGetSingleApi";

type Props = {};

const Leadership = (props: Props) => {
  const { data: contactData, loading: contactLoading } = useLayoutContext();

  const { t, i18n } = useTranslation();
  const apiUrl = useMemo(() => "/leadership", []);
  const { data, loading } = useGetSingleApi(apiUrl);

  if (loading) {
    return <MainLoader />;
  }
  return (
    <>
      {data && (
        <Box>
          <Banner
            image={data?.image}
            title={data?.title}
            text={data?.description}
          />

          {data && (
            <ContainerWithPadding
              sx={{
                "&::before": {
                  backgroundSize: "contain",
                  transform:
                    i18n.dir() === "rtl"
                      ? "translate(-50%,-50%)"
                      : "translate(-50%, -50%) rotate(180deg)",
                },
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  zIndex: 2,
                }}
              >
                <SecondaryContainer>
                  {data && <LeadershipTabs data={data?.page_details} />}{" "}
                </SecondaryContainer>
              </Box>
            </ContainerWithPadding>
          )}
        </Box>
      )}
      {contactData && contactData[0] && (
        <ContactUs contactInfo={contactData[0]} />
      )}
    </>
  );
};

export default Leadership;
