import { Box } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Banner from "../../components/Banner/Banner";
import ContainerWithPadding from "../../components/Containers/ContainerWithPadding";
import ContactUs from "../../components/LayoutComponents/ContactUs/ContactUs";
import MainLoader from "../../components/Loaders/MainLoader";
import { useLayoutContext } from "../../context/layoutContext";
import useGetSingleApi from "../../hooks/useGetSingleApi";
import i18n from "../../i18n";

type Props = {};

const OrganizationalStructure = (props: Props) => {
  const { t } = useTranslation();
  const { data: contactData, loading: contactLoading } = useLayoutContext();

  const apiUrl = useMemo(() => "/organizational_structure", []);
  const { data, loading } = useGetSingleApi(apiUrl);
  if (loading) {
    return <MainLoader />;
  }
  return (
    <>
      {data && (
        <Box>
          <Banner
            image={data?.image}
            title={data?.title}
            text={data?.description}
          />
          <ContainerWithPadding
            sx={{
              "&::before": {
                backgroundSize: "contain",
                transform:
                  i18n.dir() === "rtl"
                    ? "translate(-50%,-50%)"
                    : "translate(-50%, -50%) rotate(180deg)",
              },
              zIndex: -1,
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 2,
              }}
            >
              <Box
                component="img"
                src={data?.page_details?.IMAGE[0]?.file}
                alt="organizational-image"
                sx={{
                  width: "80%",
                  height: "auto",
                  objectFit: "contain",
                }}
              />
            </Box>
          </ContainerWithPadding>
        </Box>
      )}
      {contactData && contactData[0] && (
        <ContactUs contactInfo={contactData[0]} />
      )}
    </>
  );
};

export default OrganizationalStructure;
