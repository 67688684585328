import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

type Props = {
  data: any;
};

const LeadershipCard = ({ data }: Props) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  return (
    <Box
      sx={{
        cursor: "pointer",
        width: "100%",
        maxWidth: { mobile: "315px", xs: "440px" },
        height: { mobile: "370px", md: "400px", lg: "500px", xl: "600px" },
        border: "1px solid",
        borderColor: "primary.main",
        borderRadius: "20px",
        backgroundImage: `url(${data?.file})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        alignItems: "flex-end",
        paddingBottom: "10%",
        position: "relative",
        "&:hover": {
          border: pathname.includes("fatwa") ? "1px solid #707070" : "none",
          "& .overlay": {
            opacity: pathname.includes("fatwa") ? "0" : "1",
          },
          "& .small-circle": {
            "&::before": {
              opacity: pathname.includes("fatwa") ? "1" : "0",
            },
          },
        },
      }}
    >
      <Box
        className="small-circle"
        sx={{
          display: "flex",
          flexDirection: "column",
          borderTopRightRadius: "8px",
          borderBottomRightRadius: "8px",
          width: "auto",
          maxWidth: "90%",
          minWidth: "70%",
          // width: { mobile: "83%", md: "88%", xl: "70%" },
          backgroundColor: "rgba(255,255,255,0.96)",
          padding: "20px 10px",
          boxShadow: " 15px 3px 30px 0px #00000073 ",
          position: "relative",
          "&::before": {
            content: "''",
            position: "absolute",
            width: "12px",
            height: "12px",
            backgroundColor: "primary.main",
            top: "50%",
            left: "-6px",
            transform: "translateY(-50%)",
            borderRadius: "50%",
            zIndex: "1",
          },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
          {!pathname.includes("fatwa") && (
            <Typography
              sx={{
                fontSize: { mobile: "14px", md: "16px", xl: "20px" },
                fontWeight: "600",
                color: "#707070",
              }}
            >
              {t("leadership.title")}
            </Typography>
          )}
          <Typography
            sx={{
              color: "primary.main",
              fontSize: { mobile: "16px", md: "18px", lg: "20px", xl: "22px" },
              fontWeight: "bold",
            }}
          >
            {data?.title?.full_name}
          </Typography>
        </Box>
        <Typography
          sx={{
            color: "#AAAAAA",
            fontSize: { mobile: "12px", md: "14px", lg: "16px", xl: "18px" },
          }}
        >
          {data?.title?.position}
        </Typography>
      </Box>
      {!pathname.includes("fatwa") && (
        <Box
          className="overlay"
          sx={{
            opacity: "0",
            overflow: "auto",
            transition: "all 0.3s ease",
            borderRadius: "20px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            width: "100%",
            height: "100%",
            backgroundColor: "primary.main",
            zIndex: "50",
            padding: {
              mobile: "16px",
              xs: "32px 12px",
              sm: "20px 16px",
              md: "28px 16px",
              xl: "50px 24px",
            },
            "&::-webkit-scrollbar": {
              width: "0px",
              background: "transparent",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "transparent",
            },
            "&::-webkit-scrollbar-track": {
              background: "transparent",
            },
          }}
        >
          <Typography
            sx={{
              textAlign: "left",
              fontSize: { mobile: "18px", xs: "16px", lg: "18px", xl: "22px" },
              "@media (max-width: 760px)": {
                fontSize: "14px",
              },
              "@media (max-width: 600px)": {
                fontSize: "16px",
              },
              fontWeight: "600",
              color: "#F2F2F2",
            }}
          >
            {data?.description}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default LeadershipCard;
