import React from "react";
import { motion, stagger } from "framer-motion";

type Props = {
  children: React.ReactNode;
  index: number;
};

const AnimateStaggered = ({ children, index }: Props) => {
  return (
    <motion.div
      initial={{ scale: 0, opacity: 0.2 }}
      animate={{ scale: 1, opacity: 1 }}
      exit={{ scale: 0.8, opacity: 0.5 }}
      transition={{ delay: 0.5 * index, duration: 0.4 }}
    >
      {children}
    </motion.div>
  );
};

export default AnimateStaggered;
