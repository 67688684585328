import { Box, Divider, Stack, Typography } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Banner from "../../components/Banner/Banner";
import ContainerWithPadding from "../../components/Containers/ContainerWithPadding";
import SecondaryContainer from "../../components/Containers/SecondaryContainer";
import MainLoader from "../../components/Loaders/MainLoader";
import NewsSlider from "../../components/NewsSlider/NewsSlider";
import StyledHeader from "../../components/StyledComponents/StyledHeader";
import useGetSingleApi from "../../hooks/useGetSingleApi";

type Props = {};

const SingleNews = (props: Props) => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const mainInfoApiUrl = useMemo(() => `/news`, []);
  const { data: mainInfoData, loading: mainInfoLoading } =
    useGetSingleApi(mainInfoApiUrl);
  const apiUrl = useMemo(() => `/social_news_details/${id}`, [id]);
  const { data, loading } = useGetSingleApi(apiUrl);
  if (loading || mainInfoLoading) {
    return <MainLoader />;
  }
  return (
    <>
      {data && mainInfoData && (
        <Box>
          <Banner image={mainInfoData?.file} title={mainInfoData?.title} />

          {mainInfoData && data && (
            <ContainerWithPadding
              sx={{
                "&::before": {
                  transform:
                    i18n.dir() === "rtl"
                      ? "translate(-50%,-50%)"
                      : "translate(-50%, -50%) rotate(180deg)",
                },
              }}
            >
              <SecondaryContainer>
                <Stack
                  spacing={{
                    mobile: 4,
                    md: 6,
                    lg: 8,
                    xl: 10,
                  }}
                >
                  <Stack spacing={1}>
                    <StyledHeader
                      sx={{
                        color: "primary.main",
                      }}
                    >
                      {data?.title}{" "}
                    </StyledHeader>
                    <Typography
                      sx={{
                        color: "primary.main",
                      }}
                    >
                      بتاريخ: {data?.date}
                    </Typography>
                  </Stack>
                  <Stack
                    spacing={{
                      mobile: 4,
                      md: 6,
                      lg: 8,
                      xl: 10,
                    }}
                    divider={
                      <Divider
                        sx={{
                          borderColor: "primary.main",
                        }}
                      />
                    }
                  >
                    <NewsSlider slides={data?.slider_image} />
                    <Typography
                      sx={{
                        fontSize: {
                          mobile: "18px",
                          sm: "20px",
                          md: "24px",
                          xl: "28px",
                        },
                        color: "#505050",
                        fontWeight: "semibold",
                      }}
                    >
                      {data?.description}
                    </Typography>
                  </Stack>
                </Stack>
              </SecondaryContainer>
            </ContainerWithPadding>
          )}
        </Box>
      )}
    </>
  );
};

export default SingleNews;
