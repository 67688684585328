import { Alert, useMediaQuery } from "@mui/material";
import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./AlertMessage.css";
import { useTranslation } from "react-i18next";

const AlertMessage = () => {
  const matches = useMediaQuery("(max-width:600px)");
  const { i18n } = useTranslation();
  // hi
  return (
    <>
      <ToastContainer
        position={matches ? "bottom-center" : "top-right"}
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={i18n.dir() === "rtl" ? true : false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default AlertMessage;
