import { Box, Typography } from "@mui/material";
import React from "react";
import greenTriangle from "../../assets/images/small-green-triangle.svg";
import SmallTextInfo from "../TextInfo/SmallTextInfo";
import grayTriangle from "../../assets/images/gray-triangle.svg";

type Props = {
  data?: any;
};

const SingleIndirectFund = ({ data }: Props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "42px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          backgroundColor: "#73bb651c",
          borderRadius: "8px",
          gap: { mobile: "16px", lg: "36px" },
          padding: { mobile: "12px 24px", lg: "12px 54px" },
        }}
      >
        <Box
          component="img"
          src={greenTriangle}
          alt="green-triangle"
          sx={{
            scale: { mobile: ".5", md: ".8", lg: "1" },
          }}
        />
        <Typography
          sx={{
            color: "#428E33",
            fontSize: {
              mobile: "18px",
              lg: "28px",
            },
            fontWeight: "bold",
          }}
        >
          {data?.title}{" "}
        </Typography>
      </Box>
      {data.indirect_fund_details.map((item: any) => {
        return (
          <SmallTextInfo
            title={item?.title}
            text={item?.description}
            startIcon={
              <img
                style={{
                  marginTop: "5px",
                }}
                src={grayTriangle}
                alt="gray-triangle"
              />
            }
          />
        );
      })}
    </Box>
  );
};

export default SingleIndirectFund;
