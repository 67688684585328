import { TextField } from "@mui/material";
import { Field, FieldProps } from "formik";
import React from "react";

type Props = {
  label: string;
  // startIcon?: boolean;
  name?: string;
  color?: "warning";
  type?: string;
  multiline?: boolean;
};

const CustomTextField = ({
  label,
  name,
  color,
  multiline,
  type,
  ...props
}: Props) => {
  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => (
        <TextField
          type={type ? type : "text"}
          helperText={meta.touched && meta.error && meta.error}
          autoComplete={"off"}
          {...field}
          fullWidth
          label={label}
          error={meta.touched && !!meta.error}
          // helperText={meta.touched && meta.error}
          multiline={multiline}
          rows={multiline ? 3 : 1}
          variant="standard"
          color={color ? color : "secondary"}
          sx={{
            "& .MuiInputBase-root": {
              // height: "2.5rem",
              fontSize: {
                mobile: ".875rem",
                xs: "1rem",
                sm: "1.25rem",
                md: "1.5rem",
              },
              color: color ? "#707070" : "white",
              paddingBottom: { mobile: "2px", xs: "4px", sm: "inherit" },
              "&:hover": {
                "&::before": {
                  borderBottom: color
                    ? "1px solid #707070 !important"
                    : "1px solid white !important",
                },
                "&.Mui-error": {
                  "&::before": {
                    borderBottom: "1px solid red !important",
                  },
                },
              },
              "&.Mui-error": {
                "&::before": {
                  borderBottom: "1px solid red !important",
                },
              },
              "&::before": {
                borderBottom: color
                  ? "1px solid #707070 !important"
                  : "1px solid white",
              },
            },
            "& .MuiFormLabel-root": {
              color: color ? "#707070" : "white",
              opacity: ".5",
              "&.Mui-error": {
                opacity: "1",
                color: "#ff4949",
              },
              fontSize: {
                mobile: ".875rem",
                xs: "1rem",
                sm: "1.25rem",
                md: "1.5rem",
              },
            },
            "& .MuiFormHelperText-root": {
              // position: "absolute",
              // bottom: "-1.5rem",
            },
          }}
        />
      )}
    </Field>
  );
};

export default React.memo(CustomTextField);
