import React from "react";
import { motion } from "framer-motion";

type Props = {
  children: React.ReactNode;
  duration?: number;
};

const AnimateScale = ({ children, duration }: Props) => {
  return (
    <motion.div
      style={{
        width: "100%",
        height: "100%",
      }}
      initial={{ scaleY: 0, scaleX: 0, opacity: 0 }}
      whileInView={{ scaleY: 1, scaleX: 1, opacity: 1 }}
      transition={{ duration: duration ? duration : 0.5 }}
      viewport={{
        once: true,
      }}
    >
      {children}
    </motion.div>
  );
};

export default AnimateScale;
