import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@mui/material";
import { match } from "assert";

type Props = {
  children: React.ReactNode;
  direction: "left" | "right";
};

const AnimateX = ({ children, direction }: Props) => {
  const { i18n } = useTranslation();
  const [pageDirection, setPageDirection] = useState<"left" | "right">(
    i18n.language === "ar" ? "right" : "left"
  );
  const matches = useMediaQuery("(max-width: 1440px)");

  // useEffect(() => {
  //   setPageDirection(i18n.language === "ar" ? "right" : "left");
  // }, [i18n.language]);
  return (
    <motion.div
      initial={{
        x:
          direction === "left" && pageDirection === "left"
            ? matches
              ? "50%"
              : "85%"
            : direction === "right" && pageDirection === "right"
            ? matches
              ? "50%"
              : "85%"
            : direction === "left" && pageDirection === "right"
            ? matches
              ? "-50%"
              : "-85%"
            : matches
            ? "-50%"
            : "-85%",
      }}
      whileInView={{
        x: 0,
      }}
      transition={{
        duration: 0.9,
        ease: "easeOut",
        // delay: 0.2,
      }}
      viewport={{
        once: true,
      }}
    >
      {children}
    </motion.div>
  );
};

export default AnimateX;
