import { Box, Typography, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import bannerImage from "../../assets/images/banner-bg.jpg";
import Banner from "../../components/Banner/Banner";
import ContainerWithPadding from "../../components/Containers/ContainerWithPadding";
import SecondaryContainer from "../../components/Containers/SecondaryContainer";
import MainLoader from "../../components/Loaders/MainLoader";
import SearchItem from "../../components/SearchItem/SearchItem";
import { FlexColumn } from "../../components/Services/StyledComponents";
import { get } from "../../services/axios-service";

type Props = {};
const StyledTitle = styled("h3")({
  fontSize: "30px",
  color: "#fff",
  "@media (max-width:1650px)": {
    fontSize: "24px",
  },
  "@media (max-width:1280px)": {
    fontSize: "20px",
  },
  "@media (max-width:960px)": {
    fontSize: "18px",
  },
  "@media (max-width:600px)": {
    fontSize: "16px",
    color: "#59A44A",
  },
});

const SearchResults = (props: Props) => {
  const { t, i18n } = useTranslation();
  const { query } = useParams<{ query: string }>();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>();
  const [error, setError] = useState<any>();
  const getSearchResults = async () => {
    setLoading(true);
    await get(`/search?keyword=${query}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getSearchResults();
  }, [query]);
  if (loading) {
    return <MainLoader />;
  }
  return (
    <>
      {data && (
        <Box>
          <Banner image={bannerImage} title={t("searchPage.title")} />

          <ContainerWithPadding
            sx={{
              "&::before": {
                transform:
                  i18n.dir() === "rtl"
                    ? "translate(-50%,-50%)"
                    : "translate(-50%, -50%) rotate(180deg)",
                backgroundSize: {
                  mobile: "contain",
                  lg: "cover",
                },
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <FlexColumn
                sx={{
                  width: "100%",
                  gap: "100px",
                }}
              >
                <SecondaryContainer>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      color: "#707070",
                      fontSize: {
                        mobile: "30px",
                        md: "40px",
                        lg: "50px",
                      },
                    }}
                  >
                    في بيت التمويل السعودي الكويتي
                  </Typography>
                </SecondaryContainer>
                <FlexColumn
                  sx={{
                    gap: {
                      mobile: "50px",
                      md: "75px",
                      xl: "100px",
                    },
                  }}
                >
                  {data && data.length > 0 ? (
                    data?.map((searchItem: any, index: number) => {
                      if (!searchItem) return null;
                      return (
                        <SearchItem
                          title={searchItem}
                          index={index}
                          key={index}
                        />
                      );
                    })
                  ) : (
                    <Typography
                      sx={{
                        fontSize: "30px",
                        fontWeight: "bold",
                        textAlign: "center",
                        color: "primary.main",
                      }}
                    >
                      عفوا لا يوجد نتائج للبحث
                    </Typography>
                  )}
                </FlexColumn>
              </FlexColumn>
            </Box>
          </ContainerWithPadding>
        </Box>
      )}
    </>
  );
};

export default SearchResults;
