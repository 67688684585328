import { Box, Stack, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import achievmentImage2 from "../../assets/images/achievmentImage2.png";
import AnimateScale from "../Animations/AnimateScale";
import AnimateX from "../Animations/AnimateX";
import StyledHeader from "../StyledComponents/StyledHeader";
import AchievmentCard from "./AchievmentCard";
import { useMemo } from "react";
import useGetSingleApi from "../../hooks/useGetSingleApi";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

type Props = {};

const StyledText = styled("p")({
  color: "#505050",
  fontSize: "1.5rem",
  fontWeight: "600",
  position: "relative",
});

const Achievments = (props: Props) => {
  const { t } = useTranslation();
  const apiUrl = useMemo(() => "/awards", []);
  const { data, loading } = useGetSingleApi(apiUrl);
  const items = data?.page_details?.CARDS_IMAGES;

  const settings = {
    slidesToShow: items?.length >= 2 ? 2 : items?.length,
    swipeToSlide: false,
    swipe: true,
    infinite: true,
    speed: 1500,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  if (loading) return <></>;
  if (items)
    return (
      <Box
        sx={{
          padding: {
            mobile: "40px 0 80px",
            sm: "80px 0 160px",
            md: "100px 0 200px",
          },
          marginLeft: {
            mobile: "40px",
            xs: "100px",
            sm: "140px",
            md: "200px",
            lg: "240px",
            xl: "280px",
          },
          marginRight: { mobile: "40px", xs: "100px", md: "0" },
          display: "flex",
          alignItems: "center",
          flexDirection: { mobile: "column", md: "row" },
          gap: { mobile: "30px", xs: "40px", md: "unset" },
          justifyContent: "space-between",
        }}
      >
        <AnimateScale>
          <Stack
            justifyContent="space-around"
            sx={{
              flex: "1",
              position: "relative",
              "&::before": {
                content: '""',
                backgroundColor: "#428E33",
                width: { mobile: "2px", xs: "4px" },
                height: "75%",
                position: "absolute",
                left: { mobile: "-16px", xs: "-35px" },
              },
            }}
          >
            <StyledHeader>{t("homePage.awards.title")}</StyledHeader>
            <StyledText>{t("homePage.awards.desc")}</StyledText>
          </Stack>
        </AnimateScale>
        {/* 
        <Stack
          direction={{
            mobile: "column",
            xs: "row",
          }}
          alignItems={{
            mobile: "center",
            xs: "unset",
          }}
          justifyContent={"center"}
          sx={{
            display: { mobile: "flex", md: "none" },
            gap: "1rem",
            width: "100%",
          }}
          // spacing={4}
        >
          <AchievmentCard
            date={items[0]?.title}
            title={items[0]?.description}
            image={items[0]?.file}
          />
          <AchievmentCard
            date={items[1]?.title}
            title={items[1]?.description}
            image={items[1]?.file}
          />
        </Stack> */}
        {/* more than 1280px design */}
        {/* <AnimateX direction="left">
          <Box
            sx={{
              display: { mobile: "none", md: "grid" },
              gridTemplateColumns: "1fr 2px 1fr",
              alignItems: "center",
              width: "max-content",
              rowGap: "1.5rem",
              columnGap: "4rem",
              minWidth: "50vw",
              padding: {
                mobile: "1rem 1.2rem",
                md: "2rem 2.2rem",
                xl: "2rem 2.5rem",
              },
              boxShadow: "4px 3px 17px rgba(0, 0, 0, 0.25)",
              backgroundColor: "#FFFFFF",
              borderRadius: "10px",
            }}
          >
            <AchievmentCard
              date={items[0]?.title}
              title={items[0]?.description}
              image={items[0]?.file}
            />
            <Box
              sx={{
                width: "2px",
                backgroundColor: "#7070704a",
                height: "60%",
              }}
            />
            <AchievmentCard
              date={items[1]?.title}
              title={items[1]?.description}
              image={items[1]?.file}
            />
          </Box>
        </AnimateX> */}
        <Box
          sx={{
            flex: "2",
            width: {
              mobile: "100%",
              md: "max-content",
            },
            minWidth: {
              mobile: "100%",
              md: "50vw",
            },
            boxShadow: {
              mobile: "none",
              md: "4px 3px 17px rgba(0, 0, 0, 0.25)",
            },
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            padding: {
              mobile: "0",
              md: "2rem 2.2rem",
              xl: "2rem 2.5rem",
            },
            "& .slick-slide": {
              display: "flex",
              justifyContent: {
                mobile: "center",
                md: "unset",
              },
            },
          }}
        >
          <Slider {...settings}>
            {items.map((item: any) => (
              <Box
                key={item?.id}
                sx={{
                  "&.slick-slide": {
                    width: "100%",
                  },
                }}
              >
                <AchievmentCard
                  date={item?.title}
                  title={item?.description}
                  image={item?.file}
                />
              </Box>
            ))}
          </Slider>
        </Box>
      </Box>
    );
  else return <></>;
};

export default Achievments;
