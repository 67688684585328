import { Box, Grid, Skeleton, Stack } from "@mui/material";
import {
  MdOutlineKeyboardDoubleArrowLeft,
  MdOutlineKeyboardDoubleArrowRight,
} from "react-icons/md";
import MainButton from "../Buttons/MainButton";
import SecondaryContainer from "../Containers/SecondaryContainer";
import StyledHeader from "../StyledComponents/StyledHeader";
import MediaCard from "./MediaCard";
import MediaWithTabs from "./MediaWithTabs";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import AnimateX from "../Animations/AnimateX";
import { useNavigate } from "react-router-dom";
import { FaHippo } from "react-icons/fa";
import useGetMultiApi from "../../hooks/useGetMultiApi";
import { useMemo } from "react";

type Props = {};

const MainSkeleton = () => {
  return (
    <Skeleton
      sx={{
        borderRadius: "10px",
        width: "100%",
        height: {
          mobile: "220px",
          xs: "280px",
          md: "340px",
          xl: "390px",
        },
      }}
    />
  );
};

const MediaCenter = (props: Props) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const apiUrls = useMemo(() => ["/news", "/albums"], []);
  const { data, loading } = useGetMultiApi(apiUrls);

  const mainBoxStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#707070",
    width: "100%",
    height: "100%",
    borderRadius: "10px",

    color: "white",
    fontSize: "2rem",
    gap: "1rem",
    "& svg": {
      fontSize: "5rem",
      transform:
        i18n.dir() === "ltr"
          ? "translateY(5px) rotate(180deg)"
          : "translateY(-5px)",
    },
  };

  let newsData = data && data.length > 0 ? data[0]?.news_details : null;
  let albumData = data && data.length > 1 ? data[1]?.page_details?.ALBUM : null;

  return (
    <>
      {data ? (
        <Box
          sx={{
            margin: { mobile: "60px 0", md: "80px 0", xl: "100px 0" },
            display: "flex",
            flexDirection: "column",
            gap: { mobile: "2rem", sm: "6.25rem" },
          }}
        >
          <SecondaryContainer>
            <Stack direction="row" justifyContent="space-between">
              <StyledHeader
                // data-aos="zoom-out"
                sx={{
                  "@media (max-width: 960px)": {
                    position: "relative",
                    mb: { mobile: "1rem", sm: "0" },
                    "&::after": {
                      content: '""',
                      position: "absolute",
                      top: "0",
                      left: { mobile: "-16px", xs: "-35px" },
                      height: "100%",
                      width: "2px",
                      backgroundColor: "#428E33",
                    },
                  },
                }}
              >
                {t("homePage.mediaCenter.title")}
              </StyledHeader>
              <MainButton
                aria-label="link to media center page"
                variant="contained"
                sx={{
                  display: { mobile: "none", sm: "block" },
                }}
                onClick={() => navigate("/media-center/news")}
              >
                {t("homePage.mediaCenter.more")}
              </MainButton>
            </Stack>
          </SecondaryContainer>
          <Box
            sx={{
              display: { mobile: "none", sm: "flex" },
              flexDirection: "column",
              gap: "1.25rem",
            }}
          >
            <AnimateX direction="right">
              <Grid component={"div"} container spacing={3}>
                <Grid item xs={12} sm={2.25}>
                  <Box
                    sx={{
                      ...mainBoxStyles,
                      backgroundColor: "#428E33",
                      borderTopLeftRadius: "0 !important",
                      borderBottomLeftRadius: "0 !important",
                    }}
                  >
                    <span> {t("homePage.mediaCenter.news")}</span>
                    <MdOutlineKeyboardDoubleArrowLeft />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={3.75}>
                  {loading ? (
                    <MainSkeleton />
                  ) : (
                    newsData && (
                      <MediaCard
                        type="news"
                        id={newsData[0]?.id}
                        title={newsData[0]?.title}
                        image={newsData[0]?.file}
                      />
                    )
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {loading ? (
                    <MainSkeleton />
                  ) : (
                    <MediaCard
                      type="news"
                      id={newsData[1]?.id}
                      title={newsData[1]?.title}
                      image={newsData[1]?.file}
                    />
                  )}
                </Grid>
              </Grid>
            </AnimateX>
            <AnimateX direction="left">
              <Grid
                container
                spacing={3}
                sx={{
                  flexDirection: { mobile: "column-reverse", sm: "row" },
                }}
              >
                <Grid item xs={12} sm={6}>
                  {loading ? (
                    <MainSkeleton />
                  ) : (
                    albumData && (
                      <MediaCard
                        type="photo"
                        id={albumData[0]?.id}
                        title={albumData[0]?.title}
                        image={albumData[0]?.file}
                      />
                    )
                  )}
                </Grid>

                <Grid item xs={12} sm={3.75}>
                  {loading ? (
                    <MainSkeleton />
                  ) : (
                    albumData && (
                      <MediaCard
                        type="photo"
                        id={albumData[1]?.id}
                        title={albumData[1]?.title}
                        image={albumData[1]?.file}
                      />
                    )
                  )}
                </Grid>

                {albumData && (
                  <Grid item xs={12} sm={2.25}>
                    {" "}
                    <Box
                      sx={{
                        ...mainBoxStyles,
                        flexDirection: "row-reverse",
                        borderTopRightRadius: "0px !important",
                        borderBottomRightRadius: "0px !important",
                      }}
                    >
                      <span> {t("homePage.mediaCenter.photos")}</span>
                      <MdOutlineKeyboardDoubleArrowRight />
                    </Box>
                  </Grid>
                )}
              </Grid>
            </AnimateX>
          </Box>
          <SecondaryContainer>
            {data && <MediaWithTabs data={data} />}
          </SecondaryContainer>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default MediaCenter;
