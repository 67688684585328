import { Skeleton, Stack } from "@mui/material";

type Props = {};

const TextLoader = (props: Props) => {
  return (
    <Stack>
      <Skeleton
        sx={{
          height: "30px",
          width: "100%",
        }}
      />
      <Skeleton
        sx={{
          height: "30px",
          width: "100%",
        }}
      />
      <Skeleton
        sx={{
          height: "30px",
          width: "100%",
        }}
      />
      <Skeleton
        sx={{
          height: "30px",
          width: "100%",
        }}
      />
    </Stack>
  );
};

export default TextLoader;
