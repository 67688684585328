import { Box, ClickAwayListener, Stack, styled } from "@mui/material";
import { useState } from "react";
import NavItemMenu from "./NavItemMenu";
import { motion } from "framer-motion";
import { Link, useLocation } from "react-router-dom";

type Props = {
  name: string;
  subMenu: [];
  link?: string;
  showMenu: string;
  setShowMenu: React.Dispatch<React.SetStateAction<string>>;
};

const NavItem = ({ name, subMenu, link, showMenu, setShowMenu }: Props) => {
  const location = useLocation();

  const StyledNavItem = styled("div")({
    textAlign: "center",
    transition: "all 0.1s ease-in-out",
    position: "relative",
    h5: {
      fontSize: "1.25rem",
      color: location.pathname === "/" ? "#fff" : "#428E33",
      fontWeight: "400",
      cursor: "pointer",
      textWrap: "nowrap",
      "&::before": {
        display: "block",
        content: "attr(title)",
        fontWeight: 600,
        height: 0,
        overflow: "hidden",
        visibility: "hidden",
      },
      "&:hover": {
        fontWeight: 600,
      },
    },
    //less than 1650px
    "@media (max-width: 1650px)": {
      fontSize: "1.125rem",
    },
    // less than 1440px
    "@media (max-width: 1440px)": {
      h5: {
        fontSize: "1rem",
      },
    },
  });
  const handleShowMenu = (name: string) => {
    setShowMenu(name);
  };
  const handleCloseMenu = () => {
    setShowMenu("");
  };
  return (
    <ClickAwayListener onClickAway={handleCloseMenu}>
      <Box
        component="div"
        onMouseLeave={handleCloseMenu}
        sx={{
          alignSelf: "center",
        }}
      >
        <StyledNavItem
          onMouseEnter={() => {
            handleShowMenu(name);
          }}
          onClick={() => {
            setShowMenu(name);
          }}
        >
          {link ? (
            <Link to={link}>
              <h5 title={name}>{name}</h5>
            </Link>
          ) : (
            <h5 title={name}>{name}</h5>
          )}

          {/* subMen===name */}

          {showMenu === name && subMenu?.length > 0 && (
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.5 }}
              transition={{ duration: 0.2 }}
              onClick={handleCloseMenu}
            >
              <Stack
                sx={{
                  position: "absolute",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    height: location.pathname === "/" ? "20px" : "60px",
                    width: "100%",
                  }}
                ></Box>
                <NavItemMenu subMenu={subMenu} />
              </Stack>
            </motion.div>
          )}
        </StyledNavItem>
      </Box>
    </ClickAwayListener>
  );
};

export default NavItem;
